import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "../../assests/css/App.css";
import "../../assests/css/Giftsection.css";
import Breadcrumbs from "../shared/Breadcrumbs";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import filterIcon from "../../assests/img/header/icon-filter-categories.png";
import { brandsByCategory, getBrandData } from "Redux/brand/brandActions";
import { REACT_APP_baseUrl_V3 } from "utils/app-constants";
import axios from "axios";

class GiftCardsPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.state !== null && props.location.state !== undefined) {
      this.referrer = props.location?.state?.referrer;
    }

    this.state = {
      information: this.referrer,
      clicked: false,
      giftcards: [],
      cardsDetails: [],
      letter: "Default",
      sortedGiftCards: undefined,
      catName: "All",
      pageSize: 20,
      pageNumber: 1,
      loadPage: true,
      loading: true,
    };
  }
  componentDidMount() {
    this.fetchCards(true);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.loading && this.state.cardsDetails?.length === 0){
        this.props.history.push({
            pathname:'/404',
            redirectionURL:`/giftcards/${this.props.match.params.categoryName}/${this.props.match.params.id}`
        })
    }
    if ((prevProps.lang !== this.props.lang) || (prevProps.currancyCode !== this.props.currancyCode) || (prevProps.match.params.id !== this.props.match.params.id)) {
      this.fetchNextPage(true, 1, true);
    }
  };

  fetchNextPage(pageload, pageNumber, isReset) {
    this.setState(
      {
        loadPage: pageload ? pageload : false,
        pageNumber: pageNumber ? pageNumber : this.state.pageNumber + 1,
        loading:true
      },
      () => {
        this.fetchCards(isReset);
      }
    );
  }

  fetchCards = (isReset) => {
    let userCountry = localStorage.getItem("selectedCountry")
    if(userCountry) userCountry = JSON.parse(userCountry)
    const params = {
      currency: userCountry?.currencyCode,
      category_id: this.props.match.params.id,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
    };
    this.fetchCardsByCategory(params, isReset);
  };

  fetchCardsByCategory = (params, isReset) => {
    axios
      .get(`${REACT_APP_baseUrl_V3}/brands/get_brands`, {
        params,
      })
      .then((response) => {
        if (response.data.code === 200) {
          const brandData = response.data.data?.brands;
          this.setState({
            cardsDetails: isReset
              ? brandData
              : [...(this.state?.cardsDetails || []), ...brandData],
            loading: false,
          },()=>this.props.brandsByCategory(this.state?.cardsDetails));
        } else {
          this.setState({ loading: false });
          const errorMsg = response.data?.message;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMsg = error;
        console.log(errorMsg);
      });
  };

  render() {
    const { t } = this.props;
    const breadcrumbs = [
      { name: t("Home"), url: "/", active: true },
      { name: t("Giftcards"), url: "/giftcards", active: true },
      { name: t(this.props.match.params.categoryName), url: "", active: false },
    ];

    const giftcards = this.state.cardsDetails?.map((card, index) => {
      return (
        <div className="text-center border-0" key={card?.id}>
          <Link
            className="active p-0 "
            data-testid={`giftCard-${index}`}
            to={{
              pathname: `/selectedgiftcard/${card?.id}`,
              state: { ...card },
            }}
          >
            <img
              className="img-fluid card-hover"
              src={card?.images?.color?.medium_rectangle}
              alt={card?.name}
            />
          </Link>
          <div className="text-center py-3 product_title fw-bold">
            {card?.name}
          </div>
          {card?.rates === null ? (
            <div className="text-danger fw-bold " style={{ fontSize: "12px" }}>
              {t("Sold Out")}...... {t("Coming Soon")}
            </div>
          ) : (
            false
          )}
        </div>
      );
    });

    const desktopView = (
      <div className="d-md-block d-none">
        <div className="d-flex justify-content-between">
          <div>
            <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">
              {t("Gift Cards")} - {t(this.props.match.params.categoryName)}
            </div>
          </div>
        </div>
      </div>
    );
    const mobileView = (
      <>
        <div className="d-block d-md-none">
          <div>
            <span className="fs-3 headings fw-bold">{t("Gift Cards")}</span>
            <span className="fs-5 text-muted"> - {t(this.props.match.params.categoryName)}</span>
          </div>
          {/* <div className="d-flex flex-row justify-content-end py-2">
            <div>
              <div
                style={{
                  maxWidth: "50px",
                  marginRight: "16px",
                  maxHeight: "40px",
                }}
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-flip="false"
              >
                <img
                  style={{ minHeight: "31px", minWidth: "31px" }}
                  src={filterIcon}
                  alt="categorie"
                  className="img-fluid"
                />
              </div>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <DropdownItem onClick={() => this.props.history.push("/giftcards")}>
                  {t("All")}
                </DropdownItem>
                {this.props.giftCardCategories?.length > 0 &&
                  this.props.giftCardCategories.map((cate) => (
                    <Link
                      to={`/giftcards/${cate?.name}/${cate?.id}`}
                      key={cate?.name}
                      className="dropdown-item"
                    >
                      {t(cate?.name)}
                    </Link>
                  ))}
              </div>
            </div>
          </div> */}
        </div>
      </>
    );

    return (
      <>
        {this.state.loading && this.state.loadPage ? (
          <section>
            <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <div>
            <Breadcrumbs crumbs={breadcrumbs} />
            <section className="px-md-5 px-3 py-md-4 py-1">
              {desktopView}
              {mobileView}
              {/* <div className="d-flex gap-3"> */}
                {/* <div className="d-md-block d-none col-2 p-0">
                  <div className="fw-bold ">
                    <span className="fs-5 headings fw-bold">
                      {t("Categories")}
                    </span>
                    <div className="text-muted d-flex flex-column gap-2 py-3">
                      {
                        <Link
                          to="/giftcards"
                          className="category color-skyblue"
                        >
                          {t("All Products")}
                        </Link>
                      }
                      {this.props.giftCardCategories?.length > 0 &&
                        this.props.giftCardCategories.map((category) => (
                          <Link
                            key={category}
                            to={`/giftcards/${category?.name}/${category?.id}`}
                          >
                            {t(category?.name)}
                          </Link>
                        ))}
                    </div>
                  </div>
                </div> */}
                <div>
                  <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-2">
                    {giftcards}
                  </div>
                  {this.props.brandDataTotalRecord?.length !==
              this.state.cardsDetails?.length ? (
                <div className='d-flex justify-content-center w-100 mt-3' style={{marginBottom:"7rem"}}>
                  <button
                    disabled={this.state.loading}
                    className="btn primary-btn no-outline"
                    onClick={() => this.fetchNextPage()}
                  >
                    {this.state.loading ? "Loading...." : "Load More!"}
                  </button>
                </div>
              ) : null}
                </div>
              {/* </div> */}
              
            </section>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    giftCards: state.brand.brandData,
    giftCardCategories: state.brand.categories,
    brandDataTotalRecord: state.brand.brandDataTotalRecord,
    loading: state.brand.loading,
    letter: state.giftCards.letter,
    countrySelected: state.countries.countryId,
    currancyCode: state.countries.convertionRate.currency_code,
    countriesloading: state.countries.loading,
    fetchError: state.brand.error,
    selectedCountry: state.countries.selectedCountry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrands: (params, isReset) => dispatch(getBrandData(params, isReset)),
    brandsByCategory: (data) => dispatch(brandsByCategory(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GiftCardsPage));