import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../shared/Breadcrumbs";
import travelShopBanner from "../../assests/img/TravelShop/Travel-header.png";
import travelHeaderIcon from "../../assests/img/TravelShop/travel.png";
import hotelIcon from "../../assests/img/TravelShop/hotel.png";
import hotelFlightIcon from "../../assests/img/TravelShop/hotels-flights.png";
import taxiIcon from "../../assests/img/TravelShop/taxi.png";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import CalendarIcon from "../../assests/img/TravelShop/calendar.png";
import "react-datepicker/dist/react-datepicker.css";
import "./TravelShop.css";
import TravelFilter from './TravelFilter';
import SearchComponent from "./SearchComponent";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import SelectPassengerModal from "./SelectPassengerModal";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  fetchAirport,
  updatePassengerCount,
} from "../../Redux/travelShop/TravelAction";
import {
  RangeDatePicker,
  SingleDatePicker,
} from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import TravelShopDetails from "./TravelShopDetails";

class TravelShopResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Passenger: "Passenger",
      Class: "Class",
      passengerModal: false,
      startDate: null,
      endDate: null,
      tripType: "OneWay",
      flyingFrom: false,
      flyingTo: false,
      fromPlace: "",
      toPlace: "",
      passengerCount: {
        adults: 1,
        child: 0,
        infant: 0,
      },
      flyingToModal: false,
      showPassengerCount: false,
      passengerClass: "Economy",
      clearedDate: false,
      monthShown: 1,
      showDepatureCalendar: true,
      passengerType: "",
      passengerTypeCheck: {
        Economy: true,
      },
    };
  }

  componentDidMount() {
    let oneWayTripCheckbox =
      document.getElementsByClassName("oneWayTripCheckbox");
    if (oneWayTripCheckbox) {
      oneWayTripCheckbox[0].checked = true;
      oneWayTripCheckbox[1].checked = true;
    }
    this.props.fetchAirport();
  }

  componentDidUpdate(prevProps, prevState) {
    // this.disablePrevMonth();
  }

  passengerModal = () => {
    this.setState({
      passengerModal: false,
    });
    this.updatePassengerCount();
  };
  flyingToModal = () => {
    this.setState({
      flyingFrom: false,
      flyingTo: false,
    });
  };

  setPlace = (type, value) => {
    this.setState({ [type]: value });
  };

  //   Using Icon to open calendar
  openDatepicker = (type) => this[type]?.setOpen(true);

  passengerCountFunction = (passenger, type) => {
    if (
      !(
        Object.values(this.state.passengerCount).reduce((a, b) => a + b) ===
        10 && type === "add"
      ) &&
      !(
        type === "sub" &&
        this.state.passengerCount["adults"] === 1 &&
        passenger === "adults"
      )
    ) {
      let newPassengerCount = { ...this.state.passengerCount };
      if (type === "add") {
        newPassengerCount[passenger]++;
      } else {
        newPassengerCount[passenger] !== 0 && newPassengerCount[passenger]--;
      }
      this.setState({ passengerCount: newPassengerCount });
    }
  };

  passengerTypeChange = (type) => {
    this.setState({
      passengerType: type,
      passengerTypeCheck: {
        [type]: !this.state[type],
      },
    });
  };

  updatePassengerCount = () => {
    this.setState({ showPassengerCount: true });
    this.props.updatePassengerCount(this.state.passengerCount);
  };

  changePassengerClass = (value) => {
    this.setState({ passengerClass: value });
  };
  DesktopView = () => {
    const DepartureInputField = () => (
      <div
        style={{ position: "relative" }}
        className="dep-group cursor-pointer"
      >
        {this.state.startDate && (
          <span
            style={{ position: "absolute", top: "-18px" }}
            className="text-muted m-0 p-0 travelPlaceHolder"
          >
            Departure
          </span>
        )}
        <input
          style={{ caretColor: "transparent" }}
          type="text"
          className="dep-group border-0 no-outline cursor-pointer"
          placeholder="Departure"
          defaultValue={this.state.startDate?.toLocaleString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        />
      </div>
    );
    const { t } = this.props;
    const { tripType, passengerCount } = this.state;
    const breadcrumbs = [
      { name: t("Home"), url: "/", active: true },
      { name: t("Travel Shop"), url: `/flights`, active: true },
      { name: t("Search Results"), url: ``, active: false },
    ];
    return (
      <>
        <Breadcrumbs crumbs={breadcrumbs} />
        <div className="row d-md-block d-none">
          <div
            style={{ position: "relative" }}
            className="col-12 d-flex justify-content-center"
          >
            <div className="col-10 pb-4">
              <div className="d-flex row">
                <div className="col-12 mt-5 px-5 container">
                  {/* Section 1 */}
                  <div className="row">
                    <div className="col-12 d-flex">
                      <div className="col-2 border d-flex justify-content-center align-items-center p-2 mx-2">
                        <input
                          onClick={(e) => {
                            this.setState({ tripType: "RoundTrip" });
                          }}
                          name="tripTypeCheckbox"
                          type="radio"
                          className="mx-2 travelShopRadio"
                        />
                        <h6 className="text-black-50 mt-2">Round Trip</h6>
                      </div>
                      <div className="col-2 border d-flex justify-content-center align-items-center p-2 mx-2">
                        <input
                          onClick={(e) => {
                            this.setState({ tripType: "OneWay" });
                          }}
                          name="tripTypeCheckbox"
                          type="radio"
                          className="mx-2 travelShopRadio oneWayTripCheckbox"
                        />
                        <h6 className="text-black-50 mt-2">One way</h6>
                      </div>
                      <div
                        className="col-4 mx-2 p-2 border d-flex align-items-center cursor-pointer"
                        id="passengerToggle"
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="bg-transparent border-0 text-black-50 w-100 d-flex align-items-center justify-content-between no-outline pb-0"
                          >
                            <h6 style={{ display: "inline-block" }}>
                              {this.state.showPassengerCount
                                ? `${passengerCount.adults} Adult ${passengerCount.child > 0
                                  ? `, ${passengerCount.child} Child`
                                  : ""
                                } ${passengerCount.infant > 0
                                  ? `, ${passengerCount.infant} Infant`
                                  : ""
                                }`
                                : "Passengers"}
                            </h6>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100 px-2 mt-1">
                            <div className="bg-transparent d-flex justify-content-between align-items-center row mx-2">
                              <p className="col-8 text-dark fw-bold">
                                {t("Adults")}{" "}
                                <small className="text-black-50 fw-bold">
                                  {t("(12+ years)")}
                                </small>
                              </p>
                              <div className="d-flex col-4 justify-content-between">
                                <p
                                  onClick={() =>
                                    this.passengerCountFunction("adults", "sub")
                                  }
                                  style={{ fontSize: "1.2em" }}
                                  className="text-black-50 py-1 btn"
                                >
                                  -
                                </p>
                                <p
                                  style={{ fontSize: "1.2em" }}
                                  className="py-1"
                                >
                                  {passengerCount.adults}
                                </p>
                                <p
                                  onClick={() =>
                                    this.passengerCountFunction("adults", "add")
                                  }
                                  style={{ fontSize: "1.2em" }}
                                  className="color-blue py-1 btn"
                                >
                                  +
                                </p>
                              </div>
                            </div>
                            <div className="bg-transparent d-flex justify-content-between align-items-center row mx-2">
                              <p className="col-8 text-dark fw-bold">
                                {t("Childs")}{" "}
                                <small className="text-black-50 fw-bold">
                                  {t("(2 - 12 years)")}
                                </small>
                              </p>
                              <div className="d-flex col-4 justify-content-between">
                                <p
                                  onClick={() =>
                                    this.passengerCountFunction("child", "sub")
                                  }
                                  style={{ fontSize: "1.2em" }}
                                  className="text-black-50 py-1 btn"
                                >
                                  -
                                </p>
                                <p
                                  style={{ fontSize: "1.2em" }}
                                  className="py-1"
                                >
                                  {passengerCount.child}
                                </p>
                                <p
                                  onClick={() =>
                                    this.passengerCountFunction("child", "add")
                                  }
                                  style={{ fontSize: "1.2em" }}
                                  className="color-blue py-1 btn"
                                >
                                  +
                                </p>
                              </div>
                            </div>
                            <div className="bg-transparent d-flex justify-content-between align-items-center row mx-2">
                              <p className="col-8 text-dark fw-bold">
                                {t("Infant")}{" "}
                                <small className="text-black-50 fw-bold">
                                  {t("(below 2 years)")}
                                </small>
                              </p>
                              <div className="d-flex col-4 justify-content-between">
                                <p
                                  onClick={() =>
                                    this.passengerCountFunction("infant", "sub")
                                  }
                                  style={{ fontSize: "1.2em" }}
                                  className="text-black-50 py-1 btn"
                                >
                                  -
                                </p>
                                <p
                                  style={{ fontSize: "1.2em" }}
                                  className="py-1"
                                >
                                  {passengerCount.infant}
                                </p>
                                <p
                                  onClick={() =>
                                    this.passengerCountFunction("infant", "add")
                                  }
                                  style={{ fontSize: "1.2em" }}
                                  className="color-blue py-1 btn"
                                >
                                  +
                                </p>
                              </div>
                            </div>
                            <Dropdown.Item className="border-0 no-outline bg-transparent">
                              <button
                                style={{ border: "2px solid darkblue" }}
                                className="btn w-100"
                                onClick={this.updatePassengerCount}
                              >
                                <span className="fw-bold color-blue">
                                  {t("Confirm")}
                                </span>
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-3 mx-2 p-2 border d-flex align-items-center cursor-pointer">
                        <Dropdown>
                          <Dropdown.Toggle
                            className="bg-transparent border-0 text-black-50 w-100 d-flex align-items-center justify-content-between no-outline pb-0"
                            id="dropdown-basic"
                          >
                            <h6 style={{ display: "inline-block" }}>
                              {this.state.passengerClass}
                            </h6>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100 px-2 mt-1">
                            {["Economy", "Business", "First"].map((item) => (
                              <Dropdown.Item
                                className="text-dark fw-bold"
                                onClick={() => {
                                  this.setState({ passengerClass: item });
                                }}
                              >
                                {item}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  {/* Section 2 */}
                  <div className="my-3 mx-2 row">
                    <div
                      className={`travelSection2 ${tripType === "OneWay"
                        ? "OneWaytravelSection2"
                        : "twoWaytravelSection2"
                        }`}
                    >
                      <SearchComponent
                        type={"Flying From"}
                        status={"fromPlace"}
                        setPlace={this.setPlace}
                        pickedPlace={this.state.toPlace}
                      />
                      <SearchComponent
                        type={"Flying To"}
                        status={"toPlace"}
                        setPlace={this.setPlace}
                        pickedPlace={this.state.fromPlace}
                      />

                      <div className="border d-flex align-items-center justify-content-between p-0 w-100">
                        <div className="cursor-pointer flightCalendar m-0 p-0 px-1">
                          {tripType === "OneWay" ? (
                            <DatePicker
                              className="border-0 no-outline cursor-pointer"
                              dateFormat="MMMM d, yyyy"
                              customInput={<DepartureInputField />}
                              minDate={new Date()}
                              ref={(c) => (this.OneWayCalendar = c)}
                              placeholderText="Depature"
                              startDate={this.state.startDate}
                              selected={this.state.startDate}
                              onChange={(dates) =>
                                this.setState({ startDate: dates })
                              }
                            ></DatePicker>
                          ) : (
                            <div className="border-0 no-outline cursor-pointer w-100">
                              {this.state.showDepatureCalendar ? (
                                <DatePicker
                                  className="border-0 no-outline cursor-pointer"
                                  dateFormat="MMMM d, yyyy"
                                  customInput={<DepartureInputField />}
                                  minDate={new Date()}
                                  selected={this.state.startDate}
                                  onChange={(dates) =>
                                    this.setState({ startDate: dates })
                                  }
                                  selectsStart
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  ref={(c) => (this.DepatureCalendar = c)}
                                  placeholderText="Depature"
                                  shouldCloseOnSelect={true}
                                ></DatePicker>
                              ) : (
                                <DatePicker
                                  className="border-0 no-outline cursor-pointer"
                                  dateFormat="MMMM d, yyyy"
                                  customInput={<DepartureInputField />}
                                  minDate={new Date()}
                                  ref={(c) => (this.ReturnCalendar = c)}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  monthsShown={2}
                                  selectsRange={true}
                                  selected={this.state.endDate}
                                  onChange={(date) =>
                                    this.setState({ endDate: date[1] })
                                  }
                                  selectsEnd
                                  shouldCloseOnSelect={true}
                                ></DatePicker>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          onClick={() => {
                            this.openDatepicker(
                              this.state.tripType === "OneWay"
                                ? "OneWayCalendar"
                                : "DepatureCalendar"
                            );
                            this.setState({ showDepatureCalendar: true });
                          }}
                          className="bg-color-green px-3 align-self-stretch d-flex align-items-center cursor-pointer"
                        >
                          <img loading='lazy' decoding='async'
                            src={CalendarIcon}
                            className="mx-auto"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                      {tripType === "RoundTrip" && (
                        <div
                          style={{
                            caretColor: "transparent",
                            position: "relative",
                          }}
                          className="border d-flex align-items-center p-0 w-100"
                        >
                          <div className="col-10 flightCalendar m-0 p-0 px-1 cursor-pointer">
                            <div className="px-1">
                              {this.state.endDate && (
                                <span
                                  style={{ position: "absolute", top: "-20px" }}
                                  className="text-muted p-0 travelPlaceHolder"
                                >
                                  {t("Return")}
                                </span>
                              )}
                              <input
                                type="text"
                                className="dep-group border-0 no-outline cursor-pointer m-0 p-0"
                                placeholder={t("Return")}
                                defaultValue={
                                  this.state.endDate
                                    ? this.state.endDate.toLocaleString(
                                      "en-US",
                                      {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              this.openDatepicker("ReturnCalendar");
                              this.setState({ showDepatureCalendar: false });
                            }}
                            className="col-2 bg-color-green m-0 p-0 align-self-stretch d-flex align-items-center cursor-pointer"
                          >
                            <img loading='lazy' decoding='async'
                              src={CalendarIcon}
                              className="mx-auto"
                              alt=""
                              srcset=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mx-4 justify-content-end">
                    <button className="btn btn-primary rounded-0 mx-3">
                      {t("Change Details")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray py-4 px-xl-5">
          <Container fluid className="sidebarmain mt-4">
            <Row className="mt-4">
              <Col lg={3}>
                <TravelFilter />
              </Col>
              <Col lg={9}>
                <TravelShopDetails />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  };

  MobileView = () => {
    const { t } = this.props;
    const { tripType } = this.state;
    return (
      <>
        <div className="d-block d-md-none">
          <div className="px-3 py-3 d-flex">
            <div className="mr-3 d-flex justify-content-center align-items-center">
              <input
                className="d-inline mr-2 travelShopRadio"
                type="radio"
                onClick={(e) => {
                  this.setState({ tripType: "RoundTrip" });
                }}
                name="tripTypeCheckboxMobile"
              />
              <label className="mb-0 ml-2" for="html">
                Round-Trip
              </label>
            </div>
            <div className="mr-3 d-flex justify-content-center align-items-center">
              <input
                className="d-inline mr-2 
              travelShopRadio oneWayTripCheckbox"
                onClick={(e) => {
                  this.setState({ tripType: "OneWay" });
                }}
                name="tripTypeCheckboxMobile"
                type="radio"
              />
              <label className="mb-0 ml-2" for="html">
                One-Way
              </label>
            </div>
          </div>
          <div className="py-3 px-2">
            <div
              className="border w-auto mx-3"
              onClick={() => this.setState({ flyingFrom: true })}
            >
              <h6 className="px-3 py-3">
                {this.state.fromPlace || "Flying From"}
              </h6>
            </div>
            <div
              className="border w-auto mx-3 mt-4"
              onClick={() => this.setState({ flyingTo: true })}
            >
              <h6 className="px-3 py-3">{this.state.toPlace || "Flying To"}</h6>
            </div>
            <div
              className="border w-auto mx-3 mt-4 arrow-down"
              onClick={() => this.setState({ passengerModal: true })}
            >
              <h6 className="p-3" style={{ display: "inline-block" }}>
                {this.state.showPassengerCount
                  ? `${this.state.passengerCount.adults} Adult ${this.state.passengerCount.child > 0
                    ? `, ${this.state.passengerCount.child} Child`
                    : ""
                  } ${this.state.passengerCount.infant > 0
                    ? `, ${this.state.passengerCount.infant} Infant`
                    : ""
                  }`
                  : "Passengers"}{" "}
                {this.state.passengerClass}{" "}
              </h6>
              {/* <h6 className='px-3 py-3'>{t(Passenger )} {this.state.person} / {t(Class)}</h6> */}
            </div>
          </div>
          <div>
            {/* <div className='cursor-pointer px-4 mt-4'  >
                   <div className='d-flex'>
                       <span  onClick={this.openDatepicker} className='border p-3 w-auto'>{t("Departure")}</span>
                       <span  onClick={this.openDatepicker} className='border p-3 w-auto'>{t("Return")}</span>
                   </div>
                </div> */}
            {tripType === "RoundTrip" ? (
              <>
                <div className="py-4 px-2">
                  <RangeDatePicker
                    startDate={new Date()}
                    endDate={new Date()}
                    minDate={new Date()}
                    startDatePlaceholder="Departure"
                    endDatePlaceholder={t("Return")}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="py-4 px-2">
                  <SingleDatePicker
                    minDate={new Date()}
                    startDatePlaceholder="Departure"
                    startDate={new Date()}
                  />
                </div>
              </>
            )}
          </div>

          <div className="  text-center mx-3">
            <button className="btn btn-primary rounded-0  w-100">
              {t("Search Flights")}
            </button>
          </div>
        </div>
      </>
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <SelectPassengerModal
          show={this.state.passengerModal}
          showModal={this.passengerModal}
          passengerCount={this.state.passengerCount}
          passengerCountFunction={this.passengerCountFunction}
          passengerTypeChange={this.passengerTypeChange}
          passengerType={this.state.passengerType}
          passengerTypeCheck={this.state.passengerTypeCheck}
          changePassengerClass={this.changePassengerClass}
          passengerClass={this.state.passengerClass}
        />
        <this.MobileView />
        <div className="d-block d-md-none">
          <SearchComponent
            type={"Flying From"}
            status={"fromPlace"}
            setPlace={this.setPlace}
            pickedPlace={this.state.toPlace}
            showModal={this.flyingToModal}
            show={this.state.flyingFrom}
          />
          <SearchComponent
            type={"Flying To"}
            status={"toPlace"}
            setPlace={this.setPlace}
            pickedPlace={this.state.fromPlace}
            showModal={this.flyingToModal}
            show={this.state.flyingTo}
          />
        </div>
        <this.DesktopView />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    contactDetails: state.travel.contactDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassengerCount: (passengerCount) =>
      dispatch(updatePassengerCount(passengerCount)),
    fetchAirport: () => dispatch(fetchAirport()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(TravelShopResults)));
