import React, { Component } from 'react';
// import TermsConditionModal from '../shared/Modals/TermsConditionModal';
import  cardImg from '../../assests/img/card.svg'
import { connect } from 'react-redux';
import { currancyToPoints, otherPointsToCurrancy, pointsToCurrancy } from '../../utils/pointsConversion';
import MasterCard from "../../assests/img/MasterCard.png";
import Visa from "../../assests/img/Visa.png";
import { withTranslation } from 'react-i18next';
class OrderSummaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            checkoutBtn:true,
            missingPointsToCurrency:null
         }
    }
    submitFunc(){
        if(this.props.validForm()){
            const data = {
                firstName: this.props.formFeilds.firstName.value,
                address: this.props.formFeilds.address.value,
                city:this.props.formFeilds.city.value,
                companyName:this.props.formFeilds?.companyName?.value,
                country:this.props.formFeilds.country.value,
                email:this.props.formFeilds.email.value,
                lastName:this.props.formFeilds.lastName.value,
                phNumber:this.props.physicalCard ? `${this.props.phoneCode}${this.props.formFeilds.phNumber.value}` : this.props.formFeilds.phNumber.value,
                state:this.props.formFeilds.state.value,
                zip:this.props.formFeilds.zip.value,   
            }
            this.props.setShippingPage.setShippingPage(data)
        }
        
    }
    numberWithCommas = (x) => {
        let num = +x;
        let y = num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    numberWithCom = (x) => {
        let num = +x;
        let y = num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    setCheckoutBtn(val){
        this.setState(old=>({
            checkoutBtn:!old.checkoutBtn
        }))
    }
    componentDidUpdate(prevProps){
        if(this.props.physicalCard && prevProps.currencyConversonLoading !== this.props.currencyConversonLoading){
            const missingPoints = this.props.giftcardPoints - this.props.pointBalance
            this.setState({missingPointsToCurrency:otherPointsToCurrancy(missingPoints,true)})
        }
    }
    render() { 
        
        
        // const orderDetails ={
        //     ...this.props.order,
        //     payable_amount:this.props.order.amount
        // }
        const {t,order,pointBalance,currancyCode,physicalCard,giftcardCurrency} = this.props;
        const total = physicalCard ? this.props.giftcardPoints : currancyToPoints(order.amount);
        const missingPoints = total - pointBalance;
        const {checkoutBtn,missingPointsToCurrency}= this.state
        const desktopView=(
            <div className="d-md-block d-none">
                <div 
                    className="card mb-4 rounded-0"
                    style={{width:'17rem'}}>
                    <div className="card-body">
                    <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5  product_title">{t("Order Summary")}:</div>
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                            {
                                this.props.lang!=='ar'?
                                <h5 className="text-dark fw-bold m-0 fs-regular">{this.numberWithCommas(total)} <small>{t("Points")}</small></h5>
                                :
                                <h5 className="text-dark fw-bold m-0 fs-regular"><small>{t("Points")}</small> {this.numberWithCommas(total)}</h5>

                            }
                        </div>
                        <div className="d-flex flex-row justify-content-between py-2">
                        <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {this.props.lang!=='ar'?
                            <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(pointBalance)}<small> {t("Points")}</small></h5>
                            :
                            <h5 className="text-danger fw-bold m-0 fs-regular"><small> {t("Points")}</small> {this.numberWithCommas(pointBalance)}</h5>
                        }
                        </div>
                        {
                            missingPoints>0 && !this.props.cardPayment &&
                            <>
                            
                            <div className="d-flex flex-row justify-content-between py-2">
                                <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                                {
                                   this.props.lang!=='ar'?
                                   <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(missingPoints)} <small>{t("Points")}</small></h5> 
                                    :
                                    <h5 className="text-danger fw-bold m-0 fs-regular"><small>{t("Points")}</small> {this.numberWithCommas(missingPoints)} </h5>
                               }
                            </div>
                            <div className="d-flex flex-row py-3">
                                <div className="p-0 col-2">
                                    <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                                </div>
                                <div className="px-1 col-10">
                                <h5 className="product_title fw-bold m-0"> {this.numberWithCommas(missingPoints)} {t("Points")} ({this.numberWithCom(physicalCard ? missingPointsToCurrency : pointsToCurrancy(missingPoints))} {physicalCard ? giftcardCurrency :currancyCode})</h5>
                                    <small className="text-muted fw-bold">
                                        {t("to be paid by  Credit Card")}
                                    </small><br/>
                                    <img src={Visa} alt="card"/>
                                    <img src={MasterCard} alt="card"/>
                                </div>
                            </div>
                            </>
                            
                        }
                        {
                            this.props.cardPayment && <div className="d-flex flex-row py-3">
                            <div className="p-0 col-2">
                                <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                            </div>
                            <div className="px-1 col-10">
                                <h5 className="product_title fw-bold m-0 fs-regular"> {this.numberWithCommas(total)} {t("Points")} ({this.numberWithCom(order.amount)} {t(physicalCard ? giftcardCurrency :currancyCode)})</h5>
                                <small className="text-muted fw-bold">
                                    {t("to be paid by  Credit Card")}
                                </small><br/>
                                <img src={Visa} alt="card"/>
                                <img src={MasterCard} alt="card"/>
                            </div>
                        </div>
                        }
                        <hr className="hr-line"/>
                        <div className="d-flex flex-row justify-content-between pt-1 pb-3">
                            <h5 className="text-dark fw-bold m-0">{t("Total")}</h5>
                            {
                                this.props.lang!=='ar' && (this.props.cardPayment || missingPoints > 0)?
                                <span className=" product_value fs-large" > 
                                { this.props.cardPayment ? this.numberWithCommas(order.amount) : this.numberWithCom(physicalCard ? missingPointsToCurrency : pointsToCurrancy(missingPoints))} <small>{t(physicalCard ? giftcardCurrency :currancyCode)}</small></span>
                                :
                                this.props.lang==='ar' && (this.props.cardPayment || missingPoints > 0)?
                                <span className="product_value fs-large">
                                    &nbsp;{t(physicalCard ? giftcardCurrency :currancyCode)} {missingPoints > 0 ? this.numberWithCom(physicalCard ? missingPointsToCurrency : pointsToCurrancy(missingPoints)) : this.numberWithCommas(order.amount)} 
                                    </span>
                                :
                                this.props.lang==='ar' && !this.props.cardPayment?
                                <span className="product_value fs-large">&nbsp;{t("Points")} {this.numberWithCommas(physicalCard ? this.props.giftcardPoints : currancyToPoints(order.amount))} </span>
                                :
                                this.props.lang!=='ar' && !this.props.cardPayment?
                                <span className=" product_value fs-large" >  {this.numberWithCommas(physicalCard ? this.props.giftcardPoints : currancyToPoints(order.amount))} <small className="fw-normal ">{t("Points")}</small></span>
                                :null

                            }
                        </div>
                        {/* <div>
                            <TermsConditionModal lang={this.props.lang} show={false}/>
                            <br/>
                              <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" >{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div> */}
                        {/* <br/> */}
                        {/* </div> */}
                        <br/>
                        <button className="btn btn-block primary-btn rounded-0 text-center no-outline" onClick={()=>{this.submitFunc()}} >{t("Buy Now")}</button>
                    </div>
                </div>
            </div>
           
        )
        const mobileView=(
            <div className="d-md-none d-block">
                {
                    missingPoints > 0 && <div className="position-relative bg-product_title w-100 py-1" 
                    style={{top:`${(this.props.lang !== "ar" && physicalCard) ? "-68rem" 
                    : (this.props.lang === "ar" && physicalCard) ? "-69rem" :
                    this.props.lang !== "ar" ? "-74rem" : "-72rem"}`,left:'0'}}>
                    <h4 className="d-inline">{this.numberWithCom(physicalCard ? missingPointsToCurrency : pointsToCurrancy(missingPoints))} {physicalCard ? giftcardCurrency :currancyCode}</h4>
                    <div>{t("to be paid by  Credit Card")}</div>
                </div>
                }
                <div className="position-fixed  w-100 bg-light px-2" style={{bottom:'5rem',left:'0'}}>
                    <button 
                        onClick={()=>{this.submitFunc()}}
                        className="btn primary-btn w-100 rounded-0 p-2">
                        {t("Buy Now")}
                    </button>
                </div>
            </div>
        )
        return ( 
            <>
                {desktopView}
                {mobileView}
                <div className="d-md-none d-block m-4 p-2">
                </div>
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        orderDetails:state.order.orderDetails,
        pointBalance:state.point.pointBalance,
        currancyCode:state.countries.convertionRate.currency_code,
        currencyConversonLoading: state.conversion.loading,
    }
}

 
export default connect(mapStateToProps)(withTranslation()(OrderSummaryCard)) ;
