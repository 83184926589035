import React, { Component } from 'react';
import infoCircle from '../../assests/img/info.svg'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { checkTokenValidity } from '../../Redux/auth/authActions';
import FailMessageModal from '../shared/Modals/FailMessageModal';
import { PointsExchangeID } from '../../utils/app-constants';
import MainLoginModel from 'components/shared/Modals/MainLoginModel';
class PointExchangeCardPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            card:{...this.props.location.state},
            identifiactionNumber:null,
            hassadPoints:null,
            rewardPoints:null,
            validNumber : false,
            byNowButton:true,
            showPopup:true,
            pointExchangeCards : [],
            show:false
         }
    }
    close = () =>{
        this.setState({show:false},()=>{
            this.props.history.goBack()
        })
    }
    fetchPoint =()=> {
        let pointExchangeCards = []
        this.props.giftCards?.forEach(item => {
                item.brands.forEach(card => {
                    if(card.point_exchange_flag && card.id == this.props.match.params.id ) {
                        pointExchangeCards.push(card)
                    }
                });
            })

        this.setState({
            pointExchangeCards:pointExchangeCards
        })
      }
    setIdentifiactionNumber=(e,val)=>{
        const ID = this.state.pointExchangeCards?.[0].id
        if(Number(ID) === Number(PointsExchangeID?.Neqaty)){
            let val2 = String(val)
            if(val2?.startsWith("0")){
                val = val2?.slice(1)
            }            
        }
        this.setState({
            identifiactionNumber:val
        })
    }
    setHassadPoints=(e,val)=>{
        val=parseInt(val)
        let hassadPointAlert = document.getElementsByClassName('hassadPointAlert')[0]
        if(val<=this.props.pointBalance){
            this.setState({
                hassadPoints:val,
                rewardPoints:val
            })
            
            hassadPointAlert.style.display = 'none'
        }
        else if(val>this.props.pointBalance){
            hassadPointAlert.style.display = 'block'
            document.getElementById('alert2').style.display = 'none'
            document.getElementById('alert1').style.display = 'block'
           
        }
    }
    NeqatyChecker(){
        let number =  ""
        if(this.state.identifiactionNumber){
            number = this.state.identifiactionNumber
        }
       let numLength = number.split('').length
       let validNumberAlert = document.getElementsByClassName('mobAlert')[0]
       
       if(numLength<9){
        validNumberAlert.style.display = 'block'
       }
       else{
        this.HassadPointChecker()
        }
       
    }
    
    AlFursanChecker=()=>{
       
        let memberID = this.state.identifiactionNumber || 0;
        // Commenting Mod7 alg. since AlFursan accepts 9 and 10 digits numbers
        // let sevenDigits = memberID.toString().slice(0,7)
        // if(sevenDigits%7 != memberID.toString().slice(-1) || memberID <=1000){
        //     document.getElementsByClassName('memberAlert')[0].style.display = 'block'
        // }

        if(memberID <=1000){
            document.getElementsByClassName('memberAlert')[0].style.display = 'block'
        }
        else{
            this.HassadPointChecker()
        }    
    }
    HassadPointChecker = () =>{
        if(!this.props.valid){
            this.setState({show:true})
            return
        }
        let hassadPoint = this.state.hassadPoints
        let memberID = document.getElementsByClassName('memberAlert')[0]
        let validNumberAlert = document.getElementsByClassName('mobAlert')[0]
        
        validNumberAlert ? validNumberAlert.style.display='none' : memberID.style.display = 'none'
        let hassadPointAlert = document.getElementsByClassName('hassadPointAlert')[0]
        let continueButton = document.getElementById('continue-button')
        
        if(hassadPoint == null || hassadPoint<1000){ 
            // document.getElementsByClassName('memberAlert')[0].style.display = 'none'
            hassadPointAlert.style.display = 'block'
            document.getElementById('alert1').style.display = 'none'
            document.getElementById('alert2').style.display = 'block'
           
            }
        else {
            this.setState({validNumber:true},()=>{
                continueButton.click()
            })
        }
    }
    handleKeyPress = (event) => {
        if(typeof this.state.hassadPoints != "object" && event.key === "Backspace"){
            if(this.state.hassadPoints.toString().length){
                this.setState({hassadPoints:null})
            }
        }
      }
      componentDidMount(){
        if(this.props.countryId !== 2){
            this.props.history.push({
                pathname:"/404",
                redirectionURL:"/points-exchange"
            })
        }
        this.fetchPoint(this.props.match.params.id)
            this.props.checkTokenValidity()
      }
      componentDidUpdate(prevProps,prevState){
        if((prevProps.giftCards !== this.props.giftCards) ){
            this.fetchPoint(this.props.match.params.id)
            
        }
        if(( prevProps.currancyCode !== this.props.currancyCode && this.props.countryId !== 2) ){
            this.props.history.push({
                pathname:"/404",
                redirectionURL:"/points-exchange"
            })
        }
      }
    render() { ;
     
    const {t} = this.props
    const card = this.state.pointExchangeCards
        return ( 
            <>
            {
                this.props.authToken ? "":<MainLoginModel show={this.state.show} loginFun={()=>this.setState({show:!this.state.show})} lang={this.props.lang} toogleLang={this.props.toogleLang}/>
            }
            <div>
               {/* {this.props.authLoading ? <div style={{position:'absolute',top:'0',height:'100vh'}} class="d-flex justify-content-center pt-2 pb-2">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> : } */}
                {
                   this.props.pointLoading || this.props.authLoading? 
                    <div class="d-flex justify-content-center pt-2 pb-2">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>:
                
            <div>
                    <div className="p-md-5 p-3 d-flex flex-md-row flex-column">
                        {
                            this.props.valid && <FailMessageModal show={!this.props.pointBalance} lang={this.props.lang} Message={"Points Redemption Failed"}/>
                        }
                       { card && card.map(card =>
                           <>     
                             <div className="col-3 p-0 d-md-block d-none">
                                    <img loading='lazy' decoding='async'  
                                        className="img-fluid p-0 mx-auto d-block"   
                                        src={card?.images?.color?.large_rectangle}
                                                alt={card.name}
                                    />
                                     <div
                    
                                className="text-center text-muted py-3">
                   { this.props.lang !== 'ar' ? card?.name:card?.name_arabic }
            </div>
                            </div>
                            <div className="flex-grow-1 px-md-5 px-0">
               
                <div className='d-md-none d-block p-3'>
                    <img loading='lazy' decoding='async' 
                        className="mx-auto d-block"
                        src={card?.images?.color?.large_rectangle}
                        alt={card?.name}
                        style={{objectFit:'cover',height:'40vw',maxWidth:'100%'}}/>
                </div>
                <hr className="hr-line my-2"/>
                
                         <div className=" px-4">
                {
                                card.id === Number(PointsExchangeID.AlFursan) ?
                                <div className="text-muted pb-2">
                                    <div className="p-0">
                                    {t("Enter your Member ID")}
                                    </div>
                                    
                                   <form action="">
                                   <input 
                                   
                                        className="border rounded-0 p-2 px-3 my-3 text-muted m-0"
                                        style={{width:'10rem'}}
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        onInput={(e) => 
                                         e.target.value = e.target.value.slice(0, 10)}
                                        onChange={(e)=>{this.setIdentifiactionNumber(e,e.target.value)}}
                                        value={this.state.identifiactionNumber}
                                        onWheelCapture={e => {e.target.blur()}}
                                        type="number" 
                                        min='0'
                                        maxLength={10}
                                        required
                                        autoFocus
                                        name="memberId"/>
                                   </form>
                                        <div style={{display:'none'}}  class="memberAlert alert alert-warning" role="alert">
                                        {t("Member ID is invalid")}
                                    </div>
                                    <div className="d-block">* {t("Make sure to enter the right member ID")}</div>
                                    <div className="d-block">*{t("Miles will be added to your account within 10 business days")}</div>
                                    
                                </div>:
                                
                                <div className="text-muted pb-2">
                                    <div className="p-0">
                                    {t("Enter your Mobily Mobile Number")}
                                    </div>
                                    <div 
                                        className="d-flex py-2 my-2"
                                        style={{width:'18rem'}}>
                                        <span className="px-3 py-2 bg-light-gray">966</span>
                                        <input 
                                            style={{width:'10rem'}}
                                            className="border rounded-0 p-2 px-3 text-muted "
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 9)}
                                            onChange={(e)=>{this.setIdentifiactionNumber(e,e.target.value)}}
                                            value={this.state.identifiactionNumber}
                                            onWheelCapture={e => {e.target.blur()}}
                                            type="number"
                                            min='0'
                                            required
                                            autoFocus
                                            name="neqatyNumber"/>
                                    </div>
                                    <div style={{display:'none'}} class=" mobAlert alert alert-warning" role="alert">
                                    {t("Enter a valid mobile number")}
                                    </div>
                                    <div className="">*{t("Make Sure to enter the right mobile number")}</div>                                    
                                </div>
                            }
                        <div className="py-3 " >
                        <div className="py-3 " >
                                    <h6 className="bg-light-gray px-2 py-3 m-0 d-inline " style={{fontSize:'12px'}}>
                                        <img loading='lazy' decoding='async' className="img-fluid h-100 align-middle pb-1 mr-2" style={{width:'1.5rem'}} src={infoCircle} alt=''/>
                                        1,000 {t("Rewards")} <span className="text-muted">{t("Points")} </span>
                                        = 1,000 { this.props.lang !== 'ar' ? card.name:card.name_arabic } <span className="text-muted">{card.rewardType}</span>
                                    </h6>
                                </div>  
                        </div>

                        
                        <div className="text-muted py-2">
                                    <div className="p-0">
                                    {t("Rewards Points")}
                                    </div>
                                   <form action="">
                                   <input 
                                       onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                       onWheelCapture={e => {e.target.blur()}}
                                       onKeyUpCapture={this.handleKeyPress}
                                        className="border rounded-0 p-2 px-3 my-3 text-muted"
                                        style={{width:'10rem'}}
                                        onChange={(e)=>{this.setHassadPoints(e,e.target.value)}}
                                        value={this.state.hassadPoints}
                                        type="number" 
                                        required
                                        name="hassadPoints"/>
                                   </form>
                                        <div style={{display:'none'}}  class="hassadPointAlert alert alert-warning" role="alert">

                                            <p id='alert1'>{`${t("Please use within the Available Points")}`}</p>
                                            <p id='alert2'>{`${t("Minimum value should be 1000")}`}</p>

                                        </div>
                                    <div className="d-block p-0">* {t("Available Points")}: <span className="color-blue"><h6 className="d-inline m-0">{this.props.pointBalance}</h6></span></div>
                                </div>
                                
                        <div className="text-muted py-2">
                                    <div className="">
                                    { this.props.lang !== 'ar' ? `${card.name} ${t("Points")}`: `${card.name_arabic} ${t("Points")}` } {card.rewardType}
                                    </div>
                                   <form >
                                   <input 
                                        className="form-control border rounded-0 p-2 px-3 my-3 text-muted bg-light-gray"
                                        style={{width:'10rem'}}
                                        type="number" 
                                        value={this.state.rewardPoints}
                                        readOnly
                                        name="alFurshanMiles"/>  
                                       </form>                                  
                                </div>
                        <div>
                            
                        </div>
                        </div>
                        <hr className="hr-line my-4"/>    
                    </div>
                    </>
                        ) }
                </div>
               
                 <div
                            className="d-flex justify-content-between px-4 py-3 mt-3 w-100 bg-light-gray">
                                 {card && card.map(card =>
                      <>
                            <a href
                                onClick={()=>{this.props.history.goBack()}}
                                className="btn text-muted rounded-0 py-2 px-0"    
                            >{t("Back")}
                            </a>
                            <Link
                                
                                className="btn bg-color-green text-light rounded-0 mb-5"
                                id='continue-button'
                                to={
                                    this.state.validNumber ? {
                                        pathname:`/points-exchange/confirm-details/${card.id}`,
                                        state:{card,
                                                identifiactionNumber:card.id === Number(PointsExchangeID.AlFursan) ? this.state.identifiactionNumber : `966${this.state.identifiactionNumber}`   ,
                                                hassadPoints:this.state.hassadPoints,
                                                rewardPoints:this.state.rewardPoints 
                                            }
                                    } : this.props.location
                                }
                                onClick={()=>card.id === Number(PointsExchangeID.AlFursan) ? this.AlFursanChecker() : this.NeqatyChecker()}
                            >{t("Continue")}
                            </Link> 
                            </>
                              )}
                        </div>
            </div>
    }
          </div>
         </> 
         );
    }
}
 
const mapStateToProps = state => {
    return {
        giftCards:state.brand.brandData,
        loading:state.brand.loading,
        authToken:state.auth.authToken,
        pointLoading:state.point.loading,
        pointBalance:state.point.pointBalance,
        authLoading:state.auth.loading,
        fetchError:state.point.error,
        currancyCode:state.countries.convertionRate.currency_code,
        valid:state.auth.valid,
        countryId:Number(state.countries.countryId)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkTokenValidity : () => dispatch(checkTokenValidity()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(PointExchangeCardPage));