import React, { Component } from "react";
import Password from "../../../assests/img/Login/password.svg";
import { ReactComponent as Reset } from "../../../assests/img/Login/reset-password.svg";
import { ReactComponent as PasswordChanged } from "../../../assests/img/Login/password-changed-successfully.svg";
import Padlock from "../../../assests/img/Login/padlock.png"
import Shutdown from "../../../assests/img/Login/shutdown.png"
import User from "../../../assests/img/Login/user.png"
import Wallet from "../../../assests/img/Login/wallet.png"
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReactComponent as TokenExp } from "../../../assests/img/Login/token-expired.svg";
import { ReactComponent as TokenInvalid } from "../../../assests/img/Login/token-invalid.svg";
import ModalWrapper from "components/shared/Modals/ModalWrapper";
import Cancel from "../../../assests/img/Login/cancel-btn.svg"
import { PassRest, ResetClear } from "Redux/PasswordReset/ResetAction";
import { logout } from "Redux/auth/authActions";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import cookie from 'react-cookies';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: "",
      newPassword: "",
      confrimNewPassword:"",
      mismatch: false,
      show:false,
      required1:false,
      required2:false,
      passwordError:"",
      commonError:false,
    };
  }

  handlePassword = () =>{
    const validPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");

    if(this.state.oldpassword === "" || this.state.newPassword === "" || this.state.confrimNewPassword === ""){
      this.setState({commonError:true})
    }else if(!validPassword.test(this.state.newPassword)){
      this.setState({passwordError:"invalid",commonError:false})
    }else if(this.state.oldpassword === this.state.newPassword){
          this.setState({mismatch:true, commonError:false, passwordError:""})
    }else if(this.state.newPassword !== this.state.confrimNewPassword){
          this.setState({passwordError:"mismatch",mismatch:false,commonError:false})
    }else{
          this.setState({mismatch:false,required2:false,required1:false,passwordError:""})
          const token = localStorage.getItem("userToken")
          this.props.PassRest(this.state.oldpassword, this.state.newPassword, this.state.confrimNewPassword, token)
      }
    }

  handleCross = () =>{
    const msg = this.props.passwordChanged
    if(msg === "Password updated successfully!" || msg === "You need to login to continue."){
      this.props.logout(this.redirectToHomePage)
    }else{
      this.setState({show:false})
      this.props.ResetClear()
      this.setState({oldpassword : "" , newPassword : "" , confrimNewPassword : ""})
    }
  }

  redirectToHomePage = () => {
    this.props.history.replace('/')
  }
 
  render() {
    const { lang, t } = this.props;

    const { oldpassword, newPassword,show,mismatch ,required2,required1,confrimNewPassword,passwordError,commonError} = this.state;

    const Loader = () => {
      return (
        <div className="d-flex justify-content-center pt-2 pb-2">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    };

    return (
      <>
      <div className={`show ${lang === "ar" ? "pr-4" : "pl-2 "}`}>
          <p className={`ml-2 mb-0 dropdown-toggle cursor-pointer fw-bold text-capitalize`} id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" > 
            {t("Hello")}, {this.props.username}
          </p>

  <div className={`dropdown-menu p-0 my-acc text-uppercase p-2 ${lang === "ar" && "text-right"}`} aria-labelledby="dropdownMenuLink">
      <p className="dropdown-item mb-0 cursor-pointer text-black">
          <Link className="d-block flex-shrink-0" to={{ pathname:"/auth/my-profile"}}>
          <img src={User} alt="lock" className={`${lang === "en" ? "mr-2" : "ml-2"}`} />
            {t('Profile')}
          </Link>   
      </p>
      <p className="d-md-block d-none dropdown-item mb-0 cursor-pointer text-black">
          <Link className="d-block flex-shrink-0" to={{ pathname:"/mywallet/all"}}>
          <img src={Wallet} alt="lock" className={`${lang === "en" ? "mr-2" : "ml-2"}`} />
            {t('My Wallet')}
          </Link>   
      </p>
      <p className="dropdown-item mb-0 cursor-pointer text-black">
          <Link className="d-block flex-shrink-0" to={{ pathname:"/points-history"}}>
          <img src={User} alt="lock" className={`${lang === "en" ? "mr-2" : "ml-2"}`} />
            {t('Points History')}
          </Link>   
      </p>
    <p className="dropdown-item mb-0 cursor-pointer" onClick={()=>this.setState({show:!this.state.show})}>
    <img src={Padlock} alt="lock" className={`${lang === "en" ? "mr-2" : "ml-2"}`} />
      {t("Reset Password")}</p>
    <p className="dropdown-item mb-0 cursor-pointer" onClick={() => this.props.logout(this.redirectToHomePage)}>
      <img src={Shutdown} alt="lock" className={`${lang === "en" ? "mr-2" : "ml-2"}`} />
      {t("Logout")}</p>
  </div>
</div>
      
        <ModalWrapper show={show}>
          <div className="bg-white mx-auto LoginModel">
            <div
              className={`position-relative d-md-block d-none cross1-reset`}
              onClick={this.handleCross}>
                <img src={Cancel} alt="cancel" className="img-fluid cancel" />
                         </div>
            <div
              className={`position-relative d-md-none d-block ${
                lang === "ar" ? "cross4" : "cross2"
              } `}
              onClick={this.handleCross}>
                <img src={Cancel} alt="cancel" className="img-fluid cancel" />
                         </div>
            <div className={`text-center ${this.props.passwordChanged && "reset-password"}`}>  
            {this.props.passwordChanged === "Password updated successfully!" ? (
                <PasswordChanged />
              ) : this.props.passwordChanged === "You need to login to continue." ? (
                <TokenInvalid />
                ) : (
                <Reset />
              )}
              <h1
                className={`muted-color fw-bolder ${this.props.passwordChanged ? "mb-4" : "mb-1 mt-2"}`}
                style={{ fontSize: "18px" }}
              >{this.props.passwordChanged === "Password updated successfully!"
                  ? t("Password Reset Successfully")
                  : this.props.passwordChanged === "Current Password did not match"
                  ? t("Passwords do not match")
                  : this.props.passwordChanged === "You need to login to continue."
                  ? t("Invalid token")
                  : t("Reset Your password")}
              </h1>
            </div>
            {
              !this.props.passwordChanged && 
            <div className="d-flex flex-column LoginIcon">
                <div className="my-1 position-relative">
                  <img
                    src={Password}
                    alt="user"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="password"
                    name="password"
                    value={oldpassword}
                    placeholder={t("Current password")}
                    onChange={(e) =>
                      this.setState({ oldpassword: e.target.value })
                    }
                  />
                  {
                    required1 && <p className="text-danger mb-0">{t("Required")}</p>
                  }
                </div>
                <div className="my-2 position-relative">
                  <img
                    src={Password}
                    alt="password"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="password"
                    name="Confirmpassword"
                    value={newPassword}
                    placeholder={t("New Password")}
                    onChange={(e) =>
                      this.setState({ newPassword: e.target.value })
                    }
                  />
                  {
                    required2 && <p className="text-danger mb-0">Required</p>
                  }
                </div>
                <div className="my-2 position-relative">
                  <img
                    src={Password}
                    alt="password"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="password"
                    name="Confirmpassword"
                    value={confrimNewPassword}
                    placeholder={t("Confirm new password")}
                    onChange={(e) =>
                      this.setState({ confrimNewPassword: e.target.value })
                    }
                  />
                  {
                    required2 && <p className="text-danger mb-0">Required</p>
                  }
                  {
                    commonError ? <p className="text-danger start-text mb-1">{t("Please fill all the fields")}</p> : 
                    passwordError === "invalid" ? <p className="text-danger start-text">{t("Password must contain at least one (upper case and lower case letter, digit, special character) and min 8 characters")}</p> :
                    mismatch ? <p className="text-danger mb-0">{t("Old password cannot be new password")}</p> :
                    passwordError === "mismatch" ? <p className='text-danger'>{t("Password Mismatch")}</p> : null
                  }
                </div>
              </div>
            }
              {
                !this.props.passwordChanged && 
              <div className="text-center mb-2">
              <button
                className="btn btn-sky text-light w-100 "
                disabled={this.props.loading}
                onClick={this.handlePassword}
              >
                {this.props.loading ? <Loader /> : t("Reset Password")}
              </button>
              <br />
            </div>
              }
          </div>
        </ModalWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state?.ResetReducer?.loading,
    passwordChanged: state?.ResetReducer?.msg,
    show:state?.ResetReducer?.show
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PassRest: (oldpass, newpass, comfirmPass, token) => dispatch(PassRest(oldpass, newpass, comfirmPass, token)),
    logout : (cb) => dispatch(logout(cb)),
    ResetClear : () => dispatch(ResetClear())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ResetPassword)))


