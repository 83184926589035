import React, { Component } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { ReactComponent as ArrowRight } from "../../assests/img/TravelShop/arrow-right.svg";
import { ReactComponent as Briefcase } from "../../assests/img/TravelShop/briefcase.svg";
import arrowup from "../../assests/img/TravelShop/arrow-up.svg";
import moment from "moment";
import { withTranslation } from "react-i18next";

class OutBoundFlight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: {
                first: true,
                second: true,
                third: true,
                fourth: true,
            },
        };
    }
    toggleHandler = (type) => {
        this.setState({
            toggle: { ...this.state.toggle, [type]: !this.state.toggle[type] },
        });
    };

    DesktopView = () => {
        const { data, segments, t} = this.props;

        if (data) {
            const flightData = []
            segments.map((segmentsData, index) => {
                let startToEnd = {}
                const filteredFlight = data.segments.filter(segment => index + 1 === segment.segment_indicator)
                // data.segments.map((segment, i) => {
                //   if (segmentsData.origin === segment.origin.code) {
                //     startToEnd = { ...startToEnd, takeOff: segment, stop: i }
                //   }
                //   if (segmentsData.destination === segment.destination.code) {
                //     startToEnd = { ...startToEnd, land: segment, stop: i - startToEnd.stop }
                //   }
                // })
                startToEnd['takeOff'] = filteredFlight[0]
                if (filteredFlight.length > 1) {
                    startToEnd['land'] = filteredFlight[filteredFlight.length - 1]
                } else {
                    startToEnd['land'] = filteredFlight[0]
                }
                startToEnd['stop'] = filteredFlight.length - 1
                flightData.push(startToEnd)
            })

            return flightData?.map((flightDetail, i) => {
                const duration = moment.utc(moment.duration(moment(flightDetail?.land?.arrival_time).diff(moment(flightDetail?.takeOff?.departure_time))).as('milliseconds')).format('HH:mm')
                return i ? null :
                    <div className="flight-routes">
                        <div className="flight-routes-info">
                            <div className="airline-name d-none d-md-block">
                                <div className="d-inline-block text-center">
                                    <img loading='lazy' decoding='async' src={flightDetail?.takeOff?.airline_image} alt="" width={47} />
                                </div>
                            </div>
                            <div className="airline-time">
                                {flightDetail?.takeOff?.cabin_baggage === 'Included' ? <span className="color-blue mb-1 d-md-block d-none"><Briefcase /> <span className="align-middle">Incl.</span></span> : null}
                                <h6>{moment(flightDetail?.takeOff?.departure_time).format('HH:mm')}</h6>
                                <p>{flightDetail?.takeOff?.origin?.code}</p>
                            </div>
                            <div className="airline-direct text-center">
                                <div className="airline-direct-inner">
                                    <span className="text-gray-100">{flightDetail?.stop ? `${flightDetail.stop} ${t('stop')}` : t('Nonstop')}</span>
                                    <div className="direct"></div>
                                    <p className="text-gray-100"> {moment(duration, 'HH:mm').format('HH')}{t("h")} {moment(duration, 'HH:mm').format('mm')}{t("m")}  </p>
                                </div>
                            </div>
                            <div className="airline-time pl-lg-5">
                                <h5>{moment(flightDetail?.land?.arrival_time).format('HH:mm')}</h5>
                                <p>{flightDetail?.land?.destination?.code}</p>
                            </div>
                            <div className="airline-points text-right">
                                <h4>{data?.fare_local?.price_in_points?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t("Points")}</h4>
                            </div>
                        </div>
                        <div className="px-md-4 pb-4">
                            <Row>
                                <Col xl={7} md={6} xs={6} className="ml-auto">
                                    <div className="more-info w-100">
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            className="p-0 border-0"
                                            eventKey="1"
                                            onClick={() => {
                                                this.props.handleViewFlightDetail(data.resultID);
                                            }}
                                        >
                                            <img loading='lazy' decoding='async'
                                                src={arrowup}
                                                alt="arrow"
                                                className={
                                                    this.props.toggle[data?.resultID]
                                                        ? "toggleup"
                                                        : "toggledown"
                                                }
                                            />
                                            {this.props.toggle[data?.resultID] ? t("See less") : t("See more")}
                                        </Accordion.Toggle>
                                    </div>
                                </Col>
                                <Col xl={3} md={4} xs={6}>
                                    <Button block className="py-2 btn-sky border-white" onClick={() => this.props.handleViewDetail(data.resultID)} >{t("Choose Flight")} <ArrowRight width={8} className="ml-2" /></Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
            }
            )
        }
        return (
            <Card className="w-100 pt-3 px-3 pb-3 rounded-0 mb-4">
                <div className="flight-routes">
                    <div className="flight-routes-info">
                        <div className="airline-name">
                            <div className="d-inline-block text-center">
                                <img loading='lazy' decoding='async' src={this.props.flightImage} alt="" width={47} />
                            </div>
                        </div>
                        <div className="airline-time">
                            <span className="color-blue mb-1 d-block"><Briefcase /> <span className="align-middle">{t("Incl.")}</span></span>
                            <h6>{this.props.takeOffTime}</h6>
                            <p>{this.props.takeOffLocation}</p>
                        </div>
                        <div className="airline-direct text-center">
                            <div className="airline-direct-inner">
                                <span className="text-gray-100">{this.props.stops}</span>
                                <div className="direct"></div>
                                <p className="text-gray-100">{this.props.totalTime}</p>
                            </div>
                        </div>
                        <div className="airline-time pl-lg-5">
                            <h5>{this.props.landingTime}</h5>
                            <p>{this.props.landingLocation}</p>
                        </div>
                        <div className="airline-points text-right">
                            <h4>{this.props.points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t("Points")}</h4>
                        </div>
                    </div>
                    <div className="px-4 pb-4">
                        <Row>
                            <Col xl={7} md={6} className="ml-auto">
                                <div className="more-info w-100">
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        className="p-0 border-0"
                                        eventKey="1"
                                        onClick={() => {
                                            this.toggleHandler("second");
                                        }}
                                    >
                                        <img loading='lazy' decoding='async'
                                            src={arrowup}
                                            alt="arrow"
                                            className={
                                                this.props.toggle?.second
                                                    ? "toggleup"
                                                    : "toggledown"
                                            }
                                        />
                                        {this.props.toggle?.second ? t("See less") : t("See more")}
                                    </Accordion.Toggle>
                                </div>
                            </Col>
                            <Col xl={3} md={4}>
                                <Button block className="py-2">Choose Flight <ArrowRight width={8} className="ml-2" /></Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Card>
        )
    }


    MobileView = () => {
        const { data, segments, t } = this.props;
        if (data) {
            const flightData = []
            segments.map((segmentsData, index) => {
                let startToEnd = {}
                const filteredFlight = data.segments.filter(segment => index + 1 === segment.segment_indicator)
                // data.segments.map((segment, i) => {
                //   if (segmentsData.origin === segment.origin.code) {
                //     startToEnd = { ...startToEnd, takeOff: segment, stop: i }
                //   }
                //   if (segmentsData.destination === segment.destination.code) {
                //     startToEnd = { ...startToEnd, land: segment, stop: i - startToEnd.stop }
                //   }
                // })
                startToEnd['takeOff'] = filteredFlight[0]
                if (filteredFlight.length > 1) {
                    startToEnd['land'] = filteredFlight[filteredFlight.length - 1]
                } else {
                    startToEnd['land'] = filteredFlight[0]
                }
                startToEnd['stop'] = filteredFlight.length - 1
                flightData.push(startToEnd)
            })

            return (
                <Card className="w-100 pt-3 px-3 pb-3 rounded-0 mb-4">
                    <div className="flight-routes">
                        <div className="flight-routes-info">
                            <div className="airline-time">
                                <h6>{this.props.takeOffTime}</h6>
                                <p>{this.props.takeOffLocation}</p>
                            </div>
                            <div className="airline-direct text-center">
                                <div className="airline-direct-inner">
                                    <div className="direct"></div>
                                    <p className="text-gray-100">{this.props.totalTime} {this.props.stops}</p>
                                </div>
                            </div>
                            <div className="airline-time pl-lg-5">
                                <h5>{this.props.landingTime}</h5>
                                <p>{this.props.landingLocation}</p>
                            </div>
                        </div>
                        <div className="airline-points text-right">
                            <h4>{t("Points")} <span className="color-blue">{this.props.points}</span></h4>
                            <p className="color-blue">{t("Missing Points")} 8,490 (365 AED)</p>
                        </div>
                        <Row className="align-items-center border-top pt-2">
                            <Col xs={7}>
                                <div className="airline-name">
                                    <div className="d-flex align-items-center">
                                        <img loading='lazy' decoding='async' className="mr-2" src={this.props.flightImage} alt="" width={25} />
                                        <span className="text-gray-100">{this.props.flightName}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={7} md={6} className="ml-auto">
                                <div className="more-info w-100">
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        className="p-0 border-0"
                                        eventKey="1"
                                        onClick={() => {
                                            this.props.handleViewFlightDetail(data.resultID);
                                        }}
                                    >
                                        <img loading='lazy' decoding='async'
                                            src={arrowup}
                                            alt="arrow"
                                            className={
                                                this.props.toggle[data?.resultID]
                                                    ? "toggleup"
                                                    : "toggledown"
                                            }
                                        />
                                        {this.props.toggle[data?.resultID] ? "Hide Details" : "View Details"}
                                    </Accordion.Toggle>
                                </div>
                            </Col>
                            {/* <Col xs={5}>
                            <Button variant="link" className="py-2 float-right color-green">View Details</Button>
                        </Col> */}
                        </Row>
                    </div>
                </Card>
            )
        }
        return <div />
    }

    render() {
        return (
            <>
                <div>
                    {/* <div className="d-md-none d-block">
                        <this.MobileView />
                    </div>
                    <div className="d-md-block d-none"> */}
                    <this.DesktopView />
                    {/* </div> */}
                </div>
            </>
        );
    }
}
export default (withTranslation()(OutBoundFlight));
