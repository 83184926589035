import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import sad from "../../../assests/img/sadd.svg";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { fetchPointBalance } from '../../../Redux/point/pointActions';
import { connect } from 'react-redux';

class FailMessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:true,
            ShowheaderText : this.props.ShowheaderText === false ? this.props.ShowheaderText : true
         }
    }
    setModalView=()=>{
        this.setState({
            show:false
        })
    }
    // Disabling Points Balance API
    // componentDidUpdate(){
    //     if(this.props.show){
    //         this.props.fetchPointBalance()
    //     }
    // }
    render() { 
        const {t}=this.props
        return ( 
            <ModalWrapper show={this.state.show && this.props.show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'35rem'}}>
                    <img 
                        className="p-md-0 p-3"
                        height="100"
                        src={sad} alt="" /> 
                    <h3 className='text-muted m-0 py-md-3 py-0'>
                    {this.state.ShowheaderText && t("Payment Failed")}
                    </h3> 
                    <p>{t(this.props.Message)}</p>
                    <Link 
                        to={{
                            pathname:"/"
                        }}
                        className="btn btn-secondary mt-3 mx-2"
                        onClick={()=>{this.setModalView()}}>
                        <span className="fw-bold primary-color">{t("Close")}</span>
                    </Link>
                </div>
            </ModalWrapper>
         );
    }
}
const mapDispatchToProps = dispatch => {
    return {    
        fetchPointBalance : () => dispatch(fetchPointBalance())
    }
}

export default connect(null,mapDispatchToProps) (withTranslation()  (FailMessageModal));