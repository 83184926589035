import { fetchAuthTokenInvalid, fetchAuthTokenSuccess } from "Redux/auth/authActions";
import { setCountryHandler, setLanguagesHandler } from "Redux/country/countriesAction";
import store from "Redux/store";
import axios from "axios";
import { useEffect } from "react";
import { REACT_APP_baseUrl } from "utils/app-constants";
import cookie from "react-cookies"
import {useHistory } from "react-router-dom"

const Loader = () => {

  const history = useHistory()

  const checkToken = () =>{
      const token = window.location.search.split("?code=")
      if (token?.[1]){
        validateToken(token?.[1])
      }
  }

  const validateToken = (code) =>{
    axios.post(`${REACT_APP_baseUrl}/login`, {
    "client_id": localStorage.getItem("client_id"),
    "auth_code": code
    }).then(response =>{
      if (response.data?.data?.user.access_token){
        const userDetails = response.data?.data?.user
        let selectedCountry = {
            countryName: userDetails?.country_name,
            countryId:  userDetails?.country_id,
            currencyId: userDetails?.currency_id,
            countryCode: userDetails?.currency_code,
            currencyCode: userDetails?.country_code,
        };
        let Languages
        if(store.getState()?.countries?.countries?.length > 0){
            Languages = store.getState()?.countries?.countries?.filter(ite => ite?.currencies?.[0]?.id == selectedCountry.currencyId)
        }
        let payload = {
            selectedCountry,
            languages: Languages?.[0]?.languages,
        }
        localStorage.setItem("countryID", selectedCountry?.currencyId);
        localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
        store.dispatch(setLanguagesHandler(payload))
        store.dispatch(setCountryHandler(selectedCountry?.currencyId))
        store.dispatch(fetchAuthTokenSuccess(response.data?.data?.user.access_token))
        localStorage.setItem("userToken", response.data?.data?.user.access_token);
        cookie.save("firstname", response.data?.data?.user.first_name)
        const RedeemToken = localStorage.getItem('redeemToken')
        if(RedeemToken) return history.push(`/redeem-card?token=${RedeemToken}`);
        history.push("/");
    }
    else{
      let errorMsg = response.data?.message || "Session Timeout"
      store.dispatch(fetchAuthTokenInvalid(errorMsg))
      history.push("/");
    }
  }).catch(err =>{
    history.push("/");
    })
  }

  useEffect(()=>{
    checkToken()
  },[])

    return (
      <div className="d-flex justify-content-center pt-2 pb-2">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

export default Loader