import React, { Component } from 'react';
// import TermsConditionModal from '../shared/Modals/TermsConditionModal';
import  cardImg from '../../assests/img/card.svg'
import { connect } from 'react-redux';
// import {  pointsToCurrancy } from '../../utils/pointsConversion';
import MasterCard from "../../assests/img/MasterCard.png";
import Visa from "../../assests/img/Visa.png";
import { withTranslation } from 'react-i18next';
class OrderSummaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            checkoutBtn:true,
         }
    }
    setCheckoutBtn(val){
        this.setState(old=>({
            checkoutBtn:!old.checkoutBtn
        }))
    }
    // numberWithCommas = (x) => {
    //     let num = +x;
    //     let y = num.toFixed(0)
    //     return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
    numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
    numberWithCom = (x) => {
        let num = +x;
        let y = num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    render() { 
        const {checkoutBtn}=this.state
        const {t,convertedCurrency,selectedCurrency}=this.props
        // const orderDetails ={
        //     ...this.props.order,
        //     payable_amount:this.props.order?.amount-pointsToCurrancy(this.props.order?.hassad_points)
        // }
     
        const desktopView=(
            <div className="d-md-block d-none">
                <div 
                    className="card mb-4 rounded-0"
                    style={{width:'18rem'}}>
                    <div className="card-body">
                    <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5  headings">{t("Order Summary")}:</div>
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                            {
                                this.props.lang !== 'ar'?
                                <h5 className="text-dark fw-bold m-0">{this.numberWithCommas(convertedCurrency)} <small>{t(selectedCurrency)}</small></h5>
                                :
                                <h5 className="text-dark fw-bold m-0"><small>{t(convertedCurrency)}</small> {this.numberWithCommas(selectedCurrency)} </h5>

                            }
                        </div>
                        {/* points Section disabled  */}
                        {/* <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {
                                this.props.lang=='en'?
                                <h5 className="text-danger fw-bold m-0">{this.numberWithCommas(this.props.pointBalance)} <small>{t("Points")}</small></h5>
                                :
                                <h5 className="text-danger fw-bold m-0"><small>{t("Points")}</small> {this.numberWithCommas(this.props.pointBalance)} </h5>

                            }
                        </div> */}
                        {/* {orderDetails.payable_amount && !this.props.cardPayment && 

                        <>
                        points disabled 
                            <div className="d-flex flex-row justify-content-between py-2">
                                <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                                {
                                   this.props.lang=='en'?
                                   <h5 className="text-danger fw-bold m-0">{this.numberWithCommas(currancyToPoints(orderDetails.payable_amount))} <small>{t('Points')}</small></h5>
                                   :
                                   <h5 className="text-danger fw-bold m-0"> <small>{t('Points')}</small> {this.numberWithCommas(currancyToPoints(orderDetails.payable_amount))} </h5>
                               }
                            </div>
                            <div className="d-flex flex-row py-3">
                                <div className="p-0 col-2">
                                    <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                                </div>
                                <div className="px-1 col-10">
                                 <h5> {this.numberWithCom(convertedCurrency)} {selectedCurrency}</h5>
                                    <small className="text-muted fw-bold">
                                    {t("to be paid by Credit Card/MADA")}
                                    </small><br/>
                                    <img src={Visa} alt='Visa'/>
                                    <img src={MasterCard} alt='MasterCard'/>
                                    <img src={Mada} alt='Mada'/>
                                    
                                </div>
                                
                            </div>
                        </>
                        } */}

                        {
                            this.props.cardPayment && <div className="d-flex flex-row py-3">
                            <div className="p-0 col-2">
                                <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                            </div>
                            <div className="px-1 col-10">
                                <h5 className="color-blue fw-bold m-0">{this.numberWithCommas
                                (Number(convertedCurrency))} <small className=' fs-6'>{selectedCurrency}</small> </h5>
                                <small className="text-muted fw-bold">
                                {t("to be paid by Credit Card")}
                                </small><br/>
                                <img src={Visa} alt='Visa'/>
                                    <img src={MasterCard} alt='MasterCard'/>
                                    
                                
                            </div>
                            
                        </div>
                        }
                        <hr className="hr-line"/>
                        <div className="d-flex flex-row justify-content-between pt-1 pb-3">
                            <h5 className="text-dark fw-bold m-0">{t("Total")}</h5>
                            {
                                this.props.lang!=='ar'?
                                <h5 className="text-dark fw-bold m-0">{this.numberWithCommas(convertedCurrency)} <small>{t(selectedCurrency)}</small></h5>
                                :
                                <h5 className="text-dark fw-bold m-0"><small>{t(selectedCurrency)}</small> {this.numberWithCommas(convertedCurrency)} </h5>

                            }
                        </div>
                        <div>
                        <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" >{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div>
                            {/* <TermsConditionModal lang={this.props.lang} show={false}/> */}
                        </div>
                        <br/>
                        <button className="btn btn-block primary-btn rounded-0 text-center" disabled={checkoutBtn} onClick={()=>{this.props.onSubmit()}} >{t("Buy Now")}</button>
                    </div>
                </div>
            </div>
           
        )
        const mobileView=(
            <div className="d-md-none d-block">
                <div className="position-absolute bg-color-blue w-100 px-4 py-1" style={{top:'9rem',left:'0'}}>
                    <h4 className="d-inline">{this.numberWithCommas(Number(convertedCurrency))} {selectedCurrency}</h4>
                    {/* <span> pts</span> */}
                    <div>{t("to be paid by Credit Card")}</div>
                </div>
                <div className="position-fixed  w-100 bg-light mb-3" style={{bottom:'8.4%',left:'0'}}>
                    <button 
                        onClick={()=>{this.props.onSubmit()}}
                        className="btn primary-btn w-100 rounded-0 p-2">
                        {t("Proceed")}
                    </button>
                </div>
            </div>
        )
        return ( 
            <>
                {desktopView}
                {mobileView}
                <div className="d-md-none d-block m-4 p-2">
                </div>
            </>
         );
    }
}
const mapStateToProps = state => {
    return {
        orderDetails:state.order.orderDetails,
        pointBalance:state.point.pointBalance,
        currancyCode:state.countries.convertionRate.currency_code,
    }
}
 
export default connect(mapStateToProps) (withTranslation()(OrderSummaryCard));