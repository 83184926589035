import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import '../../assests/css/App.css';
import { withTranslation } from 'react-i18next';

import eMagazinesIcon from '../../assests/img/main-navigation-header/icon-nav-e-magazines.png'
import giftCardsIcon from '../../assests/img/main-navigation-header/icon-nav-gift-cards.png'
import hassadPartnersIcon from '../../assests/img/main-navigation-header/icon-nav-hassad-partners.png'
import mobileTopupIcon from '../../assests/img/main-navigation-header/icon-nav-mobile-topup.png'
import offersIcon from '../../assests/img/main-navigation-header/icon-nav-offers.png'
// import shopsIcon from '../../assests/img/main-navigation-header/icon-nav-shop.png'
import travelIcon from '../../assests/img/main-navigation-header/icon-nav-travel.png'
import { connect } from 'react-redux';
import { fetchCategoriesList } from '../../Redux/categories/categoriesAction';
import {ReactComponent as LeftArrow} from '../../assests/img/header/arrow-left-circle.svg'
import {ReactComponent as RightArrow} from '../../assests/img/header/arrow-right-circle.svg'

class CategorySection extends Component{
    constructor(props){
        super(props)
        this.listRef = React.createRef();
        this.state={
            forgetPass:false,
            searchList: '',
            findCategory: this.props.defaultCategory ?? 'All Categories',
            catName: [],
            id:''
        }
    }
    scrollLeft = () => {
        if (this.listRef.current) {
          this.listRef.current.scrollLeft -= 250; // Adjust the scroll distance as needed
        }
      };
      scrollRight = () => {
        if (this.listRef.current) {
          this.listRef.current.scrollLeft += 250; // Adjust the scroll distance as needed
        }
      };  
    navigationItems=[
        // {name:'SHOP',id:'shop',link:'',imgSrc:shopsIcon},
        // {name:'TRAVEL',id:'travels',link:'',imgSrc:travelIcon},
        // {name:'HASSAD PARTNERS',id:'hassadPartners',link:'/hassadpartners',imgSrc:hassadPartnersIcon},

        {name:'DIGITAL GIFT CARDS',id:'giftCards',key:'Gift Cards',link:'/giftcards',imgSrc:giftCardsIcon},
        {name:'PHYSICAL GIFT CARDS',id:'PHYSICALgiftCards',key:'Physical Giftcards',link:'/physicalgiftcards',imgSrc:giftCardsIcon},
        {name:'OFFERS',id:'offers',link:'/offers',key:'RewardsBy Offers',imgSrc:offersIcon},
        {name:'E-MAGAZINES',id:'eMagazines',key:'E-Magazines',link:'/e-magazines',imgSrc:eMagazinesIcon},
        {name:'MERCHANDISE',id:'hassadGifts',key:'RewardsBy Gifts',link:'/gifts',imgSrc:hassadPartnersIcon},
        {name:'EXPERIENCES',id:'experiences',key:'Experiences',link:'/experiences',imgSrc:''},
        // {name:'MOBILE TOPUP',id:'mobileTopUp',key:'Mobile TopUp',link:'/mobileTopUp',imgSrc:mobileTopupIcon},
        // {name:'EXPERIENCES',id:'experiences',key:'Experiences',link:'/experiences',imgSrc:''},
        {name:'MOBILE TOPUP',id:'mobileTopUp',key:'Mobile TopUp',link:'/mobileTopUp',imgSrc:mobileTopupIcon},
        {name:'POINTS EXCHANGE',id:'pointsExchange',key:'Points Exchange',link:'/points-exchange',imgSrc:''},
        {name:'SOFTWARES & GAMES',id:'games',key:"Software&Games",link:'/softwares&games',imgSrc:''},
        // {name:'SOFTWARES',id:'softwares',key:'Software',link:'/softwares',imgSrc:''},
        // {name:'GAMES',id:'games',key:'Games',link:'/games',imgSrc:''},
        {name:'TRAVEL SHOP',id:'TRAVELSHOP',key:"Travel shop",link:'/flights',imgSrc:travelIcon},
    ]
    componentDidMount(){
        // this.props.fetchCategoriesList()
        this.changeActiveClass()
        if (this.props.location.pathname.includes("forgotPassword") || this.props.location.pathname.includes("auth/signup")) {
            this.setState({ forgetPass: true });
          }
        }
        changeCategory = (e, value, id) => {
            this.setState(()=>({
                findCategory: value,
                id: id
            }))

            if(document.getElementsByClassName("active-cat")?.[0]){
                this.removeAllActiveClass()
            }
        }
        
        changeActiveClass(){
            let url = this.props.location.pathname
            if(url.startsWith('/selectedgiftcard') || url.startsWith('/giftcards')){
                this.addActiveClass(document.getElementById('giftCards'))
            }
            else if(url.startsWith('/offer')){
                this.addActiveClass(document.getElementById('offers'))
            }
            else if(url.startsWith('/experience')){
                this.addActiveClass(document.getElementById('experiences'))
            }
            else if(url.startsWith('/mobileTopUp')){
                this.addActiveClass(document.getElementById('mobileTopUp'))
            }
        }
        
        componentDidUpdate(prevProps){
            if(!this.props.location?.pathname?.includes('/category') && this.state.findCategory !== 'All Categories'){
                this.setState({findCategory : 'All Categories'})
            }

            if(this.props.location.pathname === '/' || this.props.location.pathname === '/auth/my-profile' || 
            this.props.location?.pathname?.includes("/mywallet") || 
            this.props.location.pathname === '/points-history'){
                this.removeAllActiveClass()
            }
            else if(prevProps.location?.pathname !== this.props.location?.pathname){
                this.changeActiveClass()
            }

        }

        addActiveClass(elem) {
            let div = document.getElementsByClassName("active-cat")[0];
            div?.classList?.remove("active-cat");
            elem?.classList?.add("active-cat");
          }
        removeAllActiveClass(){
            let div = document.getElementsByClassName("active-cat")[0];
            div?.classList?.remove("active-cat");
        }
        render(){ 
        const {t,modulesData} = this.props;
        const {forgetPass} = this.state
        const deskopView= 
        <div className="d-md-block d-none container-fluidNext shadow-sm px-5 py-1">
           <div className="d-flex">
               {/* <div className="col-3 p-0 dropdown m-0">
                   <b className="btn btn-outline px-0 dropdown-toggle btncategory btnTxt text-uppercase" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       {t(this.state.findCategory)}
                   </b>
                   <div className="dropdown-menu pre-scrollable custom-scrollbar" aria-labelledby="dropdownMenuButton">
                       {this.props.category?.length > 0 &&  this.props.category.map(category => (
                           <Link
                               className="dropdown-item " 
                               key={category.id} 
                               to={{
                                   pathname:`/category/${category.name}`,
                                   state: category.id
                               }}
                               onClick={(e) => this.changeCategory(e, category.name, category.id)}>{t(category.name)} 
                           </Link>
                       ))}
                   </div>
               </div> */}
                    <div className="horizontal-scroll">
                        <ul ref={this.listRef} className="list flex-grow-1 p-0 m-0 mx-1 my-2 ">
                        
                            {this.navigationItems.map(item => (
                                modulesData[item.key] &&
                                <Link
                                    key={item.id}
                                    id={item.id}
                                    className="w-100 px-2 word d-inline text-nowrap category-link fw-bold"
                                    onClick={(e) => this.addActiveClass(e.target)}
                                    to={{
                                        pathname: item.link,
                                    }}>{t(item.name)}
                                </Link>
                            ))}
                        </ul>
                        <div
                            onClick={this.scrollLeft}
                            className={`scroll-button left ${this.listRef.current && this.listRef.current.scrollWidth > this.listRef.current.clientWidth
                                ? '' // Show the button only when overflow exists
                                : 'hidden' // Apply a 'hidden' class to hide the button when no overflow
                                }`}>
                            <LeftArrow />
                        </div>
                        <div
                            onClick={this.scrollRight}
                            className={`scroll-button right ${this.listRef.current && this.listRef.current.scrollWidth > this.listRef.current.clientWidth
                                    ? '' // Show the button only when overflow exists
                                    : 'hidden' // Apply a 'hidden' class to hide the button when no overflow
                                }`}
                        >
                            <RightArrow />
                        </div>
                    </div>
           </div>
       </div>
       const mobileView = 
       <div className='d-md-none d-none w-100 overflow-auto bg-light mb-2'>
           <ul className="p-0 d-flex border m-0">
                   {this.navigationItems.map(item=>(
                           <Link 
                                key={item.link}
                               className="text-capitalize fw-bold bg-light p-2 d-flex text-nowrap border-right border-bottom" 
                               to={{
                                   pathname:item.link,
                           }}>
                               <img src={item.imgSrc} alt="linkImg" className="px-2"/>
                               
                               <span>{t(item.name)}</span>
                           </Link>
                   ))}
               </ul>
       </div>
            return(
                <>
                {
                    !forgetPass && <>
                        {deskopView}
                        {mobileView}
                    </>
                }
                </>
            )
        }

}
const mapStateToProps = state => {
    return {
       category:state.categories.allCategories,
       modulesData:state.categories.moduleInfo
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchCategoriesList: () => dispatch(fetchCategoriesList())
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)((withTranslation()(CategorySection)));
