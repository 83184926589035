import React, { Component } from "react";
import PIA from "../../assests/img/TravelShop/pia.png";
import Emirates from "../../assests/img/TravelShop/emirates.svg";
import { ReactComponent as PlaneDown } from "../../assests/img/TravelShop/plane-down.svg";
import { ReactComponent as Seat } from "../../assests/img/TravelShop/seat.svg";
import { ReactComponent as Baggage } from "../../assests/img/TravelShop/baggage.svg";
import { ReactComponent as PlaneUp } from "../../assests/img/TravelShop/plane-up.svg";
import moment from "moment";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import British from "../../assests/img/TravelShop/british.svg";
import arrowup from "../../assests/img/TravelShop/arrow-up.svg";
import { withTranslation } from 'react-i18next';


const dataArray = [
  {
    flightImage: PIA,
    flightNumber: "Flight PK 305",
    takeOfCity: "Lahore",
    takeTime: "18:10  |  Fri, 24 Dec",
    totalTime: "1 hr 45 mins",
    landingCity: "Karachi",
    landingTime: "19:45  |  Fri, 24 Dec",
    flightType: [{ name: "Economy", icon: <Seat /> }, { name: "Baggage (30KG)", icon: <Baggage /> }]
  },
  {
    flightName: "Emirates",
    flightImage: Emirates,
    flightNumber: "Flight EK 603",
    takeOfCity: "Karachi",
    takeTime: "22:30  |  Fri, 24 Dec",
    totalTime: "2 hr 29 mins",
    landingCity: "Dubai",
    landingTime: "23:59  |  Fri, 24 Dec",
    flightType: [{ name: "Economy", icon: <Seat /> }, { name: "Baggage (30KG)", icon: <Baggage /> }]
  },
  {
    flightName: "Emirates",
    flightImage: Emirates,
    flightNumber: "Flight EK 7",
    takeOfCity: "Dubai",
    takeTime: "03:10  | Sat, 25 Dec",
    totalTime: "8 hr 0 mins",
    landingCity: "London Heathrow",
    landingTime: "07:10  |  Sat, 25 Dec",
    flightType: [{ name: "Economy", icon: <Seat /> }, { name: "Baggage (30KG)", icon: <Baggage /> }]
  },
];

const flightType = [{ name: "Economy", icon: <Seat /> }, { name: "Baggage (30KG)", icon: <Baggage /> }]

class StepFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { selectedFlightDetail, searchResults, flightDetails,confirmationPage, t } = this.props

    // const flightData = []
    // searchResults.segments.map((segmentsData, index) => {
    //   let startToEnd = {}
    //   selectedFlightDetail.segments.map((segment, i) => {
    //     if (segmentsData.origin === segment.origin.code) {
    //       startToEnd = { ...startToEnd, takeOff: segment, stop: i }
    //     }
    //     if (segmentsData.destination === segment.destination.code) {
    //       startToEnd = { ...startToEnd, land: segment, stop: i - startToEnd.stop }
    //     }
    //   })
    //   flightData.push(startToEnd)
    // })

    return (
      <>
        <div className="flight-step mt-3">
          {/* <div className="step-flight-wrap">
            {dataArray &&
              dataArray.length > 0 &&
              dataArray.map((data, index) => {
                return (
                  <div className="step-flight" key={index}>
                    <img loading='lazy' decoding='async' className="mb-2" src={data?.flightImage} alt="" />
                    <h5 className="text-gray-100 mb-1">{data?.flightName}</h5>
                    <span className="text-gray">{data?.flightNumber}</span>
                  </div>
                );
              })}
          </div> */}
          {/* <div className="step-flight-info">
            {dataArray &&
              dataArray.length > 0 &&
              dataArray.map((data, index) => {
                return (
                  <div className="step-flight-inner" key={index}>
                    <div className="step-flights">
                      <div className="flights-up">
                        <h4>{data?.takeOfCity}</h4>
                        <p>{data?.takeTime}</p>
                        <span>{data?.totalTime}</span>
                      </div>
                      <div className="flights-down">
                        <h4>{data?.landingCity}</h4>
                        <p>{data?.landingTime}</p>
                      </div>
                    </div>
                    <div className="flight-options">
                      <ul className="p-0 m-0">
                        {
                          data?.flightType && data?.flightType.length > 0 && data?.flightType.map((type, index) => {
                            return <li key={index}>
                              {type?.icon}
                              <span>{type?.name}</span>
                            </li>
                          })
                        }
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div> */}
          <div className="step-flight-wrap">
            {flightDetails &&
              flightDetails?.length > 0 &&
              flightDetails.map((data, index) => {
                return (
                  <div className="step-flight" key={index}>
                    <img loading='lazy' decoding='async' width="100" className="mb-2" src={data?.airline_image} alt="" />
                    <h5 className="text-gray-100 mb-1">{data?.airline_name}</h5>
                    <span className="text-gray">{t("Flight")} {data?.airline_code} {data?.flight_number}</span>
                  </div>
                );
              })}
          </div>
          <div className="step-flight-info">
            {flightDetails &&
              flightDetails?.length > 0 &&
              flightDetails.map((data, index) => {
                const layoverTime = { hr: moment(data?.ground_time, 'hh:mm:ss').format('H'), min: moment(data?.ground_time, 'hh:mm:ss').format('mm') }

                return (
                  <div className="step-flight-inner flex-wrap" key={index}>
                    {data?.ground_time !== '00:00:00' ?
                      <div className="layover-time">{t("Layover")} {layoverTime?.hr > 0 ? `${layoverTime?.hr}${t('h')}` : ''} {layoverTime?.min}{t('m')}</div>
                      :
                      null}
                    <div className="step-flights">
                      <div className="flights-up pt-2">
                        <h4>{t(data?.origin?.city)}</h4>
                        <p>{moment(data?.departure_time).format('HH:mm | ddd, DD MMM')}</p>
                        <span>{moment(data?.duration, 'hh:mm:ss').format('H')} hr {moment(data?.duration, 'hh:mm:ss').format('mm')} mins</span>
                      </div>
                      <div className="flights-down pb-0">
                        <h4>{t(data?.destination?.city)}</h4>
                        <p>{moment(data?.arrival_time).format('HH:mm | ddd, DD MMM')}</p>
                      </div>
                    </div>
                    <div className={`flight-options ${confirmationPage && "w-25"}`}>
                      <ul className="p-0 m-0">
                        <li>
                          <Seat />
                          <span>{t(data?.cabin_class)}</span>
                        </li>
                        <li>
                          <Baggage />
                          <span>{t(data?.included_baggage)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/* {flightDetails?.inBoundFlight.length ?
          <>
            <div className="d-flex pt-4">
              <div className="bounding-icon">
                <PlaneDown />
              </div>
              <div className="bounding">
                <h5>Inbound flight {moment(searchResults?.segments[1]?.departure_date).format("MMM DD")}</h5>
                <span>{flightDetails?.inBoundFlight[0]?.origin?.city} - {flightDetails?.inBoundFlight[flightDetails?.inBoundFlight?.length - 1]?.destination?.city}</span>
              </div>
            </div>
            <div className="flight-routes mt-3">
              <Accordion className="w-100" defaultActiveKey="1">
                <Card className="w-100 rounded-0">
                  <div className="flight-routes-info">
                    <div className="airline-name">
                      <div className="d-inline-block text-center">
                        <img loading='lazy' decoding='async' src={British} alt="" />
                        <h5 className="text-gray-100">
                          British Airways
                        </h5>
                      </div>
                    </div>
                    <div className="airline-time pl-4 pl-xl-0">
                      <h6>12:10</h6>
                      <p>LHR</p>
                    </div>
                    <div className="airline-direct text-center">
                      <div className="airline-direct-inner">
                        <span className="color-blue">Direct</span>
                        <div className="direct"></div>
                        <p>06h 55m</p>
                      </div>
                    </div>
                    <div className="airline-time pl-lg-5 pl-4">
                      <h5>07:05</h5>
                      <p>DXB</p>
                    </div>
                    <div className="more-info text-center">
                      <Accordion.Toggle
                        as={Card.Header}
                        className="p-0 border-0"
                        eventKey="2"
                        onClick={() => {
                          this.toggleHandler("third");
                        }}
                      >
                        <img loading='lazy' decoding='async'
                          src={arrowup}
                          alt="arrow"
                          className={
                            this.state.toggle?.third
                              ? "toggleup"
                              : "toggledown"
                          }
                        />
                        {this.state.toggle?.third
                          ? "See less"
                          : "See more"}
                      </Accordion.Toggle>
                    </div>
                  </div>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="border-top">
                      <div className="flight-info">
                        <div className="d-flex align-items-center flex-fill">
                          <div className="flight-timeline"></div>
                          <div className="bonding-info flex-fill">
                            <PlaneUp />
                            <div className="pl-4 flex-fill">
                              <div className="bonding-time">
                                <h5 className="mb-0 fw-normal">
                                  12:10
                                </h5>
                                <p className="mb-0">
                                  Dubai Dubai International (DXB)
                                </p>
                              </div>
                              <div className="d-flex align-items-center my-4 pl-5 ml-5">
                                <img loading='lazy' decoding='async'
                                  width={20}
                                  src={Emirates}
                                  alt=""
                                />
                                <h5 className="mb-0 ml-2 text-gray-100 fw-normal">
                                  Emirates
                                </h5>
                              </div>
                              <div className="bonding-time">
                                <h5 className="mb-0 fw-normal">
                                  12:10
                                </h5>
                                <p className="mb-0">
                                  Dubai Dubai International (DXB)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flight-options">
                          <ul className="p-0 m-0">
                            {this.props.flightOptions.map((item, i) => (
                              <li key={i}>
                                {item.icon}
                                <span>{item.name}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </>
          : null} */}
      </>
    );
  }
}
export default (withTranslation()(StepFlight));
