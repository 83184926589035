import {
    FETCH_BRAND_FAILURE, FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS,
    GET_BRAND_FAILURE, GET_BRAND_REQUEST, GET_BRAND_SUCCESS, CATEGORY_BRANDS
} from "./brandTypes"

const initialState = {
    brand: {},
    loading: true,
    error: '',
    errorMsg: '',
    brandData: [],
    categories: [],
    prodNotFound: false,
    brandDataTotalRecord: 0,
    pageNumber:1,
    categoryBrands:[]
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_BRAND_REQUEST:
            return {
                ...state,
                loading: true,
                prodNotFound: false,
                brand: {},
                errorMsg: '',
            }
        case FETCH_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                brand: action.payload,
                prodNotFound: false,
                errorMsg: '',
            }
        case FETCH_BRAND_FAILURE:
            return {
                ...state,
                loading: false,
                prodNotFound: true,
                errorMsg: action.payload
            }
        case GET_BRAND_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            }
        case GET_BRAND_SUCCESS:
            if(action.payload.isReset){
                return {
                    ...state,
                    loading: false,
                    brandData: action?.payload?.brandData || [],
                    categories: action?.payload?.CATEGORIES,
                    brandDataTotalRecord: action.payload?.total,
                    pageNumber: action.payload?.pageNumber || 1
                }
            }
            return {
                ...state,
                loading: false,
                brandData: [
                    ...state?.brandData || [],
                    ...action?.payload?.brandData
                ],
                categories: action?.payload?.CATEGORIES,
                brandDataTotalRecord: action.payload?.total,
                pageNumber: action.payload?.pageNumber || 1
            }


        case GET_BRAND_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CATEGORY_BRANDS:
            return {
                ...state,
                categoryBrands:action?.payload
            }
        default: return state
    }
}

export default reducer;