import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route,
  } from "react-router-dom";
import HomePage from './homePage/HomePage';
// import GiftcardList from './GiftCardPage/GiftcardList';
import PartnersList from './HassadPartnersPage/PartnersList';
import '../assests/css/App.css';
import HassadPartnersSection from './HassadPartnersPage/HassadPartnersSection';
import CategoryPage from './CategoryPage/CategoryPage';
import TopHeader from './shared/TopHeader';
import MyWallet from './MyWallet/myWallet';
import EMagazinesPage from './E-Magazines/E-MagazinesPage';
import EMagazinePage from './E-Magazines/E-MagazinePage';
import PointExchange from './PointExchange/PointExchangePage';
import PointExchangeCardPage from './PointExchange/PointExchangeCardPage';
import PointsExchangeFormReviewPage from './PointExchange/PointExchangeFormReviewPage';
import Footer from './shared/Footer';
import GiftCardsPage from './GiftCards/GiftcardsPage';
import GiftCardPage from './GiftCards/GiftCardPage';
import Experiences from './Experiences/ExperiencesPage';
import ExperienceBookingPage from './Experiences/ExperiencesBookingPage';
// import ShippingAddressPage from './PaymentFlow/ShippingAddressPage'
import cookie from 'react-cookies';
import OffersPage from './Offers/OffersPage';
import OffersCategory from './Offers/OffersCategory';
import CategorySection from './shared/CategorySection';
import SearchHeader from './shared/SearchHeader';
import MobileTopUp from './MobileTopUp/MobileTopUp.jsx';
// import PaymentPage from './PaymentFlow/PaymentPage';
import OrderSummaryPage from './PaymentFlow/OrderSummaryPage';
import init from '../utils/init';
import NotFoundCard from './shared/NotFoundCard';
// import HassadGiftsPage from './HassadGifts/HassadGiftsPage';
import GiftPage from './HassadGifts/GiftPage';
import GiftsPage from  './HassadGifts/GiftsPage';
// import GiftsCategory from  './HassadGifts/GiftsCategory';
import ViewDetails from './MyWallet/ViewDetails';
import OfferPage from './Offers/OfferPage';
import BannerSection from './homePage/BannerSection';
import Tambola from './homePage/Tambola';
import FeaturedCards from './homePage/FeaturedCards';
import PointsSummaryPage from './PaymentFlow/PointsSummaryPage';
import OfferSummaryPage from './PaymentFlow/OfferSummaryPage';
import MobileTopUpSummaryPage from './PaymentFlow/MobileTopUpSummaryPage';
import TermsAndConditions from './shared/TermsAndConditions';
import ExperienceSummaryPage from './PaymentFlow/ExperienceSummaryPage';
// import OffersPaymentPage from './PaymentFlow/OffersPaymentPage';
// import ExperiencePaymentPage from './PaymentFlow/ExperiencePaymentPage';
import MerchandiseSummaryPage from './PaymentFlow/MerchandiseSummaryPage';
// import MerchandisePaymentPage from './PaymentFlow/MerchandisePaymentPage';
// import MobileTopUpPaymentPage from './PaymentFlow/MobileTopUpPaymentPage';
import MobileCategory from './CategoryPage/MobileCategory';
import i18next from 'i18next';
// un used import files , requiered in fututure 
import SoftwareGames from './Softwares&Games/Software&GamesPage';
// import SoftwareGamesBookingPage from './Softwares&Games/Softwares&GamesBookingPage';
// import SoftwareGamesPaymentPage from './PaymentFlow/SoftwareGamespaymentPage';
import SoftwareAndGamesSummaryPage from './PaymentFlow/SoftwareGamesSummaryPage'
import ViewDetailsSofGam from './MyWallet/ViewDetailsSofGam';
import SoftwarePage from './Software/SoftwarePage';
import GamesPage from './Games/GamesPage';
import SoftwaresBookingPage from './Software/SoftwaresBookingPage';
import GamesBookingPage from "./Games/GamesBookingPage"
import { connect } from 'react-redux';
import axios from 'axios';
import { MODULE_INFO } from '../Redux/categories/categoriesTypes';
import store from '../Redux/store';
import {StripePaymentPage} from '../components/PaymentFlow/stripePaymentPage.jsx'
// import { fetchAuthToken } from '../Redux/auth/authActions';
import {fetchCountryID } from '../Redux/country/countriesAction';
import ProductsPage from './CategoryPage/ProductsPage';
import Giftflow from './shared/giftflow/Giftflow';
import { REACT_APP_baseUrl, DEBUG_PARTY_TOWN } from 'utils/app-constants';
import ForgetPassword from './ForgetPassword/ForgetPassword';
import PrivacyPolicy from './shared/PrivacyPolicy';
import GiftsCategory from './HassadGifts/GiftsCategory';
import SignUp from './SignUp/SignUp';
import { checkTokenValidity } from 'Redux/auth/authActions';
import Myprofile from './Myprofile/Myprofile';
import PointsHistory from './Myprofile/PointsHistory';
import TravelShop from './TravelShop/TravelShop';
import PassengerDetails from './TravelShop/PassengerDetails';
import ContactDetail from './TravelShop/ContactDetail';
import DetailsDisplay from './TravelShop/DetailsDisplay';
import BookingDetails from './TravelShop/BookingDetails';
import TravelConfirmationModal from './shared/Modals/TravelConfirmationModal';
import Search from './TravelShop/SearchFlights';
import TravelShopResults from './TravelShop/TravelShopResults';
import FlightsDetails from './TravelShop/FlightsDetails';
import MobileGoalModal from './GoalItems/MobileGoalModal';
import { Theme, setThemeColor } from 'utils/Theme/theme'; 
import PhysicalGiftcardsPage from './PhysicalGiftcards/PhysicalGiftcardsPage';
import PhysicalGiftcardPage from './PhysicalGiftcards/PhysicalGiftcardPage';
import { Partytown } from '@builder.io/partytown/react';
import GiftcardsByCategory from './GiftCards/GiftcardsByCategory';
import Loader from './shared/Loader';

class MyRouter extends Component {
    state = {
        lang: 'en',
        loading:true
    }
    setLang = (newlang) => {
        this.setState({ lang: newlang },()=>{
            if(newlang==='ar'){
                document.dir = 'rtl'
                document.lang='ar'
                document.body.style.textAlign="right"
                document.body.style.fontFamily="arabicFont"
            }
            else{
                document.dir = 'ltr'
                document.lang= newlang
                document.body.style.textAlign="left"
                document.body.style.fontFamily=`Montserrat, sans-serif`;
            }
        })
    }
    
     moduleInfo = async () => {
           await axios.get(`${REACT_APP_baseUrl}/platform_module_images`)
                .then(response  => {
                    if(response.data.code === 200){
                        let categories = response.data.data?.module_images?.sort((a,b) => a.sort_order - b.sort_order);
                        let moduleInfo = {};
                        categories.forEach((e) => moduleInfo[e.module_name] = e.active)
                        store.dispatch({
                            type: MODULE_INFO,
                            payload:{
                              moduleInfo:moduleInfo
                            }})
                        }
                })
                .catch(err => {
                })
            // await store.dispatch(fetchAuthToken())
            await store.dispatch(fetchCountryID(this.toogleLang))
            await store.dispatch(checkTokenValidity())
            this.setState({loading:false})
    }

    toogleLang = (e) => {
        i18next.changeLanguage(e)
        this.setLang(e);
        localStorage.setItem('lang', e);
    }

    componentDidMount() {
        // let mystate =  { user_token: cookie.load('user_token') }
        let domain = window.location?.origin;        
        let color = Object.values(Theme)?.filter(val => val.domain === domain)
        if(color?.length > 0){
            setThemeColor(color)
        }
        if (domain === "https://demo-marketplace.meritincentives.com" || domain === "https://prod.marketplace.meritincentives.com") {
            window.document.title = "Merit Marketplace - Buy Gift Cards, Merchandise, Electronics and More"
            const link = document.querySelector('link[rel="icon"]');
            link.setAttribute('href', 'demoFav.svg');
        }

        let lang = localStorage.getItem("lang")
        if (lang === null || lang === undefined) {
            lang = 'en';
        }

        this.setLang(lang)
        init()
        this.moduleInfo()
    }
    render(){
        const {modulesData} = this.props

        return(
            this.state.loading ? 
            
            <div className="d-flex justify-content-center pt-2 pb-2">
                         <div className="spinner-border" role="status">
                             <span className="sr-only">Loading...</span>
                         </div>
                     </div> : 
           <div className='main-div'>
                <Partytown debug={DEBUG_PARTY_TOWN} forward={['dataLayer.push']} />
                <Router>

                    <Route path="*" render={props =>(!props.location?.search?.includes('?lang=') && !window.location?.search?.includes('?code=') )&& <TopHeader toogleLang={this.toogleLang} lang={this.state.lang} setLang={this.setLang}/>}/>
                    <Route path="*" render={props=>(!props.location?.search?.includes('?lang=') && !window.location?.search?.includes('?code=') )&& <SearchHeader {...props} lang={this.state.lang} setLang={this.setLang} toogleLang={this.toogleLang}/>}/>
                    <Route path="*" render={props=>(!props.location?.search?.includes('?lang=') && !window.location?.search?.includes('?code=') )&& <CategorySection {...props}/>}/>
                    <Route exact path='/' render={props => <Tambola setLang={this.setLang} lang={this.state.lang} {...props} />} />
                     <Route exact path='/' render={props => <BannerSection setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/' render={props => <FeaturedCards setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/' render={props => <HomePage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/auth' render={props => <Loader setLang={this.setLang} lang={this.state.lang} {...props} />}/>
                    <Route exact path='/auth/login' render={props => <HomePage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/404' render={props => <NotFoundCard lang={this.state.lang} {...props}/>} />

                    <Route exact path="/category/:categoryName" render={props =>  <CategoryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path="/category/:categoryName/:subCategory" render={props =>  <ProductsPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/selectedpartnercard' render={props => <PartnersList setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/categories' render={props =>  <MobileCategory setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/hassadpartners' render={props =>  <HassadPartnersSection setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    

                     {/* Giftflow */}
                     {/* <Route exact path='/giftflow' render={props =>  <Giftflow location={props.location} setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                   
                     <Route exact path='/redeem-card:token' render={props => <HomePage setLang={this.setLang} lang={this.state.lang} redem={true} {...props}  />} />

                    {/* <------------- Giftcards Section --------------> */}

                    {
                        modulesData["Gift Cards"] && <>
                        <Route exact path='/giftcards' render={props =>  <GiftCardsPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path="/selectedgiftcard/:id" render={props =>  <GiftCardPage setLang={this.setLang} lang={this.state.lang} {...props} />} toogleLang={this.toogleLang}/>
                        <Route exact path="/giftcards/:categoryName/:id" render={props =>  <GiftcardsByCategory setLang={this.setLang} lang={this.state.lang} {...props} />} toogleLang={this.toogleLang}/>
                        </>
                    }
                    {
                        modulesData["Physical Giftcards"] && <>
                        <Route exact path='/physicalgiftcards' render={props =>  <PhysicalGiftcardsPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path="/physicalgiftcard/:id" render={props =>  <PhysicalGiftcardPage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                        </>
                    }
                    

                    {/* <------------- Emagazine Section --------------> */}
                    {modulesData["E-Magazines"] && <>
                    <Route exact path='/e-magazines' render={props =>  <EMagazinesPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/e-magazines/:id' render={props =>  <EMagazinePage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                    </>
                    }
                    

                    {/* <------------- Offers Section --------------> */}
                    {modulesData["RewardsBy Offers"] && <>
                    <Route exact path='/offers' render={props => <OffersPage  setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/offers/:category' render={props => <OffersCategory  setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/offer/:id' render={props =>  <OfferPage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang} />} />
                    </>
                    }

                    {/* <------------- Points Exchange Section --------------> */}
                    {modulesData["Points Exchange"] && <>
                    <Route exact path='/points-exchange' render={props =>  <PointExchange setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/points-exchange/confirm-details/:id' render={props =>  <PointsExchangeFormReviewPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/points-exchange/:id' render={props =>  <PointExchangeCardPage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                    </>
                    }
                    

                     {/* <------------- Experiences Section --------------> */}

                    {modulesData["Experiences"] && <>
                    <Route exact path='/experiences' render={props =>  <Experiences setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/experience/:id' render={props =>  <ExperienceBookingPage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                    </>
                    }

                     {/* <------------- Mobile Topup Section --------------> */}
                     {modulesData["Mobile TopUp"] && 
                    <Route exact path='/mobileTopUp' render={props =>  <MobileTopUp setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} /> }


                    {/* <------------- Hassad Gifts Section --------------> */}
                    {modulesData["RewardsBy Gifts"] && <>
                    <Route exact path='/gifts' render={props => <GiftsPage  setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    {/* <Route exact path='/gifts/:category' render={props => <ProductsPage {...props}/>} /> */}
                    <Route exact path='/gifts/:category' render={props => <GiftsCategory  setLang={this.setLang} lang={this.state.lang} {...props} />} />

                    <Route exact path='/gift/:id' render={props =>  <GiftPage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                    </>
                    }

                    {/* <------------- Softwares Section --------------> */}
                    {/* {modulesData["Software"] && <>
                    <Route exact path='/softwares' render={props =>  <SoftwarePage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/softwares/:id' render={props =>  <SoftwaresBookingPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    </>
                    } */}

                    {/* <------------- Games Section --------------> */}
                    {/* {modulesData["Games"] && <>
                    <Route exact path='/games/:id' render={props =>  <GamesBookingPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/games' render={props =>  <GamesPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    </>
                    } */}

                    {/* <------------- Software and Games Section --------------> */}
                    {modulesData["Software&Games"] && <>
                        <Route exact path='/softwares&games' render={props =>  <SoftwareGames setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/softwares/:id' render={props =>  <SoftwaresBookingPage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                        <Route exact path='/games/:id' render={props =>  <GamesBookingPage setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>}/>
                    </>
                    }

                    {/* <------------- Flights Section --------------> */}
                    {modulesData["Travel shop"] && 
                    <>
                    <Route exact path='/flights' render={props =>  <TravelShop setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                    <Route exact path='/flights-results' render={props =>  <TravelShopResults setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/flights-details' render={props =>  <FlightsDetails setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/contacts' render={props =>  <ContactDetail setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/passenger-details/:number' render={props =>  <PassengerDetails setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/travel-details' render={props =>  <DetailsDisplay setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/order-confirmation-travel' render={props =>  <TravelConfirmationModal setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/searchFlight' render={props =>  <Search setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/travel-confirmation-order' render={props =>  <TravelConfirmationModal setLang={this.setLang} lang={this.state.lang} {...props} />} /> 
                    </>
                    }



                    {/* <------------- Mywallet Section --------------> */}

                    <Route exact path='/mywallet' render={props => <MyWallet setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                    <Route exact path='/mywallet/:categoryName' render={props => <MyWallet setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>} />
                    <Route exact path ='/giftcard/:token/:lang' render={props => <ViewDetails setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    {/* <Route exact path ='/softwareGames/:id/:lang' render={props => <ViewDetailsSofGam setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}

                    {/* <------------- Credit Card Section --------------> */}

                    <Route exact path='/payment/card-details' render={props =>  <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/card-details-offers' render={props =>  <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/card-details-topup' render={props =>  <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />                  
                    <Route exact path='/payment/card-details-experience' render={props =>  <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/card-details-merchandise' render={props =>  <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/card-details-software-games' render={props =>  <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} /> 
                    <Route exact path='/payment/card-details-travelshop' render={props =>  <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} /> 

                    {/* <------------- Order Summary Section --------------> */}

                    <Route exact path='/payment/orders-summary/:id' render={props =>  <OrderSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path ="/payment/mobile-topup-summary/:id" render={props =>  <MobileTopUpSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/offers-orders-summary/:id' render={props =>  <OfferSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/experience-orders-summary/:id' render={props =>  <ExperienceSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/merchandise-orders-summary/:id' render={props =>  <MerchandiseSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/points-orders-summary/:id' render={props =>  <PointsSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/softwareAndGames-orders-summary/:id' render={props =>  <SoftwareAndGamesSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/payment/travel-orders-summary/:id' render={props =>  <BookingDetails setLang={this.setLang} lang={this.state.lang} {...props} />} />
                   

                      {/* <------------- My Profile Section --------------> */}
                     <Route path="/auth/my-profile" render={props =><Myprofile  setLang={this.setLang} lang={this.state.lang} {...props} toogleLang={this.toogleLang}/>}/>
                     <Route path="/points-history" render={props =><PointsHistory toogleLang={this.toogleLang} setLang={this.setLang} lang={this.state.lang} {...props}/>}/>


                    {/* Forget Password */}
                    <Route path="/auth/forgotPassword/:token" render={props =><ForgetPassword  setLang={this.setLang} lang={this.state.lang} {...props}/>}/>
                    {/* <Route path="/auth/signup" render={props =><SignUp  setLang={this.setLang} lang={this.state.lang} {...props}/>}/> */}

                    {/* <------------- Terms, Privacy Policy, Footer --------------> */}
                    <Route exact path='/terms-and-conditions' render={props =>  <TermsAndConditions setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/privacy-policy' render={props =>  <PrivacyPolicy setLang={this.setLang} lang={this.state.lang} {...props} />} />

                    <Route exact path='/goal-item' render={props =>  <MobileGoalModal setLang={this.setLang} lang={this.state.lang} {...props} />} />

                    <Route path="*" render={props=>(!props.location?.search?.includes('?lang=') && !window.location?.search?.includes('?code=') )&& <Footer  setLang={this.setLang} lang={this.state.lang} {...props}/>}/>

                </Router>
                   
            </div>
            
    
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modulesData:state.categories.moduleInfo
    };
  };
  
export default connect(mapStateToProps)(MyRouter);