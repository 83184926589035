export function DefalutCountry(url) {
    return url ===
      "https://enftest.develop-rewardsby-marketplace.meritincentives.com"
      ? true
      : url ===
        "https://enftest.staging-rewardsby-marketplace.meritincentives.com"
      ? true
      : url === "https://enfactum.marketplace.rewardsby.com"
      ? true
      : false;
  }
  