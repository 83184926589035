import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../shared/Breadcrumbs';
import { connect } from 'react-redux';
import { fetchPointExchange } from '../../Redux/pointExchange/pointExchangeAction';
import { withTranslation } from 'react-i18next';
import {removeOrder} from '../../Redux/order/orderActions'
import axios from 'axios';
import { REACT_APP_baseUrl_V2 } from 'utils/app-constants';
import secretKeyGenerator from 'utils/serectKeyGenerator';
class PointExchange extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            pointExchangeCards : [],
            loading:true
         }
    }

    fetchPointsExchange = () =>{
        let payload = {
            brands :{
                currency : this.props.countrySelected
            }
        }
        axios.post(`${REACT_APP_baseUrl_V2}/brands/get_point_exchange_brands`,
            payload,{
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
                .then(response  => {
                    if(response.data.code === 200){
                        this.setState({
                            pointExchangeCards : response.data?.data?.brands,
                            loading:false
                        })
                    }
                }).catch((e)=>{
                    this.setState({loading:false})
                })
    }

    componentDidMount(){
        
        this.fetchPointsExchange()

        this.props.removeOrder()

        if(!this.state.loading && this.props.countrySelected !== 2){
            this.props.history.push({
                pathname:"/404",
                redirectionURL:"/points-exchange"
            })
        }
    }

    componentDidUpdate=(prevProps)=>{

        if((!this.state.loading && this.props.countrySelected !== 2) || (!this.state.loading && this.state.pointExchangeCards?.length === 0)){
            this.props.history.push({
                pathname:"/404",
                redirectionURL:"/points-exchange"
            })
        }

    }

    render() { 
        const {t} = this.props
        const breadcrumbs =[
            {name:t("Home"),url:'/',active:true},
            {name:t("Points Exchange"),url:``,active:false},
        ] 
        const desktopView=(
            <div className="d-md-block d-none">
                      {this.state.loading ? <section>
                    <div className="d-flex justify-content-center pt-2 pb-2">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section>:
            <div>
                <Breadcrumbs crumbs={breadcrumbs}/>
                <section className="px-5 py-4">
                    <h3 className="color-blue">{t("Points Exchange")}</h3>
                    <h6 className="text-black-50">{t("Exchange your point to get exciting rewards")} </h6>
                    <div>
                        {
                            !this.state.loading?
                            <div className="py-4 d-flex row">
                                {
                                 this.state.pointExchangeCards?.map(card=>(
                                        <div
                                            className="w-25 py-2 px-3 text-center"
                                            key={card.id}>
                                            <Link
                                                to={{
                                                    pathname:`/points-exchange/${card.id}`,
                                                    state:{...card}
                                                }}>
                                                <img
                                                    className="img-fluid w-100 card-hover px-1"
                                                    src={card?.images?.color.large_rectangle} 
                                                    alt={card?.name}/>
                                            </Link>                                                
                                            { this.props.lang !== 'ar' ? 
                                        <h6 className="text-muted pt-2 pb-1 m-0 text-decoration-none">{card.name}</h6>  : 
                                        <h6 className="text-muted pt-2 pb-1 m-0 text-decoration-none">{card.name_arabic}</h6>
                                            }
                                        </div>
                                    ))
                                }
                            </div>:
                            null
                        }
                    </div>
                </section>
            </div>
    }    
            </div>
        )
        const mobileView=(
            <div className='d-md-none d-block'>
                      {this.state.loading ? <section>
                    <div className="d-flex justify-content-center pt-2 pb-2">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section>:
            <div>
                <Breadcrumbs crumbs={breadcrumbs} lang={this.props.lang} />
                <section className="px-5 py-4">
                    <h3 className="color-blue">{t("Points Exchange")}</h3>
                    <h6 className="text-black-50">{t("Exchange your point to get exciting rewards")} </h6>
                    <div>
                        {
                            !this.state.loading?
                            <div className="py-4 d-flex row">
                                {
                                    this.state.pointExchangeCards?.map(card=>(
                                        <div
                                            className="w-50 py-2 px-3 text-center"
                                            key={card.id}>
                                            <Link
                                                to={{
                                                    pathname:`/points-exchange/${card.id}`,
                                                    state:{...card}
                                                }}>
                                                <img
                                                    className="img-fluid w-100 card-hover px-1"
                                                    src={card.images?.color?.large_rectangle} 
                                                    alt={card.name}/>
                                            </Link>
                                            <div className='text-center py-3' >
                                        { this.props.lang !== 'ar' ? 
                                        <h6 className="text-muted pt-2 pb-1 m-0 text-decoration-none">{card.name}</h6>  : 
                                        <h6 className="text-muted pt-2 pb-1 m-0 text-decoration-none">{card.name_arabic}</h6>
                                            }
                                    </div>                                              
                                        </div>
                                    ))
                                }
                            </div>:
                            null
                        }
                    </div>
                </section>
            </div>
    }   
            </div>

        )
        return ( 
            <>
            
            {desktopView}
            {mobileView}
                 </>
         );
    }
}

const mapStateToProps = state => {
    return {
        giftCards:state.brand.brandData,
        loading:state.brand.loading,
        pointExchangeCards:state.pointExchangeCards.pointExchangeCards,
        countrySelected:Number(state.countries.countryId),
        currancyCode:state.countries.convertionRate.currency_code,
        fetchError:state.pointExchangeCards.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPointExchange: (payload) => dispatch(fetchPointExchange(payload)),
        removeOrder : () => dispatch(removeOrder()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PointExchange));
