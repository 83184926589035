import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { fetchEMagazines } from '../../Redux';
// import { currancyToPoints } from '../../utils/pointsConversion';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../shared/Breadcrumbs';

class EMagazinesPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            eMagaziness:[],
            isLoaded:false,
         }
    }
    // fetchEMagazinesHandler=()=>{
    //     const payload = {
    //         "brands":
    //             {
    //                 "program_id":1, 
    //                 "currency":this.props.countrySelected
    //             }, 
    //     }
    //     this.props.fetchEMagazines(payload);
    // }
    componentDidMount() {
        // this.fetchEMagazinesHandler()
        // if(this.props.countrySelected!== 2){
        //     this.props.history.replace("/404")
        // }
        if(!this.props.loading && this.props.eMagazines === undefined){
            this.props.history.replace({
                pathname:"/404", 
                redirectionURL:'/e-magazines'
            })
        }
    }
    componentDidUpdate=(prevProps,prevState)=>{
        // if((prevProps.currancyCode !== this.props.currancyCode) ){
        //     this.props.history.replace("/404")
        // }
        // if((prevProps.currancyCode !== this.props.currancyCode) ){
        //     this.fetchEMagazinesHandler()
        // }
        if(!this.props.loading && this.props.eMagazines === undefined){
            this.props.history.replace({
                pathname:"/404", 
                redirectionURL:'/e-magazines'
            })
        }
    }
    render() { 
        const { t,currancyCode } = this.props;
        const breadcrumbs=[
            {name:t("Home"),url:"/",active:true},
            {name:t("E-Magazines"),url:"",active:false}
        ]
        return ( 
            <>
            {this.props.loading ? <section>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        </section> :
            <div>
                <Breadcrumbs crumbs={breadcrumbs}/>
                <section className="px-md-5 px-3 py-md-4 py-1">
                    <div className="product_title fw-md-bold fw-bolder fs-md-3 fs-5">{t("E-Magazines")}</div>
                    <div className="text-black-50 fw-bold ">{t("Dive into the world of unlimited reading")}</div>
                    <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-3 content-box">
                        {
                            this.props.eMagazines &&
                                this.props.eMagazines?.map(mag=>(
                                    <div
                                        className="text-center border-0"
                                        key={mag.id}>
                                        <Link
                                            to={{
                                                pathname:`/e-magazines/${mag.id}`,
                                                state:{...mag}
                                            }}>
                                            <img
                                                className="img-fluid w-100 card-hover"
                                                src={mag.images?.color?.large_rectangle} 
                                                alt={mag.name}/>
                                        </Link>
                                        <div className="fs-5 fw-bold py-md-3 py-2">
                                            <div className="product_title">{ this.props.lang !== 'ar' ? mag.name:mag.name_arabic }</div>
                                            <div className="">{mag.rates[0]} <small>{t(currancyCode)}</small></div>
                                        </div>
                                        
                                    </div>
                                ))

                        }
                    </div>
                </section>
            </div>
                 }
            </>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        // eMagazines: state.brand.brandData?.filter((e) => e.category_id === 52)[0]?.brands,
        eMagazines:state.eMagazines.eMagazines,
        loading:state.eMagazines.loading,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        fetchError:state.brand.error
    }
}


const mapDispatchToProps = dispatch => {
    return {
        // fetchEMagazines: (payload) => dispatch(fetchEMagazines(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EMagazinesPage));
