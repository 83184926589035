export const FETCH_AUTH_TOKEN_REQUEST = "FETCH_AUTH_TOKEN_REQUEST";
export const FETCH_AUTH_TOKEN_SUCCESS = "FETCH_AUTH_TOKEN_SUCCESS";
export const FETCH_AUTH_TOKEN_FAILURE = "FETCH_AUTH_TOKEN_FAILURE";
export const VALID_TOKEN = "VALID_TOKEN";
export const DELETE_TOKEN = "DELETE_TOKEN";
export const FETCH_TOKEN_REQUEST = "FETCH_TOKEN_REQUEST";
export const FETCH_AUTH_TOKEN_INVALID = "FETCH_AUTH_TOKEN_INVALID";
export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
export const SIGN_UP_MESSAGE = "SIGN_UP_MESSAGE"
export const SIGN_UP_MESSAGE_CLEAR = "SIGN_UP_MESSAGE_CLEAR"
export const LOGIN_FLOW_SUCCESS = "LOGIN_FLOW_SUCCESS"
