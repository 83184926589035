import { FETCH_AUTH_TOKEN_FAILURE, DELETE_TOKEN, FETCH_AUTH_TOKEN_REQUEST, FETCH_AUTH_TOKEN_SUCCESS, VALID_TOKEN, FETCH_AUTH_TOKEN_INVALID, FETCH_TOKEN_REQUEST, REMOVE_ERROR_MESSAGE, SIGN_UP_MESSAGE, SIGN_UP_MESSAGE_CLEAR, LOGIN_FLOW_SUCCESS } from "./authTypes"

const initialState = {
    authToken:"",
    loading: false,
    error:'',
    authErroMsg:"",
    valid:false,
    tokenLoading:false,
    SignUpMessage:false,
    email:"",
    LoginFlow:{
        provider:"",
        login_link:"",
        login_flow:"SSO"
    }
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_AUTH_TOKEN_REQUEST:
            return {
                ...state,
                loading:true,
                valid:false,
                authErroMsg:""
            }
        case FETCH_AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                loading:false,
                valid: true,
                authToken:action.payload,
            }
        case FETCH_AUTH_TOKEN_INVALID:
            return {
                ...state,
                loading: false,
                invalid: true,
                authErroMsg:action.payload
            }
        case FETCH_AUTH_TOKEN_FAILURE:
            return {
                ...state,
                loading:false,
                tokenLoading:false,
                valid:false,
                error:action.payload
            }
            case FETCH_TOKEN_REQUEST:
                return {
                    ...state,
                    tokenLoading:true,
                    valid:false,
                }
        case VALID_TOKEN:
            return{
                ...state,
                tokenLoading:false,
                loading:false,
                valid:true,
                authToken:action.payload.authtoken
            }
        case DELETE_TOKEN:
            return{
                ...state,
                loading:false,
                tokenLoading:false,
                valid:false,
                authToken:'',
                error:'Session Timeout',
            }
        case REMOVE_ERROR_MESSAGE:
            return{
                ...state,
                authErroMsg:""
            }
        case SIGN_UP_MESSAGE:
            return{
                ...state,
                SignUpMessage:true,
                email:action.payload
            }
        case SIGN_UP_MESSAGE_CLEAR:
            return{
                ...state,
                SignUpMessage:false
            }
        case LOGIN_FLOW_SUCCESS:
            return{
                ...state,
                loading:false,
                LoginFlow:{
                    provider:action?.payload?.provider,
                    login_link:action?.payload?.login_link,
                    login_flow:action?.payload?.login_flow
                }
            }
        default: return state
    }
}

export default reducer;