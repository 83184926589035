import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_EMAGAZINES_FAILURE, FETCH_EMAGAZINES_REQUEST, FETCH_EMAGAZINES_SUCCESS } from "./eMagazinesTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';


export const fetchEMagazinesRequest = () => {
    return {
        type:FETCH_EMAGAZINES_REQUEST,
    }
}

export const fetchEMagazinesSuccess = data => {
    return {
        type:FETCH_EMAGAZINES_SUCCESS,
        payload:data
    }
}

export const fetchEMagazinesFailure = error =>{
    return {
        type:FETCH_EMAGAZINES_FAILURE,
        payload:error
    }
}

// const cacheHeaders = () => {
//     return {
//         headers: {
//             'Content-Type': 'application/json',
//             'Cache-Control': 'no-cache, no-store, must-revalidate',
//             'Expires': '0'
//         }
//     }
//   }

export const fetchEMagazines = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_EMAGAZINES_REQUEST})
        dispatch(fetchEMagazinesRequest);
        axios.post(`${REACT_APP_baseUrl}/brands/emagazine_brands`,
        payload,{
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                const eMagazines = response?.data?.data?.categories?.[0]?.brands;
                dispatch(fetchEMagazinesSuccess(eMagazines))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchEMagazinesFailure("failed"))
            })
    }
}