/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import BuyNowCard from '../shared/BuyNowCard';
import TAndConly from '../shared/T&Conly';
import { connect } from 'react-redux';
import { fetchBrand } from '../../Redux/brand/brandActions';
import { withTranslation } from 'react-i18next';
import {removeOrder} from '../../Redux/order/orderActions'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { checkTokenValidity } from '../../Redux/auth/authActions';
import '../shared/giftflow/Buygift.css';
import EmailPreview from "../shared/giftflow/EmailPreview";
import { REACT_APP_baseUrl } from 'utils/app-constants';
// import WarningModal from 'components/shared/Modals/WarningModal';
import { setGoalItem } from 'Redux/goalItems/goalItemsActions';
import GoalItemStatusModal from 'components/GoalItems/GoalItemStatusModal';
import GoalItemConfirmModal from 'components/GoalItems/GoalItemConfirmModal';
import { fetchCountyCode } from 'Redux/CountryCode/CountryCodeActions';
import PhysicalCardConfirmantionModal from 'components/shared/Modals/PhysicalCardConfirmantionModal';
class PhysicalGiftcardPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading:true,
            giftcard_value:null,
            giftcard_points:null,
            giftcard_currency:"",
            giftcard_country:"",
            sku:"",
            quantity:1,
            confirmOrder:false,
            orders:{},
            termsConds:[],
            cardPayment:false,
            emailPreviewshow: false,
            showNewGiftFlow: !this.props.convertionRate.enable_buying_as_gift_feature,
            insufficientBalance:false,
            goalModalShow:false,
            goalStatusModalShow:false,
            goalModalDetail:'',
            cardDetails:{}
         }
    }

    componentDidMount=()=>{
        window.scrollTo(0, 0)
        localStorage.removeItem("type");
        localStorage.setItem("type", "physicalGiftcards");
        this.fetchBrandHandler(this.props.match?.params?.id);
        this.props.removeOrder();
        this.props.checkTokenValidity() 
        this.props.fetchCountyCode()
    }

    setGoalModalView = (value)=>{
        this.setState({goalModalShow: !this.state.goalModalShow,goalModalDetail:value || this.state.goalModalDetail})
    }
    setGoalConfirmModalView = ()=>{
        this.setState({goalConfirmModalShow: !this.state.goalConfirmModalShow})
    }

    setGoalStatusModalView = () => {
        this.setState({goalStatusModalShow:false})
    }

    setGoalItem = (date) => {
        let payload = {
            "customer_goal_item": [{
                "product_id": this.props.match?.params?.id,
                "product_type": "MERCHANT_PRODUCT",
                "product_price": this.state.giftcard_value,
                "goal_item": this.state.goalModalDetail === 'Remove' ? false : true,
                "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
            }]
        }

        let updatePayload = {
            "customer_goal_items": {
                "product_id": this.props.match?.params?.id,
                "product_type": "MERCHANT_PRODUCT",
                "product_price": this.state.giftcard_value,
                "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
            }
        }

        this.props.setGoalItem(
            this.state.goalModalDetail === 'Update' ? updatePayload : payload,
            ()=>this.fetchBrandHandler(this.props.match?.params?.id),
            this.state.goalModalDetail);
        this.setState({goalStatusModalShow:true})
    }

    fetchBrandHandler = (id) => {
        const payload = {
                "id": id,
            }
        this.fetchGiftcardDetail(payload)
    }

    numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    componentDidUpdate=(prevProps,prevState)=>{
        if(prevState?.loading !== this.state.loading && Object.keys(this.state.cardDetails).length === 0) {
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/physicalgiftcards'
            })
        }
    }

    setGiftcardValue(val, points, currency, country, sku){
        this.setState({
            giftcard_value:val,
            giftcard_points:points,
            giftcard_currency:currency,
            giftcard_country:country,
            sku:sku
        })
    }
    setQuantity=(val)=>{
        this.setState({quantity:val})
    }
    orderSummary=(val,...props)=>{
        let amount = this.state.quantity * this.state.giftcard_value
        let amount_points = this.state.quantity * this.state.giftcard_points
        //     this.setState({insufficientBalance:true})
        //     return
        // }
        let data = props[0]?.data || null
        let type = props[0]?.type || null
        let paymentType = props[0]?.paymentType
        let quantity = this.state.quantity
        let giftcardValue = this.state.giftcard_value
        let orders;
        if (this.state.showNewGiftFlow && this.props.message != ""){
            if (paymentType === 'creditcard') {
                orders = {
                    "brand_id": this.props.giftCard.id,
                    "quantity": quantity,
                    "giftcard_value": giftcardValue,
                    "amount": amount,
                    "program_id": 1,
                    "isforself": (this.props.message == "" ? true : false),
                    "use_hassad_points": false,
                    "language_code": this.props.lang,
                    "currency": this.props.countrySelected,
                    "hassad_points": "0",
                    "gift_message": this.props.message,
                    "sender_name": this.props.from,
                    "gift_occasion_image_id": this.props.GiftParticularCardSelect,
                    "recipient_name": this.props.to,
                }
                this.setState({ cardPayment: true })
            }
            else {
                orders = {
                    "brand_id": this.props.giftCard.id,
                    "quantity": quantity,
                    "giftcard_value": giftcardValue,
                    "amount": amount,
                    "program_id": 1,
                    "isforself": (this.props.message == "" ? true : false),
                    "use_hassad_points": this.props.pointBalance > 0,
                    "language_code": this.props.lang,
                    "currency": this.props.countrySelected,
                    "hassad_points": amount_points <= this.props.pointBalance ? amount_points : this.props.pointBalance,
                    "gift_message": this.props.message,
                    "sender_name": this.props.from,
                    "gift_occasion_image_id": this.props.GiftParticularCardSelect,
                    "recipient_name": this.props.to,
                }
                this.setState({ cardPayment: false })
            }
            this.setState({
                confirmOrder: !this.state.confirmOrder,
                orders: orders
            }, () => {
                if (this.state.orders.isforself == false) {
                    if (this.props.format == "both") {
                        this.setState(prevState => ({
                            orders: {
                                ...prevState.orders,
                                "contact_email": this.props.email,
                                "contact_phone": `${this.props.giftCountryCode}${this.props.mobile}`,
                                "gift_occasion_image_both_flag": true
                            }
                        }))
                    }
                    else if (this.props.format == "email") {
                        this.setState(prevState => ({
                            orders: {
                                ...prevState.orders,
                                "contact_email": this.props.email,
                                "gift_occasion_image_both_flag": false
                            }
                        }))
                    }
                    else {
                        this.setState(prevState => ({
                            orders: {
                                ...prevState.orders,
                                "contact_phone": `${this.props.giftCountryCode}${this.props.mobile}`,
                                "gift_occasion_image_both_flag": false
                            }
                        }))
                    }
                }
            })
        }
        else{
            if(paymentType === 'creditcard'){
                orders = {
                    "sku":this.state.sku,
                    "quantity":quantity,
                    "amount":amount,
                    "isforself":!props[0]?.isGift,
                    "use_hassad_points":false,
                    "language_code":this.props.lang,
                    "currency_code":this.state?.giftcard_currency,
                    "country_code":this.state?.giftcard_country,
                    "hassad_points":"0",
                }
                this.setState({cardPayment:true})
            }
            else{
                orders = {
                    "sku":this.state.sku,
                    "quantity":quantity,
                    "amount":amount,
                    "isforself":!props[0]?.isGift,
                    "use_hassad_points":this.props.pointBalance>0,
                    "language_code":this.props.lang,
                    "currency_code":this.state?.giftcard_currency,
                    "country_code":this.state?.giftcard_country,
                    "hassad_points": Number(amount_points) <= Number(this.props.pointBalance) ? amount_points : this.props.pointBalance,
                }
                this.setState({cardPayment:false})
            }
            this.setState(prevState=>({
                confirmOrder:val,
                orders:orders
    
            }))
        }
       
    }
    emailPreviewshowHandler = () => {
        this.setState({ emailPreviewshow: !this.state.emailPreviewshow });
    }

    fetchGiftcardDetail = (payload) =>{
        axios.post(`${REACT_APP_baseUrl}/merchant_products/get_physical_giftcard_details`,payload).then(res =>{
            if(res?.status === 200) this.setState({cardDetails:res?.data, termsConds:res?.data?.terms},()=>{
                this.setCardValue()
            })
            this.setState({loading:false})
        }).catch(err =>{
            this.setState({loading:false})
        })
    }

    setCardValue = () =>{
        this.setState({giftcard_value:this.state.cardDetails?.prices?.[0]?.price?.amount, giftcard_points:this.state.cardDetails?.prices?.[0]?.price?.points, giftcard_currency:this.state.cardDetails?.prices?.[0]?.price?.currency, giftcard_country:this.state.cardDetails?.prices?.[0]?.country, sku:this.state.cardDetails?.prices?.[0]?.sku})
    }

    render() { 
        const {t,currancyCode,email, message,url, from,to, mobile, giftCountryCode} = this.props
        const {confirmOrder,orders,giftcard_value,quantity,goalModalDetail,giftcard_points,cardDetails} = this.state
        const card = cardDetails
        const breadcrumbs =[
            {name:t("Home"),url:'/',active:true},   
            {name:t("Physical Gift cards"),url:`/physicalgiftcards`,active:true},
            {name:this.props.lang !== 'ar' ? card?.name:card?.name_arabic, url:'', active:false}
        ] 
        const { state } = this.props.location;
        return ( 
            <>
            <GoalItemStatusModal goalModalDetail={goalModalDetail} show={this.state.goalStatusModalShow} setGoalModalView={this.setGoalStatusModalView} />
            { this.state.loading ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section> :
                <section>

                    
                    <Breadcrumbs crumbs={breadcrumbs}/>
                    <GoalItemConfirmModal goalModalDetail={goalModalDetail} setGoalModalView={this.setGoalModalView} show={this.state.goalModalShow} lang={this.props.lang} productValue={this.state.giftcard_value} currancyCode={this.state.giftcard_currency} setGoalItem={this.setGoalItem} image={card?.image?.medium_rectangle} title={card?.name} title_arabic={card?.name_arabic} isPhysicalCard={true} giftcard_points={this.state.giftcard_points}
                        />
                    
                       {/* !this.props.loading && */}
                            {
                                confirmOrder ?  <PhysicalCardConfirmantionModal 
                                cardPayment={this.state.cardPayment} 
                                lang={this.props.lang} 
                                orders={orders} card={card} 
                                show={confirmOrder} 
                                remove={this.orderSummary}
                                GiftCardValue={giftcard_value} 
                                GiftCardEmail={email} 
                                GiftCardMobile={mobile} 
                                GiftCardMobileCode={giftCountryCode} 
                                GiftCardQuantity={quantity}
                                GiftCardMessage={message} 
                                url={url}
                                physicalCard={true}
                                currancyCode={this.state?.giftcard_currency}
                                giftcard_points={giftcard_points * quantity}
                                />
                                :
                                <>
                                <div className="px-md-5 px-3 pt-md-5 pt-3  d-flex flex-md-row flex-column pb-0 pb-md-0">
                                <div className="col-3 p-0 d-md-block d-none">
                            <img  
                                className="img-fluid p-0 mx-auto d-block"   
                                src={card?.image?.medium_rectangle}
                                alt={card?.name}
                            />
                        </div>
                        <div className="flex-grow-1 px-md-5 px-0">
                            <div className="pb-md-3 pb-2">
                                <div className="fs-md-3 fs-5 fw-md-bold fw-bolder product_title">
                                { this.props.lang !== 'ar' ? card?.name:card?.name_arabic }
                                </div>
                                {/* <div className='text-black-50 m-0 py-1 fs-md-5 fs-6 fw-bold'>
                                { this.props.lang === 'en' ? card.name:card.name_arabic }
                                </div> */}
                            </div>
                            {
                                card?.prices == null ? false : 
                                <div className="fs-5 fw-bold text-dark" style={{fontSize:"20px"}}>
                                {this.numberWithCommas((giftcard_points * quantity))}
                                    &nbsp;<small>{t("Points")}</small>
                                </div>
                            }
                            <div className='d-md-none d-block p-3'>
                                <img 
                                    className="mx-auto d-block"
                                    src={card?.image?.medium_rectangle} 
                                    alt={card?.name} srcSet=""
                                    style={{objectFit:'cover',height:'40vw',maxWidth:'100%'}}/>
                            </div>
                            <hr className="hr-line my-2"/>
                            { card?.prices  === null ? 
                                <div className="text-center"> 
                                    <p className='text-danger fw-bold'>{t("Sold Out")}...... {t("Coming Soon")}</p>
                                </div> : 
                            <div className=" py-md-4 py-2">
                                <div className='pb-2'>{t("Card Value")}: <span className="text-dark fw-bold">{t(this.state.giftcard_currency)} {giftcard_value}</span></div>
                                <div className="d-flex flex-wrap">
                                    {
                                         this.state.cardDetails?.prices?.map(rate=>(
                                            <button
                                                className={`btn px-4 rounded-0 text-muted gridRates no-outline ${giftcard_value===rate?.price?.amount?'selected-value':' border'}`}
                                                key={rate?.price?.amount}
                                                onClick={()=>{this.setGiftcardValue(rate?.price?.amount, rate?.price?.points, rate?.price?.currency, rate?.country, rate?.sku)}}>
                                                {rate?.price?.amount}
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>
                        }   
                                <div className='d-block d-md-none my-4 '>
                                {card?.prices=== null ? false :
                               
                               <TAndConly  termsConds={this.state.termsConds} id={this.props.match.params?.id} lang={this.props.lang}/>
                                   }
                                </div>
                                
                            </div>
                            {card?.prices == null ? false:
                            <BuyNowCard
                            rechargeProduct={card?.recharge_product}
                            quantityDisable={true}
                             onSubmit={this.orderSummary}
                             id={this.props.match.params.id}  
                            setQuantity={this.setQuantity} lang={this.props.lang}
                            price={(giftcard_value * quantity)}
                            giftCardValue={giftcard_value}
                            card={card}
                            termsConds={this.state.termsConds}
                            emailPreviewshowHandler={this.emailPreviewshowHandler}
                            emailPreviewshow={this.state.emailPreviewshow}
                            state={state}
                            currancyCode={this.props.currancyCode}
                            history={this.props.history}
                            setGoalModalView={this.setGoalModalView}
                            selectedRate = {giftcard_value}
                            productID = {this.props.match?.params?.id}
                            GoalItem={true}
                            physicalCard={true}
                            toogleLang={this.props.toogleLang}
                            />
                            }
                        </div>
                        <div className='d-md-block d-none pb-4 px-5 col-9'>
                                {card?.prices=== null ? false :
                               
                               <TAndConly  termsConds={this.state.termsConds} id={this.props.match.params?.id} lang={this.props.lang}/>
                                   }
                                </div>
                                </>
                            }

                            {/* {
                                this.state.insufficientBalance && <WarningModal show={this.state.insufficientBalance} closeModal={() => {this.setState({insufficientBalance:false})}} />
                            } */}
                        
                        <EmailPreview
                                show={this.state.emailPreviewshow}
                                showMethod={this.emailPreviewshowHandler}
                                card={card} 
                                termsConds={this.state.termsConds}    
                                lang={this.props.lang}
                                giftCardValue={giftcard_value}  
                                from={from}
                                url={url}    
                                GiftMessage= {message}
                                currancyCode={this.props.currancyCode}
                        />
                        {
                            (message)?<div className='d-md-block d-none px-5 position-relative'>
                                    <Link 
                                        to={{
                                        pathname: '/giftflow',
                                        state:{
                                            id: this.props.match.params.id,
                                            GiftMessage: message,
                                            GiftFrom: from,
                                            GiftTo: to,
                                            GiftEmail: email,
                                            GiftMobile: mobile,
                                            GiftCountryCode: giftCountryCode
                                        }
                                        }}
                                        >
                                            <button className="color-blue giftcardeditbuttondesk">{t("Edit")}</button>
                                    </Link>
                                    <div className="giftcardeditdesk px-5 pt-5">
                                        <div className="row pb-5 border-bottom">
                                            <div className="col-3">
                                            <img width="100%" height="auto" src={url} alt="" />
                                            </div>
                                            <div className="col-4 d-flex align-items-center pl-4">
                                                <p className="giftcardeditmssg">{message}</p>
                                            </div>
                                            <div className="col-2">
                                            </div>
                                            <div className="col-3 text-center">
                                                <img width="80%" height="auto"                                 
                                                    src={card?.image?.medium_rectangle}
                                                    alt="" />
                                                <p className="fw-bold fs-4 ">{t(currancyCode)} {giftcard_value}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                       
                                        <div className="pt-2 hoverpointer">
                                            <p onClick={this.emailPreviewshowHandler} className="w-100 text-right fw-bold"> <ins>{t("Click for the email preview")}</ins></p>
                                        </div>
                                    </div>
                            </div>: null
                        }
                </section> }
            </>
                );
    }
}

const mapStateToProps = state => {
    return {
        giftCard:state.brand.brand,
        loading:state.brand.loading,
        prodNotFound:state.brand.prodNotFound,
        pointBalance:state.point.pointBalance,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        convertionRate:state.countries.convertionRate,
        fetchError:state.brand.error,
        email: state.giftFormDetails.email,
        message: state.giftFormDetails.message,
        url: state.giftFormDetails.url,
        from: state.giftFormDetails.from,
        to: state.giftFormDetails.to,
        mobile: state.giftFormDetails.mobile,
        giftCountryCode: state.giftFormDetails.giftCountryCode,
        format: state.giftFormDetails.GiftFormat,
        GiftParticularCardSelect: state.giftFormDetails.GiftParticularCardSelect,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchBrand : (payload) => dispatch(fetchBrand(payload)),
        removeOrder : () => dispatch(removeOrder()),
        checkTokenValidity : () => dispatch(checkTokenValidity()),
        setGoalItem: (payload,productAPI,status) => dispatch(setGoalItem(payload,productAPI,status)),
        fetchCountyCode : () => dispatch(fetchCountyCode())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((withTranslation()(PhysicalGiftcardPage))));