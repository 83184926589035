import moment from 'moment';
import React, { Component } from 'react';
import { Accordion, Card, Form } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// import TimeRangeSlider from 'react-time-range-slider';
// import TimeRange from "react-timeline-range-slider";
import arrowup from '../../assests/img/TravelShop/arrow-up.svg';
import InfoIcon from '../../assests/img/TravelShop/info.svg'
import FilterModal from "../TravelShop/Modal/FilterModal";
import SortIcon from '../../assests/img/TravelShop/sort-icon.svg'
import Emirates from '../../assests/img/TravelShop/emirates.svg'
import British from '../../assests/img/TravelShop/british.svg'
import AirportAirlines from "../TravelShop/Modal/AirportAirlines";
import TravelShopFlightsBox from "./TravelShopFlightsBox";
import TravelDetailTopBar from "./TravelDetailTopBar";
import { setFilterData, setSearchOrder, setSelectedFlightDetails } from "../../Redux/travelShop/TravelAction";
import FlightTimeModal from './Modal/FlightTimeModal';
import TravelShopDetails from './TravelShopDetails';

const stopsFilters = [{ name: 'Non-Stop', value: 'non_stop' }, { name: '1 Stop', value: 'one_stop' }, { name: '2+ Stop', value: 'two_plus_stops' }]
const TabList = [
    {
        tabName: 'Best',
        className: 'active',
        name: 'best',
        icon: InfoIcon,
        points: 'best_price_in_points'
    },
    {
        tabName: 'Cheapest',
        name: 'cheapest',
        points: 'cheapest_price_in_points'
    },
    {
        tabName: 'Quickest',
        name: 'quickest',
        points: 'quickest_price_in_points'
    }
]

const timelineInterval = [
    new Date('01-5-2022 18:00'),
    new Date()
];

const selectedInterval = [
    new Date('01-6-2022 18:00'),
    new Date()
];

const quadrants = {
    quadrant1: ['12:00 AM', '05:59 AM'],
    quadrant2: ['06:00 AM', '11:59 AM'],
    quadrant3: ['12:00 PM', '05:59 PM'],
    quadrant4: ['06:00 PM', '11:59 PM'],
}
const initialFlightTiming = { outBound: { arrival: '', departure: '' }, inBound: { arrival: '', departure: '' } }
class TravelFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            departureTime: [],
            landingTime: [],
            toggle: {
                first: true,
                second: true,
                third: true,
                fourth: true
            },
            price: "0",
            selectedTab: 0,
            flightTime: initialFlightTiming,
            airlines: {},
            sort: false,
            flexible: false,
            stops: false,
            times: false,
            airports: false,
            stopModal: false,
            sortModal: false,
            showAirport: false,
            airlines: false,
            showAirlines: false,
            loading: false
        }
    }


    componentDidMount = () => {
        this.handleSearchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.handleSearchData()
        }
    }

    componentWillReceiveProps = () => {
        this.handleSearchData()
    }

    handleAirline = (e, name, airlineData) => {
        const airlines = airlineData ? { ...this.state.airlines, ...airlineData } : { ...this.state.airlines, [name]: e.target.checked }
        this.setState((state) => ({ ...state, airlines }))
        const filteredAirlines = Object.entries(airlines).filter((data, i) => data[1]).map(data => data[0])
        const payload = {}
        if (filteredAirlines.length) payload['airlines'] = filteredAirlines
        setTimeout(() => {
            this.fetchData(payload)
        }, 100);
    }

    handleStops = (e, stopData) => {
        const stops = stopData ? { ...this.state.stops, ...stopData } : { ...this.state.stops, [e?.target?.name]: { ...this.state.stops[e?.target?.name], checked: e?.target?.checked } }
        this.setState((state) => ({ ...state, stops }))
        const filteredStops = Object.entries(stops).filter((data, i) => data[1].checked).map(data => data[0].replace(/_/g, "-"))
        const payload = {}
        if (filteredStops.length) payload['stops'] = filteredStops
        setTimeout(() => {
            this.fetchData(payload)
        }, 100);
    }

    timeChangeHandler(time, type) {
        if (time !== this.state[type]) {
            if (!this.state.timeChangeFlag) {
                setTimeout(() => {
                    this.fetchData({})
                }, 700);
            }
            this.setState({
                [type]: time,
                timeChangeFlag: true
            });
        }
    }

    inputRangeHandler = (value, type) => {
        if (!this.state.priceChangeFlag) {
            setTimeout(() => {
                this.fetchData({})
            }, 700);
        }
        this.setState({ [type]: value, priceChangeFlag: true })
    };

    errorHandler = (error) => {
        console.log(`error 888`, error)
    };


    fetchData = (type, sortBy = false, resetFilters = false) => {
        const search_id = localStorage.getItem("search_id");

        const { departureTime, landingTime, price, filtered_metadata, duration, flightTime } = this.state
        const query_params = {}

        // query_params['departure_date_min'] = moment(`${moment(filtered_metadata?.departure_time_data?.min).format('YYYY-M-DD')} ${departureTime.start}`, 'YYYY-M-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss')
        // query_params['departure_date_max'] = moment(`${moment(filtered_metadata?.departure_time_data?.max).format('YYYY-M-DD')} ${departureTime.end}`, 'YYYY-M-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss')

        // query_params['arrival_date_min'] = moment(`${moment(filtered_metadata?.landing_time_data?.min).format('YYYY-M-DD')} ${landingTime.start}`, 'YYYY-M-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss')
        // query_params['arrival_date_max'] = moment(`${moment(filtered_metadata?.landing_time_data?.max).format('YYYY-M-DD')} ${landingTime.end}`, 'YYYY-M-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss')
        const FlightTime = this.getFlightTimePayload(flightTime)
        const stops = Object.entries(this.state.stops).filter((data, i) => data[1].checked).map(data => data[0].replace(/_/g, "-"))
        if (stops.length) query_params['stops'] = stops
        const airlines = Object.entries(this.state.airlines).filter((data, i) => data[1]).map(data => data[0])
        if (airlines.length) query_params['airlines'] = airlines
        if (this.state.priceChangeFlag){
            query_params['price_min'] = filtered_metadata?.price_information_local?.min_price_in_points
            query_params['price_max'] = Number(price)
            query_params['total_duration'] = Number(duration)
        } 
        query_params['order'] = sortBy || this.props.searchOrder
        if(this.props.mobileDevice){
            query_params['offset'] = Number(0)   
        }else{
            query_params['limit'] = this.props.limit
            query_params['offset'] = Number(0)
        }


        const payload = resetFilters ? { order: query_params['order'], limit : query_params['limit'], offset : query_params['offset'] } : { ...query_params, ...FlightTime, ...type }

        this.props.setFilterData(payload)
        delete this.props.searchResults?.search
        setTimeout(() => {
            this.props.fetchSearch({
                search: {
                    search_id,
                    query_params: payload
                }
            }, false, sortBy)
        }, 300);
        // this.setState({
        //     timeChangeFlag: false,
        //     priceChangeFlag: false
        // });
    }


    toggleHandler = (type) => {
        this.setState({ toggle: { ...this.state.toggle, [type]: !this.state.toggle[type] } });
    }

    dateTimeFormat = (date, time) => {
        const dateFormate = moment(date).format('YYYY-MM-DD')
        const timeFormate = moment(time, 'hh:mm A').format('HH:mm:ss')
        return dateFormate + 'T' + timeFormate
    }

    getFlightTimePayload = (flightTime) => {

        const payload = {}
        const { startDate, endDate } = this.props.searchQueryData
        const { dateTimeFormat } = this

        if (flightTime.outBound?.departure) {
            payload.departure_datetime_filter = (payload?.departure_datetime_filter ? payload?.departure_datetime_filter : []).concat(["1," + dateTimeFormat(startDate, quadrants[flightTime.outBound.departure][0]) + "," + dateTimeFormat(startDate, quadrants[flightTime.outBound.departure][1])])
        }

        if (flightTime.inBound?.departure) {
            payload.departure_datetime_filter = (payload?.departure_datetime_filter ? payload?.departure_datetime_filter : []).concat(["2," + dateTimeFormat(endDate, quadrants[flightTime.inBound.departure][0]) + "," + dateTimeFormat(endDate, quadrants[flightTime.inBound.departure][1])])
        }

        if (flightTime.outBound?.arrival) {
            payload.arrival_datetime_filter = (payload?.arrival_datetime_filter ? payload?.arrival_datetime_filter : []).concat(["1," + dateTimeFormat(startDate, quadrants[flightTime.outBound.arrival][0]) + "," + dateTimeFormat(startDate, quadrants[flightTime.outBound.arrival][1])])
        }

        if (flightTime.inBound?.arrival) {
            payload.arrival_datetime_filter = (payload?.arrival_datetime_filter ? payload?.arrival_datetime_filter : []).concat(["2," + dateTimeFormat(endDate, quadrants[flightTime.inBound.arrival][0]) + "," + dateTimeFormat(endDate, quadrants[flightTime.inBound.arrival][1])])
        }
        return payload
    }

    handleFlightTime = (quadrant, type, flightData) => {
        const flightType = this.state.selectedTab ? 'inBound' : 'outBound'
        const flightTime = flightData ? flightData : { ...this.state.flightTime, [flightType]: { ...this.state.flightTime[flightType], [type]: quadrant } }
        this.setState({ flightTime });
        const payload = this.getFlightTimePayload(flightTime)
        this.fetchData(payload)

    }

    handleSearchData = () => {
        if (this.state?.correlationID !== this.props?.searchResults?.correlationID) {
            const data = this.props.searchResults.search
            const filtered_metadata = this.props?.searchResults?.filtered_metadata

            // const departureTime = { start: moment(filtered_metadata.departure_time_data.min).format('HH:mm'), end: moment(filtered_metadata.departure_time_data.max).format('HH:mm') }
            // const landingTime = { start: moment(filtered_metadata.landing_time_data.min).format('HH:mm'), end: moment(filtered_metadata.landing_time_data.max).format('HH:mm') }
            const airlines = {}
            filtered_metadata?.airlines.forEach((airline) => { airlines[airline?.code] = false })
            const stops = {}
            Object.entries(filtered_metadata?.stops_aggregated_amount).forEach((data) => { stops[data[0]] = { name: data[1], checked: false } })
            // const departureTime = [new Date(filtered_metadata.departure_time_data.min), new Date(filtered_metadata.departure_time_data.max)]
            // const landingTime = [new Date(filtered_metadata.landing_time_data.min), new Date(filtered_metadata.landing_time_data.max)]
            let newstate = { filtered_metadata: filtered_metadata, correlationID: this.props?.searchResults?.correlationID, search_id: this.props?.searchResults?.search_id }

            if ((!this.state.correlationID) || (this.state?.search_id !== this.props?.searchResults?.search_id)) {
                newstate = {
                    ...newstate,
                    stops,
                    airlines,
                    price: filtered_metadata?.price_information_local?.max_price_in_points,
                    duration: filtered_metadata?.duration?.max,
                    airlinesData: filtered_metadata?.airlines,
                    durationData: filtered_metadata?.duration,
                    priceData: filtered_metadata?.price_information_local,
                }
            }
            this.setState(newstate)
        }
    }
    filterHandler(name) {
        this.setState((prev) => ({ [name]: !prev[name] }));
    }
    closeModal = () => {
        this.setState({
            stopModal: false,
            sortModal: false,
            showAirport: false,
            showAirlines: false,
            times: false,
        });
    };
    handleSort = (name) => {
        this.setState((prev) => ({ [name]: true }));
    };

    clearFilter = (name) => {
        this.setState((prev) => ({ [name]: false }));
    };

    handleOkStop = (data) => {
        this.handleStops(null, data?.stops || {})
        this.closeModal()
    }

    handleOkFlightTime = (data) => {
        this.handleFlightTime(null, null, data?.flightTime || false)
        this.closeModal()
    }

    handleOkSort = (data) => {
        if (data.searchOrder !== this.props.searchOrder) {
            this.props.setSearchOrder(data.searchOrder)
            this.fetchData({}, data.searchOrder)
        }
        this.closeModal()
    }

    handleOkAirline = (data) => {
        this.handleAirline(null, null, data?.airlines || [])
        this.closeModal()
    }

    handleResetFilter = () => {
        const filtered_metadata = this.props?.searchResults?.filtered_metadata
        this.setState({
            filtered_metadata: filtered_metadata,
            price: filtered_metadata?.price_information_local?.max_price_in_points,
            duration: filtered_metadata?.duration?.max,
            flightTime: initialFlightTiming,
            airlines: {},
            stops: {},
        })
        setTimeout(() => {
            this.fetchData({}, false, true)
        }, 100);
    }

    flightTimeView = () => {
        const { filtered_metadata } = this.state
        const { searchResults: { segments }, t } = this.props
        return (
            <>
                <div className="flights-tab-results d-flex mb-3">
                    {segments?.map((data, i) => {
                        return <div className={`flight-time-tab ${i === this.state.selectedTab ? 'active' : ''}`} key={i} onClick={() => this.setState({ selectedTab: i })}>
                            <h5><span>{i ? t("Inbound flight") : t("Outbound Flight")}</span></h5>
                        </div>
                    })}
                </div>
                <div>
                    <span className="mb-2 d-block">
                        <b>{t("Departs from")} {segments[this.state.selectedTab]?.origin}</b>
                    </span>
                    {Object.entries(quadrants).map((data, index) => {
                        let timeslot1 = data[1][0].split(" ")
                        let timeslot2 = data[1][1].split(" ")
                        return <div className='d-flex justify-content-between mb-2'>
                            <div className="flight-time">
                                <input onClick={(e) => this.handleFlightTime(data[0], 'departure')} name='departureRadio' type="radio"
                                    checked={this.state.flightTime[this.state.selectedTab ? 'inBound' : 'outBound'].departure === data[0]} />
                                <span>{timeslot1[0]} {t(timeslot1[1])} - {timeslot2[0]} {t(timeslot2[1])}</span>
                            </div>
                            {filtered_metadata &&
                                <span>{filtered_metadata?.duration_based_count[this.state.selectedTab]?.departure[data[0]]}</span>
                            }
                        </div>
                    })}
                    <span className="mb-2 d-block">
                        <b>{t("Arrives to")} {segments[this.state.selectedTab]?.destination}</b>
                    </span>
                    {Object.entries(quadrants).map((data, index) => {
                        let timeslot1 = data[1][0].split(" ")
                        let timeslot2 = data[1][1].split(" ")
                        return <div className='d-flex justify-content-between mb-2'>
                            <div className="flight-time">
                                <input onClick={(e) => this.handleFlightTime(data[0], 'arrival')} name='arrivalRadio' type="radio"
                                    checked={this.state.flightTime[this.state.selectedTab ? 'inBound' : 'outBound'].arrival === data[0]}
                                />
                                <span>{timeslot1[0]} {t(timeslot1[1])} - {timeslot2[0]} {t(timeslot2[1])}</span>
                            </div>
                            {filtered_metadata &&
                                <span>{filtered_metadata?.duration_based_count[this.state.selectedTab]?.arrival[data[0]]}</span>
                            }
                        </div>
                    })}
                </div>
            </>
        )
    }

    deskTopView = () => {
        const { price, duration, durationData, priceData, airlinesData, filtered_metadata } = this.state
        const { searchQueryData, searchResults: { segments }, t } = this.props
        let formattedDuration = '00:00'
        if (duration) {
            formattedDuration = Math.floor(moment.duration(duration, 'seconds').asHours()) + ':' + ('0' + moment.duration(duration, 'seconds').minutes()).slice(-2)
        }
        return (
            <>
                <div className='border d-md-block d-none'>
                    <div className='bg-color-blue2 p-3'>
                        <div className="row">
                            <div className="col-8 border-right">
                                {`${this.props.from} ${t("out of")} ${this.props?.searchResults?.flight_count}`}
                            </div>
                            <div className="col-4 text-center cursor-pointer" onClick={this.handleResetFilter}>
                                {t("RESET")}
                            </div>
                        </div>
                    </div>

                    <Accordion className="w-100" defaultActiveKey="0">
                        <Card className='w-100 border-0'>
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => { this.toggleHandler('first') }}>
                                <div className="d-flex justify-content-between">
                                    <h5 className="fw-bold m-0 p-0">{t("Stops")}</h5>
                                    <img loading='lazy' decoding='async' src={arrowup} alt='arrow' className={this.state.toggle?.first ? 'toggleup' : 'toggledown'} />
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="py-2 px-3">
                                    {this.state.stops && stopsFilters.map((stops, i) =>
                                        <div className="d-flex justify-content-between sidebarinput my-2">
                                            <div className="">
                                                <Form.Check inline onChange={this.handleStops} checked={this.state.stops[stops.value]?.checked} label={t(stops.name)} name={stops.value} type='checkbox' />
                                            </div>
                                            {/* <div>
                                            <span>11,490 {t("Points")}</span>
                                        </div> */}
                                        </div>
                                    )}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                    {/* <Accordion className="w-100" defaultActiveKey="0">
                    <Card className='w-100 border-0'>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => { this.toggleHandler('second') }}>
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold m-0 p-0">Flexible Options</h5>
                                <img loading='lazy' decoding='async' src={arrowup} alt='arrow' className={this.state.toggle?.second ? 'toggleup' : 'toggledown'} />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="p-0">
                                <div className="d-flex sidebarflex">
                                    <div className="mx-3 py-2 col-9 pl-0 flexibleOption">
                                        <Form.Check inline label="No change fees" name="group1" type='checkbox' />
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion> */}
                    <Accordion className="w-100" defaultActiveKey="0">
                        <Card className='w-100 border-0'>
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => { this.toggleHandler('third') }}>
                                <div className="d-flex justify-content-between">
                                    <h5 className="fw-bold m-0 p-0">{t("Times")}</h5>
                                    <img loading='lazy' decoding='async' src={arrowup} alt='arrow' className={this.state.toggle?.third ? 'toggleup' : 'toggledown'} />
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="py-2">
                                    {this.flightTimeView()}

                                    {/* <Card.Body className="py-2"> */}
                                    {/* <div className="fw-bold sideop">
                                    Take-off ({searchQueryData?.travel_origin_code})
                                </div>
                                <div className="d-flex py-1 justify-content-between">
                                    <div className="fw-bold sideop">
                                        {moment(this.state.departureTime[0]).format('ddd')} {moment(this.state.departureTime[0]).format('hh:mm A')}
                                    </div>
                                    <div className="fw-bold sideop">
                                        {moment(this.state.departureTime[1]).format('hh:mm A')}
                                    </div>
                                </div> */}
                                    {/* <div className="d-flex justify-content-between mb-4"> */}
                                    {/* <TimeRangeSlider
                                        disabled={false}
                                        format={24}
                                        maxValue={"23:59"}
                                        minValue={"00:00"}
                                        name={"time_range"}
                                        // onChangeStart={(time) => this.timeChangeHandler(time, 'departureTime')}
                                        // onChangeComplete={(time) => this.timeChangeHandler(time, 'departureTime')}
                                        // onChangeComplete={this.changeCompleteHandler}
                                        onChange={(time) => this.timeChangeHandler(time, 'departureTime')}
                                        step={15}
                                        value={this.state.departureTime}
                                    /> */}

                                    {/* {filtered_metadata?.departure_time_data?.min ?
                                        <TimeRange
                                            // error={error}
                                            ticksNumber={36}
                                            timelineInterval={[new Date(filtered_metadata.departure_time_data.min), new Date(filtered_metadata.departure_time_data.max)]}
                                            selectedInterval={this.state.departureTime}
                                            onUpdateCallback={this.errorHandler}
                                            onChangeCallback={(selectedInterval) => this.timeChangeHandler(selectedInterval, 'departureTime')}
                                            containerClassName="time-range-slider"
                                        /> : null
                                    } */}
                                    {/* </div> */}
                                    {/* <div className="fw-bold pt-3 sideop">
                                    Landing ({searchQueryData?.travel_destination_code})
                                </div>
                                <div className="d-flex py-1 justify-content-between">
                                    <div className="fw-bold sideop">
                                        {moment(this.state.landingTime[0]).format('ddd')} {moment(this.state.landingTime[0]).format('hh:mm A')}
                                    </div>
                                    <div className="fw-bold sideop">
                                        {moment(this.state.landingTime[1]).format('hh:mm A')}
                                    </div>
                                </div> */}
                                    {/* <div className="d-flex justify-content-between mb-5"> */}
                                    {/* <TimeRangeSlider
                                        disabled={false}
                                        format={24}
                                        maxValue={"23:59"}
                                        minValue={"00:00"}
                                        name={"time_range"}
                                        // onChangeStart={this.changeStartHandler}
                                        // onChangeComplete={this.changeCompleteHandler}
                                        onChange={(time) => this.timeChangeHandler(time, 'landingTime')}
                                        step={15}
                                        value={this.state.landingTime}
                                    /> */}
                                    {/* {filtered_metadata?.landing_time_data?.min ?
                                        <TimeRange
                                            // error={error}
                                            ticksNumber={36}
                                            timelineInterval={[new Date(filtered_metadata.landing_time_data.min), new Date(filtered_metadata.landing_time_data.max)]}
                                            selectedInterval={this.state.landingTime}
                                            onUpdateCallback={this.errorHandler}
                                            onChangeCallback={(selectedInterval) => this.timeChangeHandler(selectedInterval, 'landingTime')}
                                            containerClassName="time-range-slider"
                                        /> : null
                                    } */}
                                    {/* </div> */}
                                    <div className="row border-top mt-3 justify-content-between p-2">
                                        <div className="">
                                            <h5 className="fw-bold m-0 p-0">{t("Points")}</h5>
                                        </div>
                                        <div onClick={() => { this.inputRangeHandler(priceData?.max_price_in_points, 'price') }} className="color-blue font-weight-bold cursor-pointer">
                                            <span>{t("Clear All")}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input min={priceData?.min_price_in_points} max={priceData?.max_price_in_points} value={price} onChange={(event) => { this.inputRangeHandler(event.target.value, 'price') }} type="range" className="w-100 form-range" />
                                        </div>
                                        <div className="col-12 sideop fw-bold">
                                            {`${t("Up to")} ${price} ${t("Points")}`}
                                        </div>
                                    </div>
                                    <div className="row border-top mt-3 justify-content-between p-2">
                                        <div className="">
                                            <h5 className="fw-bold m-0 p-0">{t("Duration")}</h5>
                                        </div>
                                        <div className="color-blue font-weight-bold cursor-pointer" onClick={() => { this.inputRangeHandler(durationData?.max, 'duration') }}>
                                            <span>{t("Clear All")}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input type="range" className="w-100 form-range" id="" min={durationData?.min} max={durationData?.max} onChange={(event) => { this.inputRangeHandler(event.target.value, 'duration') }} value={duration} />
                                        </div>
                                        <div className="col-12 sideop">
                                            {t("Under")} {formattedDuration} {t("hours")}
                                        </div>
                                    </div>
                                </Card.Body>

                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                    <Accordion className="w-100" defaultActiveKey="0">
                        <Card className='w-100 border-0'>
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => { this.toggleHandler('fourth') }}>
                                <div className="d-flex justify-content-between">
                                    <h5 className="fw-bold m-0 p-0">{t("Airlines")}</h5>
                                    <img loading='lazy' decoding='async' src={arrowup} alt='arrow' className={this.state.toggle?.fourth ? 'toggleup' : 'toggledown'} />
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="p-0 scroll custom-scrollbar">
                                    {airlinesData?.map(airport => (
                                        <div className="d-flex py-2 sidebarinput">
                                            <div className="col">
                                                <Form.Check inline checked={this.state.airlines[airport.code]} label={airport.name} onChange={(e) => this.handleAirline(e, airport.code)} name="group1" type='checkbox'
                                                    className="color-blue font-weight-bold"
                                                />
                                            </div>
                                        </div>
                                    ))
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
                {/* <div className="flights-tab-results d-flex align-items-center bg-white border px-2 shadow-sm">
                    {
                        TabList.map((data, i) => (
                            <div className={`flights-tab-item ${data.name === this.props.searchOrder ? 'active' : ''}`} key={i} onClick={() => this.changeOrder(data)}>
                                <h5><span>{t(data.tabName)}</span>
                                    {data.icon && <img loading='lazy' decoding='async' src={data.icon} alt="" />}
                                </h5>
                                <span>{filtered_metadata?.fare && filtered_metadata?.fare[data.points]} {t("Points")}</span>
                            </div>
                        ))
                    }
                </div> */}
            </>
        )

    }

    mobileView = () => {
        const { price, duration, airlinesData, filtered_metadata } = this.state
        const { searchQueryData, searchResults: { segments }, t } = this.props
        const customizeNodal = {
            opacity: ".5 !important",
        };
        const flightStop = [["Any", "11,429 its"], ["Direct", "11,429 its"], ["1 Stop", "11,429 its"], ["2 Stop", "15,283 its"]];
        const flightSort = [["Cheapest", "11,429 its"], ["Best", "11,429 its"], ["Quickest", "11,429 its"], ["Earliest", "11,429 its"]];
        const Airports = [
            "DOH Doha",
            "DXB Dubai",
            "ISB Islamabad",
            "IST Istanbul",
            "KHI Karachi",
            "JED Jeddah",
        ];
        const Airlines = [
            "AIr Arabia Abi Dhabi",
            "Egypt Air",
            "Emirates",
            "Etihed Airways",
            "FlyDubai",
            "Gulf Air",
            "Kuwait Airways",
            "Pakisthan Inernational",
        ];
        const FlightsList = [
            {
                flightImage: Emirates,
                returnFlightImage: British,
                flightName: 'Emirates',
                returnFlightName: 'British Airways',
                takeOff: ['12:10 pm', 'DXB'],
                landing: ['4:30 pm', 'LHR'],
                returnFlightTakeOff: ['12:10 pm', 'LHR'],
                returnFlightLanding: ['4:30 pm', 'DXB'],
                totalTime: '7h 10m',
                returnFlightTotalTime: '7h 10m',
                points: '18,490',
            },
            {
                flightImage: British,
                returnFlightImage: British,
                best: 'Best',
                fees: 'No change fees',
                stops: '2 Stops',
                flightName: 'British Airways',
                returnFlightName: 'British Airways',
                takeOff: ['12:10 pm', 'LHR'],
                landing: ['4:30 pm', 'DXB'],
                returnFlightTakeOff: ['12:10 pm', 'LHR'],
                returnFlightLanding: ['4:30 pm', 'DXB'],
                totalTime: '7h 10m',
                returnFlightTotalTime: '7h 10m',
                points: '18,490'
            },
            {
                flightImage: British,
                returnFlightImage: British,
                best: 'Best',
                fees: 'No change fees',
                stops: '2 Stops',
                flightName: 'British Airways',
                returnFlightName: 'British Airways',
                takeOff: ['12:10 pm', 'LHR'],
                landing: ['4:30 pm', 'DXB'],
                returnFlightTakeOff: ['12:10 pm', 'LHR'],
                returnFlightLanding: ['4:30 pm', 'DXB'],
                totalTime: '7h 10m',
                returnFlightTotalTime: '7h 10m',
                points: '18,490'
            },
            {
                flightImage: British,
                returnFlightImage: British,
                best: 'Best',
                fees: 'No change fees',
                stops: '2 Stops',
                flightName: 'British Airways',
                returnFlightName: 'British Airways',
                takeOff: ['12:10 pm', 'LHR'],
                landing: ['4:30 pm', 'DXB'],
                returnFlightTakeOff: ['12:10 pm', 'LHR'],
                returnFlightLanding: ['4:30 pm', 'DXB'],
                totalTime: '7h 10m',
                returnFlightTotalTime: '7h 10m',
                points: '18,490'
            },
            {
                flightImage: British,
                returnFlightImage: British,
                best: 'Best',
                fees: 'No change fees',
                stops: '2 Stops',
                flightName: 'British Airways',
                returnFlightName: 'British Airways',
                takeOff: ['12:10 pm', 'LHR'],
                landing: ['4:30 pm', 'DXB'],
                returnFlightTakeOff: ['12:10 pm', 'LHR'],
                returnFlightLanding: ['4:30 pm', 'DXB'],
                totalTime: '7h 10m',
                returnFlightTotalTime: '7h 10m',
                points: '18,490'
            },
        ];
        return (
            <div className=" d-md-none d-block">
                {this.state.stopModal ?
                    <FilterModal
                        type={"Stops"}
                        stops={this.state.stops}
                        stopsFilters={stopsFilters}
                        handleStops={this.handleStops}
                        filterData={flightStop}
                        clear={this.clearFilter}
                        sort={this.handleSort}
                        show={this.state.stopModal}
                        style={customizeNodal}
                        showModal={this.closeModal}
                        handleOk={this.handleOkStop}
                    /> : null
                }

                {this.state.times ?
                    <FlightTimeModal
                        filtered_metadata={filtered_metadata}
                        segments={segments}
                        show={this.state.times}
                        showModal={this.closeModal}
                        handleOk={this.handleOkFlightTime}
                        quadrants={quadrants}
                        flightTime={this.state.flightTime}
                        selectedTab={this.state.selectedTab}
                    /> : null
                }

                <FilterModal
                    type={"Sort"}
                    searchOrder={this.props.searchOrder}
                    searchResults={this.props.searchResults}
                    filterData={flightSort}
                    clear={this.clearFilter}
                    sort={this.handleSort}
                    show={this.state.sortModal}
                    style={customizeNodal}
                    showModal={this.closeModal}
                    handleOk={this.handleOkSort}
                />
                {/* <AirportAirlines
                    title={"Airports"}
                    show={this.state.showAirport}
                    showModal={this.closeModal}
                    shopover={Airports}
                    type={"airports"}
                    clear={this.clearFilter}
                    sort={this.handleSort}
                /> */}
                {this.state.showAirlines ?
                    <AirportAirlines
                        title={"Airlines"}
                        airlines={this.state.airlines}
                        airlinesData={airlinesData || []}
                        show={this.state.showAirlines}
                        showModal={this.closeModal}
                        shopover={Airlines}
                        type={"airlines"}
                        handleOk={this.handleOkAirline}
                    // clear={this.clearFilter}
                    // sort={this.handleSort}
                    /> : null
                }
                <TravelDetailTopBar takeOff={searchQueryData?.travel_origin_code} landing={searchQueryData?.travel_destination_code} desc={`${moment(searchQueryData?.startDate).format('DD MMM')} - ${moment(searchQueryData?.endDate).format('DD MMM')} | ${searchQueryData?.passengerClass}`} handleSearchForm={this.props.handleSearchForm} />
                <div className="d-flex border-bottom" style={{ overflowX: "auto" }}>
                    <button
                        onClick={() => {
                            // this.filterHandler("Sort");
                            this.setState({ sortModal: true });
                        }}
                        className={`${this.state.sort === true ? " filterButton-clicked" : ""
                            } btn filterButton mx-2 my-3 px-3 py-1 `}
                    >
                        <img loading='lazy' decoding='async' src={SortIcon} className="mr-2" alt="" /> {t("Sort")}
                    </button>
                    {/* <button
                        onClick={() => this.filterHandler("flexible")}
                        className={`${this.state.flexible === true ? " filterButton-clicked" : ""
                            } btn filterButton mx-2 my-3 px-3 py-1 `}
                    >
                        Flexible
                    </button> */}
                    <button
                        onClick={() => {
                            // this.filterHandler("stops");
                            this.setState({ stopModal: true });
                        }}
                        className={`${this.state.stops === true ? " filterButton-clicked" : ""
                            } btn filterButton mx-2 my-3 px-3 py-1 `}
                    >
                        {t("Stops")}
                    </button>
                    <button
                        onClick={() => this.filterHandler("times")}
                        className={`${this.state.times === true ? " filterButton-clicked" : ""
                            } btn filterButton mx-2 my-3 px-3 py-1 `}
                    >
                        {t("Times")}
                    </button>
                    {/* <button
                        // onClick={() => this.filterHandler("airports")}

                        onClick={() => {
                            // this.filterHandler("Sort");
                            this.setState({ showAirport: true });
                        }}
                        className={`${this.state.airports === true ? " filterButton-clicked" : ""
                            } btn filterButton mx-2 my-3 px-3 py-1 `}
                    >
                        Airports
                    </button> */}
                    <button
                        // onClick={() => this.filterHandler("airports")}

                        onClick={() => {
                            // this.filterHandler("Sort");
                            this.setState({ showAirlines: true });
                        }}
                        className={`${this.state.airlines === true ? " filterButton-clicked" : ""
                            } btn filterButton mx-2 my-3 px-3 py-1 `}
                    >
                        {t("Airlines")}
                    </button>
                </div>
                <div className="flights-result-data px-3 pt-4">
                    <h5 className="color-blue">{t("Select result flight to")} {this.props.searchResults?.segments[0]?.destination}</h5>
                    <p className="mb-0">{t(`Average ${this.props.searchResults?.segments?.length > 1 ? 'round' : 'one way'} price trip per passenger`)}</p>
                    <span>{t("Showing")} {`${this.props.searchResults?.search?.length} ${t("out of")} ${this.props?.searchResults?.flight_count}`} {t("Flights")} | {t("The price includes taxes and fees")}</span>
                </div>
                {/* <div className="px-3" style={{ paddingBottom: 80 }}>
          {
            FlightsList && FlightsList.length > 0 && FlightsList.map((data, i) => (
              <TravelShopFlightsBox
                className={data.className}
                key={i}
                flightImage={data.flightImage}
                returnFlightImage={data.returnFlightImage}
                best={data.best}
                fees={data.fees}
                stops={data.stops}
                flightName={data.flightName}
                returnFlightName={data.returnFlightName}
                takeOff={data?.takeOff}
                landing={data?.landing}
                returnFlightTakeOff={data?.returnFlightTakeOff}
                returnFlightLanding={data?.returnFlightLanding}
                totalTime={data.totalTime}
                returnFlightTotalTime={data.returnFlightTotalTime}
                points={data.points}
              />
            ))
          }
        </div> */}
                <div className="px-3" style={{ paddingBottom: 80 }}>
                {this.props.searchResults?.filtered_metadata ?
                                    this.props.searchResults?.search?.length ?
                                        <TravelShopDetails fetchSearch={this.props.fetchSearch} handleViewDetail={this.props.handleViewDetail} viewCount={this.props.viewCount} paginate={this.props.paginate} mobileDevice={this.props.mobileDevice}
                                        />
                                        : <div className="flights-box border text-center p-5">{t("No flight data was found for the applied filters!")}</div>
                                    : null
                                }
                    {/* {
                        this.props.searchResults?.search.length ?
                            this.props.searchResults?.search.map((data, i) => (
                                <TravelShopFlightsBox
                                    data={data}
                                    segments={this.props.searchResults.segments}
                                    className={data.className}
                                    key={i}
                                    flightImage={data.flightImage}
                                    returnFlightImage={data.returnFlightImage}
                                    best={data.best}
                                    fees={data.fees}
                                    stops={data.stops}
                                    flightName={data.flightName}
                                    returnFlightName={data.returnFlightName}
                                    takeOff={data?.takeOff}
                                    landing={data?.landing}
                                    returnFlightTakeOff={data?.returnFlightTakeOff}
                                    returnFlightLanding={data?.returnFlightLanding}
                                    totalTime={data.totalTime}
                                    returnFlightTotalTime={data.returnFlightTotalTime}
                                    points={data.points}
                                    handleViewDetail={this.props.handleViewDetail}
                                />
                            ))
                            : <div className="flights-box border text-center p-5 mt-3">No flight data was found for the applied filters!</div>
                    } */}
                </div>
            </div>)
    }

    render() {
        return (
            <>
                {this.deskTopView()}
                {this.mobileView()}
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        searchResults: state.travel.searchResults,
        searchQueryData: state.travel?.searchQueryData,
        searchOrder: state.travel.searchOrder,
        filters: state.travel.filters,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSearchOrder: (data) => dispatch(setSearchOrder(data)),
        setFilterData: (data) => dispatch(setFilterData(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TravelFilter)));
