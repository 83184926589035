import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import infoCircle from '../../assests/img/info.svg'
import { connect } from 'react-redux';
import SuccessfullMessageModal from '../shared/Modals/SuccessfulMessageModal';
import { pointsToCurrancy} from '../../utils/pointsConversion';
import PointExchangeButton from '../shared/PointExchangeButton';
import PointsConfirmationModal from '../shared/Modals/PointsConfirmationModal';
import axios from 'axios';
import secretKeyGenerator from '../../utils/serectKeyGenerator';
import { REACT_APP_baseUrl_V2 } from 'utils/app-constants';

class PointsExchangeFormReviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            card:{...this.props.location.state.card},
            isLoaded:true,
            identifiactionNumber:this.props.location.state.identifiactionNumber,
            hassadPoints:this.props.location.state.hassadPoints,
            rewardPoints:this.props.location.state.rewardPoints,
            confirmOrder:false,
            quantity:1,
            giftcard_value:100,
            orders:{},
            showModal:false,
            data:{},
            TermsCondition:undefined
         }
    }
    componentDidMount(){
        localStorage.removeItem("type");
        localStorage.setItem("type", "pointsExchange");
        const payload ={
            "brands": {
                "id": this.props.match.params.id,
                "currency": this.props.countrySelected
            }
        }
        axios.post(`${REACT_APP_baseUrl_V2}/brands/get_point_exchange_brand_details`,
            payload,{
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
                .then(response  => {
                    if(response.data.code === 200){
                        
                        this.setState({
                            TermsCondition : [response.data.data?.brand?.terms, response.data.data?.brand?.terms_arabic]
                        })
                    }
                })
               
    }

    componentDidUpdate(prevProps,prevState){
        if(( prevProps.currancyCode !== this.props.currancyCode && this.props.countrySelected !== 2) ){
            this.props.history.push({
                pathname:"/404",
                redirectionURL:"/points-exchange"
            })
        }
      }
    alFursaTermsCondition=[
        'Customer is responsible for getting the correct member ID.',
        'Miles will be added within 10 business days.',
        ',Al Fursan and MYList is not responsible for entering the wrong member ID',
        'AL Fursan will send you an SMS when the miles are added to your account.'
    ]
    nequatyTermsCondition=[
        'Customer is responsible for getting the correct mobile number.',
        ',Neqaty and MYList are not responsible if an incorrect mobile number is entered.',
        'Neqaty will send you an SMS when the points are added to your account.'
    ]
    orderSummary=(val)=>{
        let amount = pointsToCurrancy(this.state.hassadPoints)
        let amount_points = this.state.hassadPoints
        this.setState(prevState=>({
            confirmOrder:val,
            orders: {
                "brand_id":this.state.card.id,
                "quantity":prevState.quantity,
                "giftcard_value": this.state.giftcard_value,
                "amount":amount, //SAR or Points?
                "program_id":1,
                "isforself":true,
                "use_hassad_points":this.props.pointBalance>0,
                "language_code":this.props.lang,
                "currency":2,
                "hassad_points": amount_points <= this.props.pointBalance ? amount_points: this.props.pointBalance,
            },
            data:{
                "brand_id": this.state.card.id,
                "points_exchange_member_id":this.props.location.state.identifiactionNumber,
                "hassad_points": this.props.location.state.hassadPoints,
                "language_code": this.props.lang,
                "currency": 2,
                "program_id": 1
            }
        }))
       
    }
    
    

    render() {   
        const {t} = this.props
    const {confirmOrder,orders,card,data,isLoaded,identifiactionNumber,rewardPoints,hassadPoints,TermsCondition} = this.state
    return ( 
       
        <div>
            
            
            {/* <ProcessingModal/> */}
            <SuccessfullMessageModal/>
            {
                    isLoaded?
                    <div className="p-md-5 p-3 d-flex flex-md-row flex-column">
                            {   
                                
                                confirmOrder && <PointsConfirmationModal lang={this.props.lang} orders={orders} data={data} card={card} show={confirmOrder} remove={this.orderSummary}/>
                            }
                             <div className="col-3 p-0 d-md-block d-none">
                                    <img  
                                        className="img-fluid p-0 mx-auto d-block"   
                                        src={card.images?.color?.large_rectangle}
                                                alt={card.name}
                                    />
                            </div>
                            <div className="flex-grow-1 px-md-5 px-0">
                <div className="pb-md-3 pb-2">
                    <div className="fs-md-3 fs-5 fw-md-bold fw-bolder color-blue">
                    { this.props.lang !== 'ar' ? card.name:card.name_arabic }
                    </div>
                    {/* <div className='text-black-50 m-0 py-1 fs-md-5 fs-6 fw-bold'>
                    { this.props.lang === 'en' ? card.name:card.name_arabic }
                    </div> */}
                </div>
                <div className="fs-5 fw-bold text-dark">
                    {rewardPoints}
                    <small>{t("Points")}</small>
                </div>
                <div className='d-md-none d-block p-3'>
                    <img 
                        className="mx-auto d-block"
                        src={card.images?.color?.large_rectangle}
                        alt={card.name}
                        style={{objectFit:'cover',height:'40vw',maxWidth:'100%'}}/>
                </div>
                <hr className="hr-line my-2"/>
                
                <div className=" px-4">
                        <h6 className="bg-light-gray px-3 py-3 m-0 w-100 text-center">
                            <img className="img-fluid mt-n1 pt-1 float-left" style={{height:'1.6rem'}} src={infoCircle} alt=''/>
                            {
                                card.name ==='Neqaty'?
                               <> {t("Please review and confirm the details to redeem your Rewards Points to ")} { this.props.lang !== 'ar' ? card.name:card.name_arabic } {card.rewardType}{t("Points")}</>
                                :
                                <>{t("Please review and confirm the details to redeem your Rewards Points to ")}  { this.props.lang !== 'ar' ? card.name:card.name_arabic } {card.rewardType} {t("Miles")}</>
                            }
                           {/* {t("Please Review your confirm the details to redeem your Rewards Points to")}  { this.props.lang === 'en' ? card.name:card.name_arabic } {card.rewardType} */}
                        </h6>

                        <div className="py-3" style={{width:'20rem'}}>
                            <h6 className="m-0 p-0 row py-2">
                                <span className='text-muted col p-0 m-0'>{t("Transfer To")}:</span>
                                {/* <span className='col p-0'> { this.props.lang === 'en' ? card.name:card.name_arabic } {card.rewardType}</span> */}
                                {
                                   card.name==='Neqaty'?
                                   <> <span className='col p-0'> { this.props.lang !== 'ar' ? card.name:card.name_arabic } {card.rewardType} {t("Points")}</span></>
                                   :<> <span className='col p-0'> { this.props.lang !== 'ar' ? card.name:card.name_arabic } {card.rewardType} {t("Miles")}</span></>
                               }
                            </h6>
                            {
                                card.name === "AL FURSAN " ?
                                <h6 className="m-0 row py-2">
                                    <span className='text-muted col-6 p-0 m-0'>{t("Member ID")}:</span>
                                    <span className='col p-0'>{identifiactionNumber}</span>
                                </h6>:
                                <h6 className="m-0 row py-2">
                                    <span className='text-muted col-6 p-0 m-0'>{t("Mobile Number")}:</span>
                                    <span className='col p-0'>{identifiactionNumber}</span>
                                </h6>

                            }
                             <h6 className="m-0 row py-2">
                             {
                                   card.name==='Neqaty'?
                                   <>  <span className='text-muted col-6 p-0 m-0'>{this.props.lang!=='ar'?card.name:card.name_arabic} {card.rewardType} {t("Points")}: </span></>
                                   :<> <span className='text-muted col-6 p-0 m-0'> { this.props.lang !== 'ar' ? card.name:card.name_arabic } {card.rewardType} {t("Miles")}:</span></>
                               }
                                <span className='col p-0'>{rewardPoints}</span>
                            </h6>
                        </div>
                        <hr className="hr-line my-2"/>
                        <div className="py-3" style={{width:'20rem'}}>
                            <h6 className="m-0 p-0 py-2 row ">
                                <span className='text-muted col p-0 m-0'>{t("Transfer From")}:</span>
                                <span className='col p-0'>{t("Rewards Points")}</span>
                            </h6>
                            <h6 className="m-0 p-0 py-2 row ">
                                <span className='text-muted col p-0 m-0'>{t("Rewards Points")}:</span>
                                <span className='col p-0'>{hassadPoints}</span>
                            </h6>
                        </div>
                        <hr className="hr-line my-2"/>
                        <div>
                            <h4 className='m-0 color-blue py-2'>{t("Terms & Conditions")}</h4>
                            <div className="text-muted py-2">
                            {TermsCondition && 
                            TermsCondition?.[this.props.lang==="ar" ? 1 : 0]?.split("\n")
                            .map(terms=> 
                                <div>
                                {terms}
                            </div>
                         )}
                            </div>
                        </div>
                        <hr className="hr-line my-4"/>
                        
                        
                    </div>
                </div>
                    <PointExchangeButton onSubmit={this.orderSummary}/>
                    
                </div>:null
                }
                
        </div>
         );
    }
}

const mapStateToProps = state => {
    return {
        countrySelected:Number(state.countries.countryId),
        pointBalance:state.point.pointBalance,
        currancyCode:state.countries.convertionRate.currency_code,
    }
}

export default connect(mapStateToProps)(withTranslation()(PointsExchangeFormReviewPage));