import rewardsBy from "../../assests/img/header/logo2.svg"
import enfactem from "../../assests/img/header/enfactemlogo.png"
import merit from "../../assests/img/Logos/Meritlogo.svg"


export function logoHandler() {
  let domain = window.location?.origin; 
  if(domain === "http://localhost:3000") return rewardsBy
  else if (domain === "https://enftest.develop-rewardsby-marketplace.meritincentives.com" || domain === "https://enftest.staging-rewardsby-marketplace.meritincentives.com" || domain === "https://enfactum.marketplace.rewardsby.com") return enfactem
  else if (domain === "https://merit.staging-rewardsby-marketplace.meritincentives.com" || domain === "https://demo-marketplace.meritincentives.com" || domain === "https://prod.marketplace.meritincentives.com") return merit
  else return rewardsBy
}
