import React, { Component } from "react";
import ModalWrapper from "./ModalWrapper";
import { ReactComponent as Login } from "../../../assests/img/Login/reward.svg";
import { ReactComponent as Login1 } from "../../../assests/img/Login/login.svg";
import { ReactComponent as Email } from "../../../assests/img/Login/check-email.svg";
import { ReactComponent as Reset } from "../../../assests/img/Login/reset-password.svg";
import Password from "../../../assests/img/Login/password.svg";
import User from "../../../assests/img/Login/user.svg";
import Cancel from "../../../assests/img/Login/cancel-btn.svg";
import { withTranslation } from "react-i18next";
import { checkLoginFlow, fetchAuthToken } from "../../../Redux/auth/authActions";
import { connect } from "react-redux";
import { FORGET_PASSWORD_FAILURE } from "Redux/ForgetPassword/ForgetPassTypes";
import { ForgetPassEmail } from "../../../Redux/ForgetPassword/ForgetPassAction";
import SignUp from "../../SignUp/SignUp";
import axios from "axios";
import { REACT_APP_baseUrl } from "utils/app-constants";
import { fetchPointBalance } from "Redux/point/pointActions";
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_RECAPTCHA_SITE_KEY } from "utils/app-constants";
import {Link} from "react-router-dom"
import store from "Redux/store";
import { LOGIN_FLOW_SUCCESS } from "Redux/auth/authTypes";

class MainLoginModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isForgetPass: false,
      email: this.props.email || "",
      password: "",
      forgetPasswordEmailErr: true,
      forgetMail: "",
      emailerror: "",
      passworderror: "",
      invaliderror: "",
      isLogin: this.props.isLogin,
      tokenApiLoading: false,
      giftcardno: "",
      firstname: "",
      lastname: "",
      email: "",
      isRedeming: false,
      isCaptchaVerified: false,
      isNext:false,
      isSSOLogin:true
    };
    this.captchaRef = React.createRef();
  }

  checkEmailValidation = (value) => {
    this.setState({ forgetMail: value });
    const validEmail = new RegExp(
      "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"
    );
    let error = {};
    if (!validEmail.test(value)) {
      this.setState({ forgetPasswordEmailErr: true });
      error["error"] = "Please enter a valid email";
    } else {
      this.setState({ forgetPasswordEmailErr: false });
    }
    return error;
  };

  CancelFun = () => {
    this.setState({ isForgetPass: false });
    if (this.props.sendInstrution) {
      this.props.EMailSend();
    }
  };

  isLoginFun = () => {
    this.setState({ isLogin: !this.state.isLogin });
  };

  next= (isSSO) =>{
   this.setState({
      isNext:true,
    })
  }

  handleNext = () =>{
    let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (this.state.email === "") {
      return this.setState({
        emailerror: "Required",
      });
    }
    if (!this.state.email.match(emailregex)) {
      return this.setState({
        emailerror: "Enter correct email address",
      });
    }
    this.props.checkLoginFlow(this.state.email, this.next)
  }

  handleRedirect = () =>{
    window.location.href = this.props.loginFlow?.login_link
  }

  login = () => {
    let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (this.state.email == "") {
      this.setState({
        emailerror: "Required",
      });
    } else if (this.state.password == "") {
      this.setState({
        passworderror: "Required",
      });
    } else if (!this.state.email.match(emailregex)) {
      this.setState({
        emailerror: "Enter correct email address",
      });
    } else {
      const ref = this.captchaRef;
      if (ref.current != null) {
        const captchaToken = ref.current.getValue();
        if (captchaToken === null || captchaToken === "") {
          this.setState({
            invaliderror: "Please confirm you are not a robot",
          });
        } else {
          let signin = {
            email: this.state.email,
            password: this.state.password,
          };
          this.props.fetchAuthToken(
            signin,
            this.redemptionHandler,
            false,
            this.captchaRef,
            this.props.toogleLang
          );
        }
      }
    }
  };

  handleCaptchaChange = () => {
    const ref = this.captchaRef;
    if (ref.current != null) {
      const captchaToken = ref.current.getValue();
      if (captchaToken === null || captchaToken === "") return;
      this.setState({
        isCaptchaVerified: true,
      });
    }
  };

  componentDidMount() {
    this.fetchTokenDetails();
  }

  fetchTokenDetails = () => {
    if (this.props.token != "" && this.props.token != undefined) {
      localStorage.setItem("redeemToken", this.props.token)
      this.setState({
        tokenApiLoading: true,
      });
      axios
        .get(`${REACT_APP_baseUrl}/giftcards/fetch_giftcard`, {
          params: {
            token: this.props.token,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code === 400) {
              this.props.redeemPopShow(
                "",
                false,
                this.state.isLogin,
                res.data.message
              );
            }

            if (res.data.data.giftcard.remaining_value > 0) {
              this.setState({
                firstname: res.data.data.giftcard.first_name,
                lastname: res.data.data.giftcard.last_name,
                giftcardno: res.data.data.giftcard.number,
                email: res.data.data.giftcard.email,
                tokenApiLoading: false,
                isRedeming: true,
              });
            } else {
              this.setState({
                tokenApiLoading: false,
                isLogin: true,
                isRedeming: true,
                giftcardno: res.data.data.giftcard.number,
                email: res.data.data.giftcard.email,
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({
        tokenApiLoading: false,
        isRedeming: false,
        isLogin: true,
      });
    }
  };

  addAnchorTag(inputString) {
    const text = "Click here"
    if (inputString.includes(text)) {
      const indexOfClick = inputString?.indexOf(text)
      const Route = <Link to="#" onClick={() =>
        this.setState({ isForgetPass: !this.state.isForgetPass })
      }>{text}</Link>
       return <>
       {inputString?.substring(0, indexOfClick)} {Route} {inputString?.substring(indexOfClick+text?.length)}
       </>
      
    } else {
      return this.props.t(inputString);
    }

  }

  componentDidUpdate(prevProps) {
    // if (this.props.valid && prevProps.valid == false) {
    //     console.count()
    //     this.props.loginFun();
    //     console.log(this.props.loginFun);
    // }
    if ((this.props.invalid && prevProps.invalid === undefined) || (this.props.invalid && prevProps.authErroMsg !== this.props.authErroMsg)) {
      this.setState({
        invaliderror: this.props.authErroMsg || "Email or password is incorrect",
      });
    }
  }

  redemptionHandler = () => {
    if (this.state.isRedeming) {
      localStorage.removeItem("redeemToken")
      axios
        .post(
          `${REACT_APP_baseUrl}/giftcards/add_mylist_card`,
          {
            mylist_card: {
              card_number: this.state.giftcardno,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.props.authToken}`,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.setState({
              isRedeming: false,
            });
            this.props.fetchPointBalance(localStorage.getItem("userToken"));
            this.props.loginFun();
            this.props.redeemPopShow(
              this.state.giftcardno,
              true,
              this.state.isLogin
            );
          } else if (res.data.code == 400) {
            this.setState({
              isRedeming: false,
            });
            this.props.fetchPointBalance(localStorage.getItem("userToken"));
            this.props.loginFun();
            this.props.redeemPopShow(
              this.state.giftcardno,
              false,
              this.state.isLogin,
              res.data?.message
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.props.loginFun();
    }
  };

  render() {
    const Loader = () => {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    };

    const { loginFun, show, lang, t, loginFunClose } = this.props;

    const { isForgetPass, forgetPasswordEmailErr, forgetMail, isLogin } =
      this.state;
    return (
      <>
        {this.state.tokenApiLoading && show ? (
          <div
            style={{
              height: "500px",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <ModalWrapper show={show}>
              <div className="bg-white mx-auto LoginModel">
                {!this.state.isRedeming ? (
                  <>
                    <div
                      className={`position-relative d-md-block d-none ${
                        lang === "ar" ? "cross3" : "cross1"
                      }`}
                    >
                      <img
                        src={Cancel}
                        alt="cancel"
                        className="img-fluid cancel"
                        onClick={() => {
                          loginFun();
                          this.CancelFun();
                          store.dispatch({
                            type: LOGIN_FLOW_SUCCESS,
                            payload: {
                              provider:"",
                              login_link:"",
                              login_flow:"SSO"
                            }
                          })
                        }}
                      />
                    </div>
                    <div
                      className={`position-relative d-md-none d-block ${
                        lang === "ar" ? "cross4" : "cross2"
                      } `}
                    >
                      <img
                        src={Cancel}
                        alt="cancel"
                        className="img-fluid cancel"
                        onClick={() => {
                          loginFun();
                          this.CancelFun();
                        }}
                      />
                    </div>
                  </>
                ) : null}
                <div
                  className={
                    this.state.isRedeming ? "text-center pt-4" : "text-center"
                  }
                >
                  {this.state.isRedeming ? (
                    <>
                      <div className="reward-no rounded mb-4">
                        <div className="reward-img">
                          <Login />
                        </div>
                        <div className="reward-card">
                          <span>
                            {t("Reward No")}: {this.state.giftcardno}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : this.props.sendInstrution ? (
                    <div className="check-mail">
                      <Email />
                    </div>
                  ) : isForgetPass ? (
                    <Reset />
                  ) : (
                    <Login1 />
                  )}
                  {isLogin ? (
                    <>
                      <h1
                        className="muted-color fw-bolder mb-1"
                        style={{ fontSize: "20px" }}
                      >
                        {this.props.sendInstrution
                          ? t("Check Your Email")
                          : !isForgetPass
                          ? t("Login to your Account")
                          : t("Reset Password")}
                      </h1>
                      <p className="fs-7 muted-color">
                        {this.props.sendInstrution
                          ? t(
                              "We have sent you password recover instructions to your email"
                            )
                          : !isForgetPass
                          ? t("Enter to continue and explore within your graps")
                          : t(
                              "Enter your email associate to your account and we will an email with The instructions to reset your password."
                            )}
                      </p>
                      {!this.props.sendInstrution && (
                        <div className="d-flex flex-column LoginIcon">
                          {!isForgetPass ? (
                            <>
                            {
                              !this.state?.isNext ? 
                              <>
                                <div className="my-1 position-relative">
                                  <img
                                    src={User}
                                    alt="user"
                                    className={`img-fluid position-absolute ${
                                      lang === "ar" ? "img2" : ""
                                    }`}
                                  />
                                  <input
                                    className={`w-100 login-input outline-color-skyblue ${
                                      lang === "ar" ? "input2" : ""
                                    }`}
                                    type="text"
                                    name=""
                                    id=""
                                    value={this.state.email}
                                    placeholder={t("Enter Email")}
                                    onChange={(e) => {
                                      this.setState({
                                        email: e.target.value,
                                        emailerror: "",
                                      });
                                    }}
                                  />
                                </div>
                                <p className="text-danger text-left">
                                  {t(this.state.emailerror)}
                                </p>
                              </>
                              : this.props.loginFlow?.login_flow !== "SSO" && <>
                              <div className="my-2 position-relative">
                                <img
                                  src={Password}
                                  alt="password"
                                  className={`img-fluid position-absolute ${
                                    lang === "ar" ? "img2" : ""
                                  }`}
                                />
                                <input
                                  className={`w-100 login-input outline-color-skyblue ${
                                    lang === "ar" ? "input2" : ""
                                  }`}
                                  type="password"
                                  name=""
                                  id=""
                                  placeholder={t("Enter Password")}
                                  onChange={(e) => {
                                    this.setState({
                                      password: e.target.value,
                                      passworderror: "",
                                    });
                                  }}
                                />
                              </div>
                              <p className="m-0 text-danger text-left">
                                {t(this.state.passworderror)}
                              </p>
                              
                              </>
                            }
                              {
                                !this.props.loading &&
                              <p className="m-0 text-danger text-left">
                                {this.addAnchorTag(this.state.invaliderror)}
                              </p>
                              }
                            </>
                          ) : (
                            <div className="my-2 position-relative">
                              <img
                                src={User}
                                alt="email"
                                className={`img-fluid position-absolute ${
                                  lang === "ar" ? "img2" : ""
                                }`}
                              />
                              <input
                                className={`w-100 login-input outline-color-skyblue ${
                                  lang === "ar" ? "input2" : ""
                                }`}
                                type="text"
                                name="forgetMail"
                                value={forgetMail}
                                placeholder={t("Enter Email")}
                                onChange={(e) =>
                                  this.checkEmailValidation(e.target.value)
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {!this.props.sendInstrution && (
                        <>
                          {
                            this.props.loginFlow?.login_flow !== "SSO" && 
                            <ReCAPTCHA
                              onExpired={() => this.captchaRef.current.reset()}
                              sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                              onChange={this.handleCaptchaChange}
                              ref={this.captchaRef}
                            />
                          }
                          {
                            this.state.isNext && this.props.loginFlow?.login_flow !== "SSO" &&
                            <p
                              className="text-start mb-1 mt-3 mb-3 cursor-pointer Forget-Password"
                              style={{ color: "black" }}
                              onClick={() =>
                                this.setState({ isForgetPass: !isForgetPass })
                              }
                            >
                              {!isForgetPass 
                                ? t("Forgot Password?")
                                : t("Go Back")}
                            </p>
                          }
                        </>
                      )}
                      <div className="text-center mb-2">
                        {this.props.sendInstrution ? (
                          <button
                            className="btn btn-sky text-light w-100 "
                            onClick={() => {
                              loginFun();
                              this.CancelFun();
                            }}
                          >
                            {t("OK")}
                          </button>
                        ) : isForgetPass ? (
                          <button
                            className="btn btn-sky text-light w-100"
                            disabled={
                              forgetPasswordEmailErr ||
                              this.state?.isCaptchaVerified === false
                            }
                            onClick={() => {
                              this.props.ForgetPassEmail(
                                forgetMail,
                                this.captchaRef
                              );
                            }}
                          >
                            {this.props.loading2 ? (
                              <Loader />
                            ) : (
                              t("Send Instructions")
                            )}
                          </button>
                        ) : this.props.loading ? (
                          <Loader />
                        ) : (
                          <button
                            className="btn btn-sky text-light w-100 "
                            onClick={() => {
                              !this.state.isNext ? this.handleNext() : this.props.loginFlow?.login_flow === "SSO" ? this.handleRedirect() : this.login();
                            }}
                          >
                            {t(!this.state.isNext ? "Next"  : this.props.loginFlow?.login_flow === "SSO" ? `Login With ${this.props.loginFlow?.provider}` : "Login")}
                          </button>
                        )}
                      </div>
                      {!isForgetPass &&
                        !this.props.loading &&
                        this.props.isRedeming && (
                          <p>
                            {t("Don't have an account ?")}{" "}
                            <span
                              className="cursor-pointer login-color"
                              onClick={this.isLoginFun}
                            >
                              {t("Sign up")}
                            </span>
                          </p>
                        )}
                    </>
                  ) : (
                    <SignUp
                      isLogin={this.isLoginFun}
                      lang={lang}
                      closeModal={this.redemptionHandler}
                      firstname={this.state.firstname}
                      lastname={this.state.lastname}
                      email={this.state.email}
                    />
                  )}
                </div>
              </div>
            </ModalWrapper>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    valid: state.auth.valid,
    invalid: state.auth.invalid,
    loading2: state?.ForgetPassReducer?.loading,
    sendInstrution: state?.ForgetPassReducer?.sendInstrution,
    authToken: state.auth.authToken,
    authErroMsg: state.auth.authErroMsg,
    loginFlow : state?.auth?.LoginFlow

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAuthToken: (payload, cb, flag, recaptcha, toogleLang) =>
      dispatch(fetchAuthToken(payload, cb, flag, recaptcha, toogleLang)),
    ForgetPassEmail: (email, recaptcha) =>
      dispatch(ForgetPassEmail(email, recaptcha)),
    EMailSend: () => dispatch({ type: FORGET_PASSWORD_FAILURE }),
    fetchPointBalance: (val) => dispatch(fetchPointBalance(val)),
    checkLoginFlow: (val, cb) => dispatch(checkLoginFlow(val, cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(MainLoginModel)));
