import moment from 'moment';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import arrowLeftRight from "../../assests/img/TravelShop/arrow-left-right.svg";
import { ReactComponent as PlaneUp } from "../../assests/img/TravelShop/plane-up.svg";
import { ReactComponent as PlaneDown } from "../../assests/img/TravelShop/plane-down.svg";
import { ReactComponent as PlaneDownRight } from "../../assests/img/TravelShop/plane-down-right.svg";
import { ReactComponent as Clock } from "../../assests/img/TravelShop/clock.svg";
import { ReactComponent as Seat } from "../../assests/img/TravelShop/seat.svg";
import { ReactComponent as Boeing } from "../../assests/img/TravelShop/boeing.svg";
import { ReactComponent as Baggage } from "../../assests/img/TravelShop/baggage.svg";
import { ReactComponent as Meal } from "../../assests/img/TravelShop/meal.svg";
import { Accordion, Card } from 'react-bootstrap';
import arrowup from "../../assests/img/TravelShop/arrow-up.svg";
import ConfirmationStepFlight from './ConfirmationStepFlight';
import StepFlight from './StepFlight';


class ConfirmationDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            flightSegments: [],
            toggle: {
                first: true,
                second: true,
                third: true,
                fourth: true,
                flightDetails: { outBoundFlight: [], inBoundFlight: [] },
              },
        }
    }
    flightDetails = () => {
        const flightSegments = this.props.flightDetails?.segments?.reduce((result, segment) => {
          if (segment.segment_indicator === 1) {
            result["outBoundFlight"].push(segment)
          } else {
            result["inBoundFlight"].push(segment)
          }
          return result
        }, { outBoundFlight: [], inBoundFlight: [] })
        this.setState({flightSegments:flightSegments})
      }

    componentDidMount(){
        this.flightDetails()
    }
    numberWithCommas = (x) => {
        let num = +x;
        if(typeof x === 'number'){
          let y = num.toFixed(0);
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else{return 0}
      };
      numberWithCom = (x) => {
        let num = +x;
        let y = num.toFixed(2);
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

      toggleHandler = (type) => {
        this.setState({
          toggle: { ...this.state.toggle, [type]: !this.state.toggle[type] },
        });
      };
    render() {
        const {flightDetails,searchResults,searchQueryData,t,total} = this.props
        const {outBoundFlight,inBoundFlight} = this.state.flightSegments || [];
        const flightOptions = [
            {
              icon: <Clock />,
              name: "07h 35m",
            },
            {
              icon: <Seat />,
              name: "Economy",
            },
            {
              icon: <Boeing />,
              name: "Boeing 777-300ER ( EK 5 )",
            },
            {
              icon: <Baggage />,
              name: "Baggage (30KG)",
            },
            {
              icon: <Meal />,
              name: "Meal",
            },
          ];
        const nonstopFlight = (flightData, selectedFlightDetail, inBoundFlight) => {
          
            if (flightData) {
              const toggleVariable = inBoundFlight ? `${selectedFlightDetail.resultID}inBoundFlight` : `${selectedFlightDetail.resultID}outBoundFlight`
              return (
                <>
                  {inBoundFlight ?
                    <div className="d-flex pt-4">
                      <div className="bounding-icon">
                        <PlaneDown />
                      </div>
                      <div className="bounding">
                        <h5>{t("Inbound flight")} {moment(searchResults?.segments?.[1]?.departure_date).format("MMM DD")}</h5>
                        <span>{flightDetails?.inBoundFlight?.[0]?.origin?.city} - {flightDetails?.inBoundFlight?.[flightDetails?.inBoundFlight?.length - 1]?.destination?.city}</span>
                      </div>
                    </div> : null
                  }
                  <div className="flight-routes mt-3">
                    <Accordion className="w-100" defaultActiveKey="1">
                      <Card className="w-100 rounded-0">
                        <div className="flight-routes-info">
                          <div className="airline-name">
                            <div className="d-inline-block text-center">
                              <img loading='lazy' decoding='async' width="50" src={flightData?.airline_image} alt="" />
                              <h5 className="text-gray-100">
                                {flightData?.airline_name}
                              </h5>
                            </div>
                          </div>
                          <div className="airline-time pl-4 pl-xl-0">
                            <h6>{moment(flightData?.departure_time).format("HH:mm")}</h6>
                            <p>{flightData?.origin?.city_code}</p>
                          </div>
                          <div className="airline-direct text-center">
                            <div className="airline-direct-inner">
                              <span className="color-blue">{t("Direct")}</span>
                              <div className="direct"></div>
                              <p>{moment(flightData?.duration, 'hh:mm:ss').format('HH')}{t("h")} {moment(flightData?.duration, 'hh:mm:ss').format('mm')}{t("m")}</p>
                            </div>
                          </div>
                          <div className="airline-time pl-lg-5 pl-4">
                            <h5>{moment(flightData?.arrival_time).format("HH:mm")}</h5>
                            <p>{flightData?.destination?.city_code}</p>
                          </div>
                          <div className="more-info text-center w-50">
                            <Accordion.Toggle
                              as={Card.Header}
                              className="p-0 border-0"
                              eventKey="2"
                              onClick={() => {
                                this.toggleHandler(toggleVariable);
                              }}
                            >
                              <img loading='lazy' decoding='async'
                                src={arrowup}
                                alt="arrow"
                                className={
                                  this.state.toggle[toggleVariable]
                                    ? "toggleup"
                                    : "toggledown"
                                }
                              />
                              {this.state.toggle[toggleVariable]
                                ? t("See less")
                                : t("See more")}
                            </Accordion.Toggle>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body className="border-top">
                            <div className="flight-info">
                              <div className="d-flex align-items-center flex-fill">
                                <div className="flight-timeline"></div>
                                <div className="bonding-info flex-fill">
                                  <PlaneUp />
                                  <div className="pl-4 flex-fill">
                                    <div className="bonding-time">
                                      <h5 className="mb-0 fw-normal">
                                        {moment(flightData?.departure_time).format("HH:mm")}
                                      </h5>
                                      <p className="mb-0">
                                        {flightData?.origin?.name} ({flightData?.origin?.city_code})
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center my-4 pl-5 ml-5">
                                      <img loading='lazy' decoding='async'
                                        width={20}
                                        src={flightData?.airline_image}
                                        alt=""
                                      />
                                      <h5 className="mb-0 ml-2 text-gray-100 fw-normal">
                                        {flightData?.airline_name}
                                      </h5>
                                    </div>
                                    <div className="bonding-time">
                                      <h5 className="mb-0 fw-normal">
                                        {moment(flightData?.arrival_time).format("HH:mm")}
                                      </h5>
                                      <p className="mb-0">
                                        {flightData?.destination?.name} ({flightData?.destination?.city_code})
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flight-options w-25">
                                <ul className="p-0 m-0">
                                  <li>
                                    <Clock />
                                    <span>{moment(flightData?.duration, 'hh:mm:ss').format('HH')}h {moment(flightData?.duration, 'hh:mm:ss').format('mm')}m</span>
                                  </li>
                                  <li>
                                    <Seat />
                                    <span>{`${t(flightData?.cabin_class)}`}</span>
                                  </li>
                                  <li>
                                    <Boeing />
                                    <span>{t("Flight")} {flightData?.airline_code} {flightData?.flight_number}</span>
                                  </li>
                                  <li>
                                    <Baggage />
                                    <span>{flightData?.included_baggage}</span>
                                  </li>
                                  <li>
                                    <Meal />
                                    <span>{flightData?.meal || ''}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </>
              )
            }
            // return (
            //   <>
            //     <div className="d-flex pt-4">
            //       <div className="bounding-icon">
            //         <PlaneDown />
            //       </div>
            //       <div className="bounding">
            //         <h5>Inbound flight {moment(searchResults?.segments[1]?.departure_date).format("MMM DD")}</h5>
            //         <span>{flightDetails?.inBoundFlight[0]?.origin?.city} - {flightDetails?.inBoundFlight[flightDetails?.inBoundFlight?.length - 1]?.destination?.city}</span>
            //       </div>
            //     </div>
            //     <div className="flight-routes mt-3">
            //       <Accordion className="w-100" defaultActiveKey="1">
            //         <Card className="w-100 rounded-0">
            //           <div className="flight-routes-info">
            //             <div className="airline-name">
            //               <div className="d-inline-block text-center">
            //                 {/* <img loading='lazy' decoding='async' src={British} alt="" /> */}
            //                 <h5 className="text-gray-100">
            //                   British Airways
            //                 </h5>
            //               </div>
            //             </div>
            //             <div className="airline-time pl-4 pl-xl-0">
            //               <h6>12:10</h6>
            //               <p>LHR</p>
            //             </div>
            //             <div className="airline-direct text-center">
            //               <div className="airline-direct-inner">
            //                 <span className="color-blue">Direct</span>
            //                 <div className="direct"></div>
            //                 <p>06h 55m</p>
            //               </div>
            //             </div>
            //             <div className="airline-time pl-lg-5 pl-4">
            //               <h5>07:05</h5>
            //               <p>DXB</p>
            //             </div>
            //             <div className="more-info text-center">
            //               <Accordion.Toggle
            //                 as={Card.Header}
            //                 className="p-0 border-0"
            //                 eventKey="2"
            //                 onClick={() => {
            //                   this.toggleHandler("third");
            //                 }}
            //               >
            //                 <img loading='lazy' decoding='async'
            //                 //   src={arrowup}
            //                   alt="arrow"
            //                   className={
            //                     this.state.toggle?.third
            //                       ? "toggleup"
            //                       : "toggledown"
            //                   }
            //                 />
            //                 {this.state.toggle?.third
            //                   ? "See less"
            //                   : "See more"}
            //               </Accordion.Toggle>
            //             </div>
            //           </div>
            //           <Accordion.Collapse eventKey="2">
            //             <Card.Body className="border-top">
            //               <div className="flight-info">
            //                 <div className="d-flex align-items-center flex-fill">
            //                   <div className="flight-timeline"></div>
            //                   <div className="bonding-info flex-fill">
            //                     <PlaneUp />
            //                     <div className="pl-4 flex-fill">
            //                       <div className="bonding-time">
            //                         <h5 className="mb-0 fw-normal">
            //                           12:10
            //                         </h5>
            //                         <p className="mb-0">
            //                           Dubai Dubai International (DXB)
            //                         </p>
            //                       </div>
            //                       <div className="d-flex align-items-center my-4 pl-5 ml-5">
            //                         <img loading='lazy' decoding='async'
            //                           width={20}
            //                         //   src={Emirates}
            //                           alt=""
            //                         />
            //                         <h5 className="mb-0 ml-2 text-gray-100 fw-normal">
            //                           Emirates
            //                         </h5>
            //                       </div>
            //                       <div className="bonding-time">
            //                         <h5 className="mb-0 fw-normal">
            //                           12:10
            //                         </h5>
            //                         <p className="mb-0">
            //                           Dubai Dubai International (DXB)
            //                         </p>
            //                       </div>
            //                     </div>
            //                   </div>
            //                 </div>
            //                 <div className="flight-options">
            //                   <ul className="p-0 m-0">
            //                     {flightOptions?.map((item, i) => (
            //                       <li key={i}>
            //                         {item.icon}
            //                         <span>{item.name}</span>
            //                       </li>
            //                     ))}
            //                   </ul>
            //                 </div>
            //               </div>
            //             </Card.Body>
            //           </Accordion.Collapse>
            //         </Card>
            //       </Accordion>
            //     </div>
            //   </>
            // )
          }
        return (
            <div className=" px-3 flex-grow-1 ">
                      <div>
                        <div className="d-flex">
                        <div className="bounding-icon">
                          <PlaneUp />
                        </div>
                    <div className="bounding">
                        <h5>{t("Outbound Flight")} {moment(searchResults?.segments?.[0]?.departure_date).format("MMM DD")}</h5>
                        <span>{outBoundFlight?.[0]?.origin?.city} - {outBoundFlight?.[outBoundFlight?.length - 1]?.destination?.city}</span>
                    </div>
                        </div>
                        
                        {/* section 1 */}
                        <div>
                        {outBoundFlight && outBoundFlight?.length > 1 ?
                                <>
                                    <StepFlight confirmationPage={true} flightDetails={outBoundFlight}  />
                                </>
                                :
                                nonstopFlight(outBoundFlight?.[0], searchResults, false)
                        }
                          
                        </div>
                        
                        {/* section 2 */}
                        {inBoundFlight &&
                            inBoundFlight?.length > 1 ?
                                <>
                                <div className="d-flex pt-4">
                                    <div className="bounding-icon">
                                    <PlaneDown />
                                    </div>
                                    <div className="bounding">
                                    <h5>{t("Inbound flight")} {moment(searchResults?.segments?.[1]?.departure_date).format("MMM DD")}</h5>
                                    <span>{inBoundFlight?.[0]?.origin?.city} - {inBoundFlight?.[inBoundFlight?.length - 1]?.destination?.city}</span>
                                    </div>
                                </div>
                                <StepFlight confirmationPage={true} flightDetails={inBoundFlight}  />
                                </>
                                :
                                nonstopFlight(inBoundFlight?.[0], searchResults, true)
                           }
                        <hr />
                        {/* total */}
                      </div>
                    </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      orderDetails:state.order.orderDetails,
      pointBalance:state.point.pointBalance,
      errorFlag:state.order.errorFlag,
      authToken:state.auth.authToken,
      currancyCode:state.countries.convertionRate.currency_code,
      flightDetails:state.travel.selectedFlightDetail?.search?.data,
      searchResults: state.travel.searchResults,
      searchQueryData:state.travel.searchQueryData
  
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(withTranslation()(ConfirmationDetails)));

